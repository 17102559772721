import { Component } from 'react'
import { setIsNativeApp } from '../actions'
import { connect } from 'react-redux'

class ListenForIsNativeApp extends Component {
  componentDidMount() {
    setInterval(this.listen, 100)
  }

  listen = () => {
    const { isNative, setIsNativeApp } = this.props
    if (!isNative) {
      if (window.isNativeApp) {
        setIsNativeApp()
      }
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    isNative: state.app.isNative,
  }
}

const mapDispatchToProps = {
  setIsNativeApp,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListenForIsNativeApp)
