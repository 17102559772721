import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getLatestSermons,
  setDayFilterValue,
  setPassageFilterValue,
  setSelectedSpeaker,
  setShowSpeakers,
} from './actions'
import { useHover } from '../hooks/useHover'

const SpeakerFilterSpeaker = (props) => {
  const [hoverRef, isHovered] = useHover()
  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)

  const selectSpeaker = async () => {
    dispatch(setDayFilterValue(null))
    dispatch(setSelectedSpeaker(props.speaker))
    dispatch(setShowSpeakers(false))

    dispatch(getLatestSermons(10, true, null, null, props.speaker))
  }

  return (
    <div
      ref={hoverRef}
      onClick={selectSpeaker}
      style={{
        ...styles.container,
        backgroundColor: isHovered ? '#a39a9333' : 'transparent',
      }}>
      {props.speaker}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    fontSize: 18,
    color: '#6A5B5D',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
}

export default SpeakerFilterSpeaker
