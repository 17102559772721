import React, { useState, useEffect, useRef } from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { withSizes } from '@oos-studio/website-common-components'
import ArrowAnimated from '../../common/ArrowAnimated'
import Footer from '../../footer/Footer'
import CrossfadeImage from 'react-crossfade-image'

const PillarsDesktop = ({ states, heroImage, getStyle }) => {
  const [scrollY, setScrollY] = useState(0)
  const [copyHeight, setCopyHeight] = useState(0)
  const [activePillar, setActivePillar] = useState(0)
  const [footerVisible, setFooterVisible] = useState(false)

  let footerRef = useRef()
  let copyRef = useRef()

  let pillarRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    setCopyHeight(copyRef?.current?.clientHeight)
  }, [copyRef])

  const onScroll = () => {
    setScrollY(window.scrollY)
    let foundActive = false
    for (let i = pillarRefs.length - 1; i > 0; i--) {
      if (
        pillarRefs[i].current?.getBoundingClientRect().y <=
        window.innerHeight / 1.75
      ) {
        setActivePillar(i)
        foundActive = true
        break
      }
    }
    if (!foundActive) {
      setActivePillar(0)
    }

    if (footerRef?.current?.getBoundingClientRect()?.y <= window.innerHeight) {
      setFooterVisible(true)
    } else {
      setFooterVisible(false)
    }
  }

  const getStateSection = (state, index) => {
    return (
      <div
        ref={pillarRefs[index]}
        style={{
          ...styles.stateWrapper,
          paddingTop: index === 1 ? '25vh' : index === 0 ? '15vh' : 0,
        }}>
        <div style={styles.stateTitle}>{state.title}</div>
        <div>
          {state.headings.map((heading, _index) => {
            return (
              <>
                <div
                  style={
                    index === 1
                      ? {
                          ...styles.stateHeading,
                          fontSize: 25,
                          fontFamily: 'Neutraface Slab Text',
                          fontWeight: 300,
                          textAlign: 'center',
                          marginBottom: 25,
                        }
                      : styles.stateHeading
                  }>
                  {heading}
                </div>
                <div
                  style={
                    index === 1
                      ? { ...styles.stateBody, textAlign: 'center' }
                      : styles.stateBody
                  }>
                  {state.bodies[_index]}
                </div>
              </>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <>
      <div style={styles.gradientBackground} />
      <>
        {scrollY >= window.innerHeight && <div style={styles.blurOverlayTop} />}
        {scrollY >= window.innerHeight * 0.5 && !footerVisible && (
          <div>
            <div style={styles.blurOverlayBottom} />
          </div>
        )}
      </>
      <ArrowAnimated styles={styles.arrow} />
      <div style={styles.heroContainer}>
        <img style={styles.heroImage} src={heroImage} />
      </div>
      <div style={styles.scrollContainer}>
        <div style={styles.imageScroll}>
          <Controller>
            <Scene duration={copyHeight} offset={250} pin>
              <div>
                <div
                  style={{
                    position: 'relative',
                    opacity:
                      activePillar === 0 || activePillar === states.length - 1
                        ? 0
                        : 1,
                    transition: 'opacity 0.15s',
                  }}>
                  <div style={styles.headingWrapper}>
                    <div style={getStyle(styles.heading)}>The Six Pillars</div>
                    <div style={getStyle(styles.subHeading)}>
                      OF FOLLOWING CHRIST TOGETHER
                    </div>
                  </div>
                  <CrossfadeImage
                    style={styles.image}
                    src={states[activePillar].image}
                  />
                </div>
              </div>
            </Scene>
          </Controller>
        </div>
        <div id={'trigger'} ref={copyRef} style={styles.content}>
          <div style={styles.copyWrapper}>
            {states.map((state, index) => getStateSection(state, index))}
          </div>
        </div>
      </div>
      <div style={{ marginTop: '-65vh' }} ref={footerRef}>
        <Footer />
      </div>
    </>
  )
}

const styles = {
  gradientBackground: {
    background:
      'transparent radial-gradient(closest-side at 49% 50%, #336C6A 0%, #2E5B66 100%) 0% 0% no-repeat padding-box',
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: '100vh',
    zIndex: -1,
  },
  arrow: {
    borderColor: '#65A3BE',
    bottom: 55,
  },
  heroContainer: {
    paddingTop: 150,
    paddingLeft: 30,
    paddingRight: 30,
    width: 'fit-content',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '75vh',
  },
  heroImage: {
    maxHeight: '65vh',
    width: 'auto',
  },
  scrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  imageScroll: {
    flex: 1,
    paddingLeft: 35,
    paddingRight: 25,
  },
  content: {
    flex: 1,
    paddingRight: 35,
    paddingLeft: 25,
    position: 'relative',
    paddingTop: '15vh',
  },
  blurOverlayTop: {
    position: 'fixed',
    width: '100vw',
    height: '25vh',
    backgroundImage:
      'linear-gradient(180deg, rgba(46,91,102,1) 41%, rgba(46,91,102,0) 100%)',
    opacity: 0.75,
    top: 0,
    right: 0,
    zIndex: 1000,
  },
  blurOverlayBottom: {
    position: 'fixed',
    width: '100vw',
    height: '25vh',
    backgroundImage:
      'linear-gradient(0deg, rgba(46,91,102,1) 41%, rgba(46,91,102,0) 100%)',
    opacity: 0.75,
    bottom: 0,
    right: 0,
    zIndex: 1000,
  },
  copyWrapper: {
    paddingBottom: '100vh',
  },
  stateWrapper: {
    marginTop: 75,
    marginBottom: 50,
  },
  stateTitle: {
    color: '#FDECBB',
    letterSpacing: 3.2,
    fontFamily: 'Neutraface 2 Text',
    fontWeight: 300,
    textTransform: 'uppercase',
    fontSize: 32,
    marginBottom: 25,
  },
  stateHeading: {
    color: '#F8F5EE',
    fontFamily: 'Neutraface 2 Text',
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 5,
  },
  stateBody: {
    color: '#F8F5EE',
    fontFamily: 'Neutraface 2 Text',
    fontSize: 18,
    marginBottom: 25,
  },
  headingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
  },
  heading: {
    fontSize: 62,
    fontWeight: 300,
    fontFamily: 'Neutraface Slab Text',
    color: '#F8F5EE',
    lineHeight: 1.3,
    md: {
      fontSize: 43,
    },
  },
  subHeading: {
    fontSize: 18,
    fontFamily: 'Neutraface 2 Text',
    color: '#F8F5EE',
    letterSpacing: 1.6,
    md: {
      fontSize: 12,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
  },
}

export default withSizes(PillarsDesktop)
