import React, { useEffect, useState } from 'react'
import ConnectForm from './ConnectForm'
import { withSizes } from '@oos-studio/website-common-components'

const FloatingConnect = ({ xs }) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [closeButtonVisible, setCloseButtonVisible] = useState(false)
  const [right, setRight] = useState(-100)

  useEffect(() => {
    const widgetHidden = localStorage.getItem('hideConnectWidget')
    if (!widgetHidden) {
      setTimeout(() => {
        setRight(xs ? 10 : 20)
      }, 1000)
    }
  }, [])

  const hideWidget = (e) => {
    e.stopPropagation()
    window.localStorage.setItem('hideConnectWidget', 'true')
    setRight(-100)
    setIsFormVisible(false)
  }

  return (
    <>
      <ConnectForm
        close={() => setIsFormVisible(false)}
        visible={isFormVisible}
      />
      <div
        onMouseEnter={() => setCloseButtonVisible(true)}
        onMouseLeave={() => setCloseButtonVisible(false)}
        onClick={() => setIsFormVisible(!isFormVisible)}
        style={{
          ...styles.container,
          right,
          bottom: xs ? 10 : 20,
          transition: 'all 1.15s',
        }}>
        {closeButtonVisible && (
          <div onClick={hideWidget} style={styles.closeButtonWrapper}>
            <div style={styles.closeButton}>x</div>
          </div>
        )}
        <div style={styles.formWrapper}>
          <img src={require('../assets/Form.png')} style={styles.formIcon} />
          Connect
        </div>
      </div>
    </>
  )
}

const styles = {
  container: {
    width: 85,
    height: 85,
    borderRadius: '50%',
    backgroundColor: '#D07D3C',
    position: 'fixed',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F8F5EE',
    zIndex: 100000,
    cursor: 'pointer',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    fontSize: 13,
    color: '#F8F5EE',
    fontFamily: 'Neutraface 2 Text',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  formIcon: {
    width: 35,
  },
  closeButtonWrapper: {
    position: 'relative',
  },
  closeButton: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    position: 'absolute',
    top: -2,
    right: -2,
    backgroundColor: '#F8F5EE',
    zIndex: 100000,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 2,
  },
}

export default withSizes(FloatingConnect)
