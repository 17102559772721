import React, { Component } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import DateIcon from '../../common/DateIcon'
import { getPostPreview } from '../../utils/GetPostPreview'
import {
  Button,
  NavLink,
  withSizes,
} from '@oos-studio/website-common-components'
import { withRouter } from 'react-router-dom'
import deepmerge from 'deepmerge'
import Footer from '../../footer/Footer'
import LoadMoreButton from '../../common/LoadMoreButton'
import DocumentMeta from 'react-document-meta'
import { getMeta } from '../../utils/defaultMeta'
import withTouchscreenSupport from '../../utils/TouchscreenSupport'

class BlogList extends Component {
  state = {
    mobile: false,
    keepTopPadding: false,
    hovered: false,
    activeTopic: '',
    initialLimit: 6,
    limit: 6,
    activeHover: -1,
  }

  numLoaded = 0

  handleResize = () => {
    const { mobile, keepTopPadding } = this.state

    if (window.innerWidth < 1250 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1250 && mobile) {
      this.setState({
        mobile: false,
      })
    }

    if (window.innerWidth < 1040 && keepTopPadding) {
      this.setState({
        keepTopPadding: false,
      })
    }
    if (window.innerWidth >= 1040 && !keepTopPadding) {
      this.setState({
        keepTopPadding: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.numLoaded = 0
  }

  componentDidMount() {
    const { handleResize, getActiveTopic } = this

    getActiveTopic()

    if (window.innerWidth < 1250) {
      this.setState({
        mobile: true,
      })
    }

    if (window.innerWidth >= 1040) {
      this.setState({
        keepTopPadding: true,
      })
    }

    window.addEventListener('resize', handleResize)

    this.unlisten = this.props.history.listen((location, action) => {
      this.numLoaded = 0
      this.setState({
        limit: this.state.initialLimit,
      })
      this.getActiveTopic()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  onHoverButton = (index) => {
    const { onHoverImage } = this

    onHoverImage(index)

    this.setState({
      hovered: true,
    })
  }

  leaveHoverButton = () => {
    const { onLeaveHoverImage } = this

    onLeaveHoverImage()

    this.setState({
      hovered: false,
    })
  }

  getActiveTopic = () => {
    const { data } = this.props.gql

    let topicList = []
    data.forEach((item) => {
      if (!topicList.includes(item.blogTopicCategory[0]?.slug)) {
        topicList.push(item.blogTopicCategory[0]?.slug)
      }
    })

    const { history } = this.props

    const urlPieces = history.location.pathname.split('/')
    const topicRoute = urlPieces[urlPieces.length - 1]

    const _activeTopic = topicList.find((topic) => topic === topicRoute)

    this.setState({
      activeTopic: _activeTopic || 'blog',
    })
  }

  renderTopics = () => {
    const { data } = this.props.gql
    const { history } = this.props
    const { activeTopic } = this.state

    let topicList = []
    let seenTopics = []
    data.forEach((item) => {
      if (!seenTopics.includes(item.blogTopicCategory[0]?.title)) {
        topicList.push({
          title: item.blogTopicCategory[0]?.title,
          slug: item.blogTopicCategory[0]?.slug,
        })
        seenTopics.push(item.blogTopicCategory[0]?.title)
      }
    })

    return topicList.map((item, index) => {
      return (
        <React.Fragment>
          <NavLink
            key={index}
            style={{
              ...styles.navLink,
              textDecoration: activeTopic === item.slug ? 'underline' : 'none',
            }}
            history={history}
            useRouter={true}
            item={{ url: `blog/${item.slug}` }}>
            {item.title}
          </NavLink>
          {index < topicList.length - 1 && (
            <span style={{ marginLeft: 10, marginRight: 10 }}>{'|'}</span>
          )}
        </React.Fragment>
      )
    })
  }

  loadMore = () => {
    const { limit, initialLimit } = this.state

    this.setState({
      limit: limit + initialLimit,
    })

    this.numLoaded = 0
  }

  onHoverImage = (index) => {
    this.setState({
      activeHover: index,
    })
  }

  onLeaveHoverImage = () => {
    this.setState({
      activeHover: -1,
    })
  }

  renderItem = (item, index) => {
    const { history, touchscreen } = this.props
    const { mobile, hovered, limit, activeHover } = this.state
    const {
      onHoverButton,
      leaveHoverButton,
      numLoaded,
      onHoverImage,
      onLeaveHoverImage,
    } = this

    const _styles = mobile ? deepmerge(styles, styles.mobile) : styles

    this.numLoaded += 1

    return numLoaded < limit ? (
      <div
        style={_styles.itemContainer}
        key={index}
        onMouseEnter={touchscreen ? null : () => onHoverImage(index)}
        onMouseLeave={touchscreen ? null : () => onLeaveHoverImage()}>
        <div style={_styles.itemTopWrapper}>
          <div
            style={{
              transform: activeHover === index ? 'scale(1.025)' : 'scale(1.0)',
              transition: 'transform 0.5s',
              overflow: 'hidden',
            }}>
            <img
              onMouseEnter={() => onHoverImage(index)}
              onMouseLeave={() => onLeaveHoverImage()}
              src={item.heroImage[0].url}
              onClick={() => history.push(`/blog/post/${item.slug}`)}
              style={{
                ..._styles.itemImg,
                transform: activeHover === index ? 'scale(1.0)' : 'scale(1.1)',
                transition: 'transform 0.5s',
              }}
              alt={'heroImg'}
            />
          </div>
          <div style={_styles.itemInfoWrapper}>
            <div style={_styles.itemTitleWrapper}>
              <div style={_styles.itemTitle}>{item.title}</div>
              <div style={_styles.itemAuthor}>{`By ${item.author.name}`}</div>
            </div>
            <DateIcon date={item.postDate} />
          </div>
        </div>
        <div style={_styles.itemPreview}>{getPostPreview(item)}</div>
        <div
          style={_styles.buttonWrapper}
          onMouseEnter={touchscreen ? null : () => onHoverButton(index)}
          onMouseLeave={touchscreen ? null : leaveHoverButton}>
          <Button
            useTouchEnd={false}
            useRouter={true}
            history={history}
            item={{ url: `/blog/post/${item.slug}` }}
            styles={_styles.button}>
            READ MORE
            <div
              style={{
                ...styles.arrow,
                borderColor:
                  hovered && activeHover === index ? '#FAA860' : '#D07D3C',
                right: hovered && activeHover === index ? -30 : -20,
              }}
            />
          </Button>
        </div>
      </div>
    ) : null
  }

  render() {
    const { data } = this.props.gql
    const { mobile, activeTopic, keepTopPadding } = this.state
    const { history, getStyle } = this.props
    const { renderItem, renderTopics, loadMore } = this

    const _styles = mobile ? deepmerge(styles, styles.mobile) : styles

    return (
      <DocumentMeta {...getMeta('Blog')}>
        <React.Fragment>
          <div style={_styles.backgroundBlock} />
          <div
            style={{
              ..._styles.header,
              paddingTop: keepTopPadding ? 183 : _styles.header.paddingTop,
            }}>
            <div style={getStyle(_styles.title)}>Providence Church Blog</div>
            <div style={getStyle(_styles.filters)}>
              Topics: <span style={{ marginLeft: 10 }}></span>
              {
                <NavLink
                  style={{
                    ...styles.navLink,
                    textDecoration:
                      activeTopic === 'blog' || activeTopic === ''
                        ? 'underline'
                        : 'none',
                  }}
                  useRouter={true}
                  history={history}
                  item={{ url: 'blog' }}>
                  All Topics
                </NavLink>
              }{' '}
              <span style={{ marginLeft: 10, marginRight: 10 }}>{'|'}</span>
              {renderTopics()}
            </div>
          </div>
          <div style={_styles.grid}>
            {data.map((entry, index) => {
              return activeTopic === 'blog' ||
                entry.blogTopicCategory[0]?.slug === activeTopic
                ? renderItem(entry, index)
                : null
            })}
          </div>
          <LoadMoreButton loading={false} loadMore={loadMore} />
          <Footer />
        </React.Fragment>
      </DocumentMeta>
    )
  }
}

const styles = {
  header: {
    paddingTop: 183,
    width: '100%',
    maxWidth: 1260,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#695B5D',
    marginBottom: 25,
    paddingLeft: 30,
    paddingRight: 30,
  },
  title: {
    fontFamily: 'Neutraface Slab Text',
    fontSize: 45,
    fontWeight: 300,
    xs: {
      fontSize: 32,
    },
  },
  filters: {
    marginTop: 10,
    fontSize: 18,
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    xs: {
      fontSize: 15,
    },
  },
  navLink: {
    color: '#518EAB',
    fontSize: 16,
    paddingTop: 0,
    paddingBottom: 0,
    // paddingLeft: 10,
    // paddingRight: 10,
  },
  grid: {
    width: '100%',
    maxWidth: 1260,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: 30,
    paddingRight: 30,
  },
  itemContainer: {
    margin: 0,
    marginTop: 20,
    marginBottom: 50,
    maxWidth: 585,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'auto',
    height: 600,
    fontSize: 20,
    fontFamily: 'Neutraface 2 Text',
    color: '#6A5B5D',
    padding: 0,
  },
  itemTopWrapper: {
    width: '100%',
  },
  itemImg: {
    zIndex: -1,
    width: '100%',
    height: 300,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  itemInfoWrapper: {
    display: 'flex',
    width: '100%',
    paddingRight: 35,
    marginTop: -35,
  },
  itemTitleWrapper: {
    marginRight: 'auto',
    marginTop: 60,
  },
  itemTitle: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 3,
  },
  itemAuthor: {},
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 500,
    position: 'absolute',
    zIndex: -1,
  },
  itemPreview: {
    marginTop: 20,
  },
  buttonWrapper: {
    marginTop: 'auto',
  },
  button: {
    navLink: {
      color: '#D07D3C',
      padding: 0,
    },
    color: '#D07D3C',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    letterSpacing: 2.4,
    position: 'relative',
    padding: 0,
    hovered: {
      navLink: {
        color: '#FAA860',
      },
      backgroundColor: 'transparent',
      borderWidth: 0,
      borderStyle: 'none',
    },
  },
  arrow: {
    borderColor: '#D07D3C',
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    position: 'absolute',
    padding: 5,
    top: 8,
    transform: 'rotate(-45deg)',
    marginBottom: 1,
    marginLeft: 5,
    transition: 'right 0.5s',
    hovered: {
      borderColor: '#FAA860',
    },
  },
  mobile: {
    grid: {
      maxWidth: 800,
      justifyContent: 'center',
    },
    header: {
      maxWidth: 646,
      paddingTop: 135,
    },
    itemContainer: {
      width: '100%',
      height: 'auto',
    },
    itemImg: {
      height: 'auto',
      maxHeight: 300,
    },
    buttonWrapper: {
      marginTop: 25,
    },
  },
}

export default withTouchscreenSupport(
  gqlConnect('blog')(withRouter(withSizes(BlogList))),
)
