import config from '../config'
const { api } = require('boxcast-sdk-js')

export const getChannels = async (limit = 20, page = 0, sortBy = 'name') => {
  const channelList = await api.channels.list(config.boxcast.accountId, {
    //s: 'name',
    l: 100,
    //p: page,
  })

  let _channels = []
  for (const channel of channelList.data) {
    let _channel = await getChannelInfo(channel.id)
    const videos = await getVideosFromChannel(channel.id, 100, 'desc', true)
    if (videos.videos[0]) {
      const length = videos.videos.length
      _channel.date = videos.videos[0].starts_at
      _channel.endDate = videos.videos[0].starts_at
      _channel.startDate = videos.videos[length - 1].starts_at
      _channels.push(_channel)
    }
  }

  return {
    total: channelList.pagination.total,
    data: _channels
      .filter((channel) => {
        return (
          !channel.content_settings?.development &&
          !channel.content_settings?.is_podcast
        )
      })
      .sort((a, b) => b.date - a.date),
  }
}

export const getChannelInfo = async (id) => {
  const response = await fetch(`https://api.boxcast.com/channels/${id}`)
  return await response.json()
}

export const getVideosFromChannel = async (
  id,
  limit = 20,
  order = 'desc',
  justDate = false,
  useFilter = false,
  filterBook = null,
  filterChapter = null,
  selectedSpeaker = null,
  startsAt = null,
  stopsAt = null,
) => {
  let videoList
  if (useFilter) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    const data = JSON.stringify({
      limit: limit,
      book: filterBook,
      chapter: filterChapter,
      speaker: selectedSpeaker,
      broadcast_date: startsAt,
      starts_at: startsAt,
      stops_at: stopsAt,
    })
    const response = await fetch(
      `${config.providenceApi.baseUrl}/boxcast/broadcasts/filter`,
      {
        method: 'POST',
        headers: headers,
        body: data,
      },
    )
    videoList = await response.json()
  } else {
    videoList = await api.broadcasts.list(id, {
      s: order === 'desc' ? '-starts_at' : '+starts_at',
      l: limit,
      p: 0,
      q: 'timeframe:past',
    })
  }

  const total = useFilter ? videoList.length : videoList.pagination.total
  const videos = useFilter
    ? videoList.filter((video) => {
        return !video.description.includes('<hide>')
      })
    : videoList.data.filter((video) => {
        return !video.description.includes('<hide>')
      })

  if (!justDate) {
    const channelInfo = await getChannelInfo(id)

    const image = channelInfo.content_settings.preview

    return {
      name: channelInfo.name,
      total: total,
      videos: videos,
      image: image,
    }
  } else {
    return {
      total: total,
      videos: videos,
    }
  }
}

export const getBroadcast = async (id) => {
  return await api.broadcasts.get(id)
}

export const getTagsFromCraft = async (id) => {
  const response = await fetch(
    `${config.providenceApi.baseUrl}/boxcast/broadcasts/tags/${id}`,
  )
  return await response.json()
}

export const getHighlight = async (broadcastId, id) => {
  const response = await fetch(
    `${config.providenceApi.baseUrl}/boxcast/broadcasts/${broadcastId}/highlights/${id}`,
  )
  return await response.json()
}

export const getAudioRecording = async (id) => {
  const response = await fetch(
    `${config.providenceApi.baseUrl}/boxcast/recording/${id}`,
  )
  return await response.json()
}

export const checkForLiveStream = async () => {
  const response = await fetch(
    'https://api.boxcast.com/channels/wZ5XDxtT5GqbAR7O3EMa/broadcasts?s=-starts_at&l=5&p=0',
  )

  let data = await response.json()

  const futureStreams = data.filter((stream) => stream.timeframe === 'future')
  const currentStreams = data.filter((stream) => stream.timeframe === 'current')
  const prerollStreams = data.filter((stream) => stream.timeframe === 'preroll')

  let selectedStream = null
  if (currentStreams.length > 0) {
    selectedStream = currentStreams[0]
  } else if (prerollStreams.length > 0) {
    selectedStream = prerollStreams[0]
  } else if (futureStreams.length > 0) {
    selectedStream = futureStreams[futureStreams.length - 1]
  } else {
    selectedStream = data[0]
  }

  if (selectedStream) {
    const responseProvidence = await fetch(
      `${config.providenceApi.baseUrl}/boxcast/livestream/${selectedStream.id}`,
    )
    return await responseProvidence.json()
  }

  return null
}
