import React, { Component } from 'react'
import FloatingParallax from '../../common/FloatingParallax'
import Footer from '../../footer/Footer'
import ImageCaptionBlock from '../../common/ImageCaptionBlock'
import ImageAlignedText from '../../common/ImageAlignedText'
import HomeFeed from '../HomeFeed'
import HomepageMapImage from '../HomepageMapImage'
import FullScreenIntro from '../FullScreenIntro'
import '../../fonts/fonts.css'
import { gqlConnect } from 'react-connect-graphql-beta'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  parseKeywords,
  _keywords,
  getImageProperties,
} from '../../utils/defaultMeta'
import ImageSliderSection from '../ImageSliderSection'

class Homepage extends Component {
  state = {
    imageProperties: null,
  }
  async componentDidMount() {
    const { data } = this.props.gql

    const imageProperties =
      data.seoImage.length > 0
        ? await getImageProperties(data.seoImage[0]?.url)
        : null

    this.setState({
      imageProperties: imageProperties,
    })
  }
  smallGroups = () => {
    const { data } = this.props.gql

    return {
      text: data.smallGroupsDescription.replace(/(<([^>]+)>)/gi, ''),
      image: {
        image: data.smallGroupsBackgroundImage[0].url,
        title: '',
      },
      logo: {
        image: data.sealImage[0].url,
        title: '',
      },
    }
  }
  aboutLayers = () => {
    const { data } = this.props.gql

    const layers = {
      text: data.description.replace(/(<([^>]+)>)/gi, ''),
      background: data.aboutBackgroundImage[0].url,
      back: data.aboutBackOverlayImage[0].url,
      middle: data.aboutMiddleOverlayImage[0].url,
      front: data.aboutFrontOverlayImage[0].url,
    }

    return {
      text: layers.text,
      image: {
        src: layers.background,
        title: 'Church Illustration Sky',
      },
      parallaxLayers: [
        {
          src: layers.back,
          title: 'Church Illustration 1',
          x: [8, 2],
        },
        {
          src: layers.middle,
          title: 'Church Illustration 2',
          x: [10, 0],
        },
        {
          src: layers.front,
          title: 'Church Illustration 3',
          x: [13, -3],
        },
      ],
      button: {
        text: data.buttonText,
        url: data.aboutUrl,
        entry: data.aboutEntry,
      },
    }
  }
  render() {
    const { data } = this.props.gql
    const { imageProperties } = this.state

    return (
      <DocumentMeta
        {...getMeta(
          _title,
          data.seoDescription || _description,
          data.seoImage.length > 0 ? data.seoImage[0].url : _image,
          data.seoKeywords.length > 0
            ? parseKeywords(data.seoKeywords)
            : _keywords,
          imageProperties,
        )}>
        <div style={styles.container}>
          <FullScreenIntro
            text={data.introText}
            image={data.introOverlayImage[0]?.url}
            slides={data.introSlides}
          />
          <FloatingParallax
            url={
              data.parallaxEntry.length > 0
                ? data.parallaxEntry[0].slug
                : data.parallaxUrl
            }
            useRouter={data.parallaxEntry.length > 0}
            backImage={data.backImage[0]?.url}
            frontImage={data.frontImage[0]?.url}
          />
          <ImageAlignedText {...this.aboutLayers()} />
          <ImageCaptionBlock data={this.smallGroups()} />
          <HomepageMapImage
            map={data.mapImage[0].url}
            buttonText={data.mapButtonText}
            url={data.mapEntry[0]?.slug ? data.mapEntry[0].slug : data.mapUrl}
            isEntry={data.mapEntry[0]?.slug}
          />
          <HomeFeed />
          <ImageSliderSection isHomePage data={data.slider} />
          <Footer />
        </div>
      </DocumentMeta>
    )
  }
}

const styles = {
  container: {
    fontFamily: 'Neutraface 2 Text',
  },
  mapWrapper: {
    width: 500,
    height: 400,
  },
}

export default gqlConnect('homepage')(Homepage)
