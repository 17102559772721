export const getPostPreview = (item) => {
  let firstBodyEntry = null
  let firstParagraph

  const formatPreview = (text) => {
    const finalIndex = 275 + text.substring(275, 300).indexOf(' ')
    text = text.substring(0, finalIndex)
    if (text[text.length - 1] === '?') {
      return text
    } else {
      return `${text}...`
    }
  }

  if (item.body) {
    firstBodyEntry = item.body.find((b) => b.typeHandle === 'bodyText')?.text
    if (firstBodyEntry.includes('<p>')) {
      firstParagraph = firstBodyEntry
        .substring(
          firstBodyEntry.indexOf('<p>') + 3,
          firstBodyEntry.indexOf('</p>'),
        )
        .substring(0, 300)
    } else {
      firstParagraph = firstBodyEntry.substring(0, 300)
    }
    firstParagraph = firstParagraph.replace(/(<([^>]+)>)/gi, '')

    let preview

    if (item.postPreview !== null && item.postPreview !== undefined) {
      preview = item.postPreview
    } else {
      preview = firstParagraph
    }
    return formatPreview(preview)
  }
}
