import React, { Component } from 'react'
import PlayButton from '../common/PlayButton'
import { withSizes, NavLink } from '@oos-studio/website-common-components'
import deepmerge from 'deepmerge'
import { withRouter } from 'react-router-dom'
import withTouchscreenSupport from '../utils/TouchscreenSupport'

class SermonsListView extends Component {
  state = {
    mobile: false,
    activeIndex: -1,
    touchscreen: false,
  }
  async componentDidMount() {
    const { handleResize } = this
    if (window.innerWidth < 1250) {
      this.setState({
        mobile: true,
      })
    }

    window.addEventListener('resize', handleResize)
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1250 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1250 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  onClick = (index, video) => {
    this.props.history.push({
      pathname: `/sermons/player/${video.id}`,
    })
  }

  render() {
    const { series, getStyle, history, isSeries } = this.props
    const { mobile } = this.state

    const _styles = mobile ? deepmerge(styles, styles.mobile) : styles

    return (
      <div style={_styles.container}>
        {series.map((video, index) => {
          const date = new Date(video.starts_at)
          const speaker =
            video.tags?.speaker !== undefined ? ` | ${video.tags.speaker}` : ''
          const passage =
            video.tags?.passage !== undefined ? ` | ${video.tags.passage}` : ''

          return (
            <NavLink
              key={index}
              useTouchEnd={false}
              append={false}
              useRouter={true}
              item={{ url: null }}
              history={history}
              onClickItem={() => this.onClick(index, video)}
              style={{
                ..._styles.listItem,
                backgroundColor: isSeries
                  ? index % 2 === 0
                    ? '#FFFFFF'
                    : '#F8F5EE'
                  : index % 2 !== 0
                  ? '#FFFFFF'
                  : '#F8F5EE',
              }}>
              <div style={getStyle(_styles.widthRestrict)}>
                <div style={getStyle(_styles.info)}>
                  <div style={getStyle(_styles.title)}>{video.name}</div>
                  <div style={getStyle(_styles.tags)}>
                    {`${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()}${speaker}${passage}`}
                  </div>
                </div>
                <PlayButton
                  item={{ url: `sermons/player/${video.id}` }}
                  styles={_styles.button}
                />
              </div>
            </NavLink>
          )
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    marginBottom: 40,
    marginTop: 0,
    width: '100vw',
  },
  widthRestrict: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 15,
    paddingBottom: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 1250,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 125,
    sm: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  listItem: {
    cursor: 'pointer',
    padding: 0,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A5B5D',
  },
  tags: {
    display: 'flex',
  },
  title: {
    fontSize: 30,
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    xs: {
      fontSize: 25,
    },
  },
  button: {
    container: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 20,
    },
  },
  mobile: {
    widthRestrict: {
      maxWidth: 800,
    },
  },
}

export default withTouchscreenSupport(withRouter(withSizes(SermonsListView)))
