import React from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import withTouchscreenSupport from '../utils/TouchscreenSupport'
import DateIcon from './DateIcon'
import AnimateHeight from 'react-animate-height'
import Radium from 'radium'

const CalendarEventFeedEvent = ({
  event,
  sm,
  getStyle,
  index,
  touchscreen,
  expand,
  onLeaveHover,
  onHover,
  hoveredEvent,
  activeEvent,
}) => {
  return (
    <div
      onMouseEnter={touchscreen ? null : () => onHover(index)}
      onMouseLeave={touchscreen ? null : () => onLeaveHover()}
      onClick={() => (event.eventDescription ? expand(index) : null)}
      key={index}
      className={'expand-event-container'}
      style={{...styles.eventContainer, cursor: event.eventDescription ? 'pointer' : 'default'}}>
      <div style={styles.dateWrapper}>
        {event.eventDate && <DateIcon date={event.eventDate} />}
      </div>
      <div style={styles.eventInfoWrapper}>
        <div style={getStyle(styles.eventTitle)}>{event.eventTitle}</div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div style={getStyle(styles.divider)} />
          {event.eventDescription && (
            <div className={'expand-event'}>
              <div
                style={{
                  ...getStyle(styles.verticalPlus),
                  backgroundColor:
                    hoveredEvent === index ? '#FAA860' : '#6A5B5D',
                  display: activeEvent === index ? 'none' : 'block',
                }}
              />
              <div
                style={{
                  ...getStyle(styles.horizontalPlus),
                  backgroundColor:
                    hoveredEvent === index ? '#FAA860' : '#6A5B5D',
                }}
              />
            </div>
          )}
        </div>
        <div>
          {event.targetGroup && (
            <div style={{ display: sm ? 'block' : 'inline' }}>
              <span style={styles.detailType}>WHO:</span>
              <span style={styles.detail}>{event.targetGroup}</span>
            </div>
          )}
          {event.eventTime && (
            <div style={{ display: sm ? 'block' : 'inline' }}>
              <span
                style={{
                  ...styles.detailType,
                  marginLeft: sm || !event.targetGroup ? 0 : 10,
                  paddingLeft: sm || !event.targetGroup ? 0 : 10,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: sm || !event.targetGroup ? 0 : 1,
                  borderLeftColor: '#6A5B5D',
                }}>
                WHEN:
              </span>
              <span style={styles.detail}>{event.eventTime}</span>
            </div>
          )}
          {event.eventLocation && (
            <div style={{ display: sm ? 'block' : 'inline' }}>
              <span
                style={{
                  ...styles.detailType,
                  marginLeft: sm ? 0 : 10,
                  paddingLeft: sm ? 0 : 10,
                  borderLeftStyle: 'solid',
                  borderLeftWidth: sm ? 0 : 1,
                  borderLeftColor: '#6A5B5D',
                }}>
                WHERE:
              </span>
              <span style={styles.detail}>{event.eventLocation}</span>
            </div>
          )}
        </div>
        <div
          style={{
            ...styles.eventDescription,
            position: 'absolute',
            opacity: 0,
          }}>
          {event.eventDescription}
        </div>
        <AnimateHeight
          duration={250}
          height={activeEvent === index ? 'auto' : 0}>
          <div style={styles.eventDescription}>{event.eventDescription}</div>
        </AnimateHeight>
      </div>
    </div>
  )
}

const styles = {
  dateWrapper: {
    position: 'static',
    top: 0,
    left: 0,
    marginTop: 5,
    '@media (min-width: 1440px)': {
      position: 'absolute',
      top: 7,
      left: -100,
      marginTop: 0,
    },
  },
  eventContainer: {
    position: 'relative',
    fontFamily: 'Neutraface 2 Text',
    marginTop: 45,
    marginBottom: 45,
    display: 'flex',
    width: '100%',
  },
  eventInfoWrapper: {
    flex: 1,
    marginLeft: 25,
    '@media (min-width: 1440px)': {
      marginLeft: 0,
    },
    '@media (max-width: 576px)': {
      marginLeft: 15,
    },
  },
  eventTitle: {
    color: '#65A3BE',
    fontSize: 22,
    letterSpacing: 2.6,
    fontWeight: 500,
    textTransform: 'uppercase',
    xs: {
      fontSize: 19,
    },
  },
  divider: {
    // width: '100%',
    flex: 1,
    height: 1,
    maxWidth: 'none',
    opacity: 0.5,
    backgroundColor: '#A39A93',
    marginBottom: 15,
    marginTop: 15,
    xxs: {
      maxWidth: 200,
    },
  },
  detailType: {
    color: '#A39A93',
    letterSpacing: 1.8,
    fontWeight: 500,
  },
  detail: {
    color: '#6A5B5D',
    marginLeft: 3,
    fontWeight: 400,
    letterSpacing: 0,
  },
  eventDescription: {
    color: '#6A5B5D',
    fontWeight: 400,
    letterSpacing: 0,
    transition: 'all 0.25s',
    marginTop: 25,
  },
  verticalPlus: {
    width: 1,
    height: 16,
    left: 16,
    transition: 'background-color 0.25s',
    position: 'absolute',
    top: 8,
    sm: {
      height: 13,
      left: 10,
      top: 5,
    },
  },
  horizontalPlus: {
    width: 15,
    height: 1,
    left: 9,
    position: 'absolute',
    transition: 'background-color 0.25s',
    top: 16,
    sm: {
      width: 13,
      left: 4,
      top: 11,
    },
  },
}

export default withTouchscreenSupport(withSizes(Radium(CalendarEventFeedEvent)))
