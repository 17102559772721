import React, { Component } from 'react'
import { Article, withSizes } from '@oos-studio/website-common-components'
import '../article.css'
import Radium from 'radium'
import CardGrid from './CardGrid'
import EventFeed from './EventFeed'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  _keywords,
  parseKeywords,
  getImageProperties,
} from '../utils/defaultMeta'
import Footer from '../footer/Footer'
import NotFound from './NotFound'
import config from '../config'
import ImageSliderSection from '../home/ImageSliderSection'
import Map from './Map/Map'
import truncateText from '../utils/truncateText'
import RectButton from './RectButton'
import InstagramCard from './InstagramCard'

class Page extends Component {
  state = {
    mobile: false,
    imageProperties: null,
    pageBodyNew: null,
    pageTitle: null,
    heroImage: null,
    activeCards: [],
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1040 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1040 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  async componentDidMount() {
    const { data } = this.props.gql
    const { getPageBodyNew, handleResize } = this

    if (window.innerWidth < 1040) {
      this.setState({
        mobile: true,
      })
    }

    const imageProperties =
      data?.pageHeroImage.length > 0
        ? await getImageProperties(data.pageHeroImage[0]?.url)
        : null

    this.setState({
      imageProperties: imageProperties,
    })

    window.addEventListener('resize', handleResize)

    const pageBodyNew = data ? await getPageBodyNew() : []
    this.setState({
      pageBodyNew,
    })
  }

  mapDataToProps = (data) => {
    return {
      newsPostBody: [
        {
          typeHandle: 'text',
          text: data,
        },
      ],
    }
  }

  getPageBodyNew = async () => {
    const { data, instagramData } = this.props.gql
    const { mapDataToProps } = this
    const { getStyle } = this.props
    const { activeCards } = this.state

    const elements = []
    let currentSlider = []
    let cards = []

    let firstItem = data.pageBodyNew[0]?.__typename
    if (firstItem === 'pageBodyNew_pageTitle_BlockType') {
      firstItem = data.pageBodyNew[1]?.__typename
    }

    for (const index in data?.pageBodyNew) {
      const item = data?.pageBodyNew[index]
      let element = null
      let isFirstItem =
        (Number(index) === 0 || Number(index) === 1) &&
        firstItem === item.__typename &&
        !data.pageBodyNew.find((item) => item.isPageHero)

      let integrationEvents = null
      let integrationFeedBackgroundColor = 'white'

      let apiEventFeedUrl = null

      if (
        currentSlider.length &&
        item.__typename !== 'pageBodyNew_slide_BlockType'
      ) {
        const sliderElement = <ImageSliderSection data={currentSlider} />
        elements.push(sliderElement)
        currentSlider = []
      }

      if (cards.length && item.__typename !== 'pageBodyNew_card_BlockType') {
        const cardGridElement = <CardGrid hideGap={true} data={cards} />
        elements.push(cardGridElement)
        cards = []
      }

      if (item.__typename === 'pageBodyNew_apiEventFeed_BlockType') {
        if (item.eventFeedBackgroundColor?.length) {
          integrationFeedBackgroundColor =
            item.eventFeedBackgroundColor[0]?.color[0]?.colorValue
        }
        switch (item.integration) {
          case 'pushPay':
            apiEventFeedUrl = `${config.providenceApi.baseUrl}/pushpay/events`
            break
          case 'planningCenter':
            apiEventFeedUrl = `${config.providenceApi.baseUrl}/pco/events`
            break
          default:
            apiEventFeedUrl = null
            break
        }

        if (apiEventFeedUrl) {
          const response = await fetch(apiEventFeedUrl)
          const resJson = await response.json()
          if (resJson?.length > 0) {
            integrationEvents = resJson
          }
        }
        element = integrationEvents ? (
          <EventFeed
            showDateIcon
            title={item.eventFeedTitle}
            events={integrationEvents}
            backgroundColor={integrationFeedBackgroundColor}
          />
        ) : null
      } else if (item.__typename === 'pageBodyNew_card_BlockType') {
        cards.push(item)
      } else if (item.__typename === 'pageBodyNew_slide_BlockType') {
        currentSlider.push(item)
      } else if (item.__typename === 'pageBodyNew_map_BlockType') {
        if (item?.mapEntry?.length) {
          const pins = item?.mapEntry[0]?.pins
          element = (
            // <div style={{ flex: 1, backgroundColor: 'white' }}>
            <Map data={pins} isFirstItem={isFirstItem} />
            // </div>
          )
        }
      } else if (item.__typename === 'pageBodyNew_textBlock_BlockType') {
        element = (
          <div
            style={
              isFirstItem
                ? {
                    backgroundColor: 'white',
                  }
                : {}
            }
            className={'articlePage increaseWidth'}>
            <Article
              styles={getStyle(styles.article)}
              data={mapDataToProps(item.text)}
            />
          </div>
        )
      } else if (item.__typename === 'pageBodyNew_assetBlock_BlockType') {
        if (item.isPageHero) {
          this.setState({
            heroImage: item.assetField[0]?.url,
          })
        } else {
          element = (
            <div style={{ ...getStyle(styles.imageWrapper), marginTop: 40 }}>
              <img src={item.assetField[0]?.url} style={styles.image} />
            </div>
          )
        }
      } else if (item.__typename === 'pageBodyNew_pageTitle_BlockType') {
        element = <div style={styles.title}>{item.pageTitleField}</div>
      } else if (item.__typename === 'pageBodyNew_singleEvent_BlockType') {
        element = (
          <EventFeed showDateIcon backgroundColor={'white'} events={[item]} />
        )
      } else if (
        item.__typename === 'pageBodyNew_spacer_BlockType' &&
        item.spacer
      ) {
        element = (
          <div
            style={{
              backgroundColor: 'inherit',
              width: '100%',
              height:
                item.spacer === 'large'
                  ? 30
                  : item.spacer === 'medium'
                  ? 20
                  : 10,
            }}
          />
        )
      } else if (item.__typename === 'pageBodyNew_instagramcard_BlockType') {
        element = (
          <InstagramCard
            data={instagramData}
            username={item.instagramUsername}
          />
        )
      } else {
        element = null
      }
      if (element) {
        elements.push(element)
      }
    }
    if (currentSlider.length) {
      const sliderElement = <ImageSliderSection data={currentSlider} />
      elements.push(sliderElement)
    }
    if (cards.length) {
      const cardGridElement = <CardGrid hideGap={true} data={cards} />
      elements.push(cardGridElement)
    }
    return elements
  }

  render() {
    const { data } = this.props.gql
    const { imageProperties, pageBodyNew, mobile, heroImage } = this.state
    const { mapDataToProps } = this
    const { getStyle } = this.props

    return !data ? (
      <NotFound />
    ) : (
      <DocumentMeta
        {...getMeta(
          data.title || _title,
          data.seoDescription || _description,
          data?.pageHeroImage[0]?.url || _image,
          data.seoKeywords.length > 0
            ? parseKeywords(data.seoKeywords)
            : _keywords,
          imageProperties,
        )}>
        {mobile && heroImage ? null : <div style={styles.backgroundBlock} />}
        <div style={styles.container}>
          {(!!heroImage || data.pageHeroImage[0]?.url) && (
            <div
              style={
                mobile
                  ? getStyle(styles.imageWrapperMobile)
                  : getStyle(styles.imageWrapper)
              }>
              <img
                alt={'heroImg'}
                src={heroImage || data.pageHeroImage[0]?.url}
                style={mobile ? styles.imageMobile : styles.image}
              />
            </div>
          )}
          {data.pageBodyNew.length <= 0 && (
            <>
              <div style={styles.title}>{data.title}</div>
              <div className={'articlePage increaseWidth'}>
                <Article
                  styles={getStyle(styles.article)}
                  data={mapDataToProps(data.pageBody)}
                />
              </div>
            </>
          )}
          {pageBodyNew?.length > 0 &&
            pageBodyNew?.map((item) => {
              return item
            })}
          {data.pageCardGrid?.length > 0 && (
            <CardGrid data={data.pageCardGrid} />
          )}
          {data.eventFeed?.length > 0 && (
            <EventFeed title={data.eventFeedTitle} events={data.eventFeed} />
          )}
        </div>
        <Footer />
      </DocumentMeta>
    )
  }
}

const styles = {
  row: {
    marginLeft: 55,
    marginRight: 55,
    xs: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  wrapper: {
    maxWidth: 1350,
  },
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    height: 500,
    width: '100%',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    '@media (max-width: 645px)': {
      height: 350,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingTop: 182,
    paddingBottom: 35,
  },
  title: {
    fontFamily: 'Neutraface Slab Text',
    fontSize: 40,
    fontWeight: 300,
    color: '#6A5B5D',
    marginTop: 25,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1340,
    paddingLeft: 70,
    paddingRight: 70,
    '@media (max-width: 1200px)': {
      paddingLeft: 35,
      paddingRight: 35,
    },
    '@media (max-width: 768px)': {
      fontSize: 35,
    },
  },
  imageWrapper: {
    width: '100%',
    maxWidth: 1340,
    paddingLeft: 70,
    paddingRight: 70,
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  image: {
    maxWidth: 1270,
    width: '100%',
    height: 400,
    objectFit: 'cover',

    '@media (max-width: 768px)': {
      height: 300,
    },
    '@media (max-width: 585px)': {
      height: 250,
    },
    '@media (max-width: 485px)': {
      height: 150,
    },
  },
  imageMobile: {
    maxWidth: 1270,
    width: '100%',
    height: 400,
    marginTop: -100,
    objectFit: 'cover',

    '@media (max-width: 768px)': {
      height: 320,
    },
    '@media (max-width: 585px)': {
      height: 270,
    },
    '@media (max-width: 485px)': {
      height: 170,
    },
  },
  imageWrapperMobile: {
    width: '100%',
    height: '100%',
  },
  article: {
    '@media (max-width: 1200px)': {
      container: {
        paddingLeft: 35,
        paddingRight: 35,
      },
    },
    container: {
      borderTopWidth: 0,
      maxWidth: 1340,
      paddingLeft: 70,
      paddingRight: 70,
      paddingTop: 0,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      lg: {
        paddingLeft: 35,
        paddingRight: 35,
      },
    },
    lg: {
      container: {
        paddingLeft: 35,
        paddingRight: 35,
      },
    },
  },
}

export default withSizes(Radium(Page))
