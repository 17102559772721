import React, { Component } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withRouter, Link } from 'react-router-dom'
import { Article, withSizes } from '@oos-studio/website-common-components'
import withTouchscreenSupport from '../../utils/TouchscreenSupport'
import '../../article.css'
import Footer from '../../footer/Footer'
import InformationHeader from '../../common/InformationHeader'
import Radium from 'radium'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  _keywords,
  parseKeywords,
  getImageProperties,
} from '../../utils/defaultMeta'
import NotFound from '../../common/NotFound'
import AuthorBio from '../../common/AuthorBio'

class BlogPost extends Component {
  state = {
    post: null,
    returnHovered: false,
    imageProperties: null,
  }

  mapDataToProps = (data) => {
    return data?.body?.map((b) => {
      let typeHandle
      switch (b.typeHandle) {
        case 'quote':
          typeHandle = 'quote'
          break
        case 'asset':
          typeHandle = 'asset'
          break
        default:
          typeHandle = 'text'
          break
      }
      return {
        typeHandle: typeHandle,
        text: b.text.replace(/<p><br><\/p>/g, '<br>'),
        asset: b.asset,
        caption: b.caption,
        position: b.position,
      }
    })
  }

  async componentDidMount() {
    const { data } = this.props.gql
    const { history } = this.props

    const path = history.location.pathname.split('/')
    const slug = path[path.length - 1]
    const post = data.find((entry) => {
      return entry.slug === slug
    })

    const imageProperties =
      post?.heroImage.length > 0
        ? await getImageProperties(post?.heroImage[0]?.url)
        : null

    this.setState({
      post: {
        ...post,
        newsPostBody: this.mapDataToProps(post),
      },
      imageProperties: imageProperties,
    })
  }

  formatDate = (_date) => {
    const date = new Date(_date)
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  }

  onHoverReturn = () => {
    this.setState({
      returnHovered: true,
    })
  }

  leaveHoverReturn = () => {
    this.setState({
      returnHovered: false,
    })
  }

  render() {
    const { post, returnHovered, imageProperties } = this.state
    const { getStyle, touchscreen, md } = this.props
    const { onHoverReturn, leaveHoverReturn } = this

    return !post?.newsPostBody ? (
      <NotFound />
    ) : (
      post && (
        <DocumentMeta
          {...getMeta(
            post.title || _title,
            post.seoDescription || post.postPreview || _description,
            post?.heroImage[0]?.url || _image,
            post.seoKeywords.length > 0
              ? parseKeywords(post.seoKeywords)
              : _keywords,
            imageProperties,
          )}>
          {!md ? <div style={getStyle(styles.backgroundBlock)} /> : null}
          <div style={styles.container}>
            <div style={getStyle(styles.imageWrapper)}>
              <img
                src={post.heroImage[0].url}
                style={styles.image}
                alt={'heroImg'}
              />
            </div>
            <InformationHeader
              topic={post.blogTopicCategory[0].title}
              speaker={post.author.name}
              date={post.postDate}
              title={post.title}
              topicType={'link'}
              topicRoute={`/blog/${post.blogTopicCategory[0].slug}`}
            />
            <AuthorBio
              url={post.author?.blogBioImage[0]?.url}
              name={post.author.name}
              title={post.author.userTitle}
            />
            <div
              className={post?.dropCap ? 'articlePage dropCap' : 'articlePage'}>
              <Article styles={getStyle(styles.article)} data={post} />
            </div>
            <Link
              style={{
                textDecoration: 'none',
                ...getStyle(styles.returnWrapper),
              }}
              to={'/blog'}>
              <div
                onMouseEnter={touchscreen ? null : onHoverReturn}
                onMouseLeave={touchscreen ? null : leaveHoverReturn}
                style={{
                  ...styles.return,
                  color: returnHovered ? '#FAA860' : '#D07D3C',
                }}>
                <div
                  style={{
                    ...styles.arrow,
                    borderColor: returnHovered ? '#FAA860' : '#D07D3C',
                    left: returnHovered ? -35 : -25,
                  }}
                />
                RETURN TO BLOG
              </div>
            </Link>
          </div>
          <Footer />
        </DocumentMeta>
      )
    )
  }
}

const styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 500,
    position: 'absolute',
    zIndex: -1,
    sm: {
      height: 375,
    },
    '@media (max-width: 1115px)': {
      backgroundColor: '#FFFFFF',
    },
  },
  container: {
    paddingTop: 165,
    width: '100%',
    maxWidth: 1260,
    paddingLeft: 30,
    paddingRight: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 1115px)': {
      paddingTop: 90,
    },
  },
  article: {
    container: {
      borderTopWidth: 0,
      marginTop: 65,
      paddingTop: 0,
      marginLeft: 120,
      marginRight: 0,
      maxWidth: 1086,
      marginBottom: 75,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: '#A39A93',
      paddingBottom: 75,
    },
    content: {
      flexBasis: '100%',
    },
    supplement: {
      content: {
        imgCaption: {
          color: '#6A5B5D',
          fontFamily: 'Neutraface 2 Text',
          fontWeight: 400,
          fontSize: 16,
          fontStyle: 'italic',
        },
      },
    },
    lg: {
      container: {
        marginLeft: 0,
        marginTop: 35,
      },
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 40,
    '@media (min-width: 992px)': {
      height: '100px',
    },
  },
  imageWrapper: {
    position: 'relative',
    height: 0,
    width: '100%',
    paddingBottom: '33.5%',
    overflow: 'hidden',
    marginTop: 30,
    md: {},
    '@media (max-width: 1115px)': {
      boxSizing: 'content-box',
      paddingBottom: '57%',
      marginTop: 0,
      marginLeft: -30,
      paddingRight: 60,
    },
  },
  image: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderWidth: 0,
    overflow: 'hidden',
    objectFit: 'cover',
  },
  titleWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 40,
    md: {
      display: 'unset',
      marginLeft: 0,
    },
  },
  category: {
    fontSize: 18,
    color: '#65A3BE',
  },
  author: {
    fontSize: 18,
    color: '#6A5B5D',
  },
  titleBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: '#A39A93',
    paddingTop: 25,
    marginTop: 16,
    marginBottom: 0,
    md: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: 15,
      marginTop: 15,
      marginBottom: 15,
    },
  },
  date: {
    fontSize: 18,
    color: '#6A5B5D',
  },
  title: {
    fontSize: 45,
    color: '#695B5D',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
  },
  returnWrapper: {
    marginLeft: 120,
    marginRight: 0,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 50,
    fontFamily: 'Neutraface 2 Text',
    fontSize: 22,
    fontWeight: 400,
    letterSpacing: 2.4,
    lg: {
      marginLeft: 0,
    },
  },
  return: {
    position: 'relative',
  },
  arrow: {
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    position: 'absolute',
    top: 8,
    padding: 5,
    marginRight: 4,
    transition: 'left 0.5s',
    transform: 'rotate(135deg)',
    fontSize: 25,
  },
}

export default withTouchscreenSupport(
  gqlConnect('blogPost')(withRouter(withSizes(Radium(BlogPost)))),
)
