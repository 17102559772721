import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { gqlConnect } from 'react-connect-graphql-beta'
import LeaderImage from '../LeaderImage'
import Radium from 'radium'
import Footer from '../../footer/Footer'
import {
  getMeta,
  _description,
  _image,
  parseKeywords,
  _keywords,
  getImageProperties,
} from '../../utils/defaultMeta'
import DocumentMeta from 'react-document-meta'

class Leadership extends Component {
  state = {
    pastors: [],
    elders: [],
    deacons: [],
    nonBoardElders: [],
    staff: [],
    nonBoardElderNote: null,
    mobile: false,
    imageProperties: null,
  }

  async componentDidMount() {
    const { data } = this.props.gql
    const { handleResize } = this

    if (window.innerWidth < 1040) {
      this.setState({
        mobile: true,
      })
    }

    const imageProperties =
      data.seoImage.length > 0
        ? await getImageProperties(data.seoImage[0]?.url)
        : null

    window.addEventListener('resize', handleResize)

    this.setState({
      pastors: data.leaders.filter((leader) => {
        return leader.leaderType === 'pastor'
      }),
      elders: data.leaders.filter((leader) => {
        return leader.leaderType === 'elder'
      }),
      nonBoardElders: data.leaders.filter((leader) => {
        return leader.__typename === 'leaders_nonBoardElder_BlockType'
      }),
      deacons: data.leaders.filter((leader) => {
        return leader.leaderType === 'deacon'
      }),
      staff: data.leaders.filter((leader) => {
        return leader.leaderType === 'staff'
      }),
      imageProperties: imageProperties,
      nonBoardElderNote: data.nonBoardElderNote,
    })
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1040 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1040 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  renderPastors = () => {
    const { pastors } = this.state
    const { xs, getStyle } = this.props

    const pastorStyles = {
      container: {
        marginBottom: 35,
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
        position: 'relative',
        xs: {
          flexDirection: 'column',
        },
      },
      imageWrapper: {
        float: 'left',
        textAlign: 'center',
        marginRight: 35,
        marginBottom: 15,
        xs: {
          float: 'none',
          marginRight: 0,
        },
      },
      emailWrapper: {
        marginTop: 20,
      },
    }

    return pastors.map((pastor, index) => {
      return (
        <div key={index} style={getStyle(pastorStyles.container)}>
          <div style={getStyle(pastorStyles.imageWrapper)}>
            <LeaderImage image={pastor.leaderPicture[0].url} />
            {!xs && (
              <div style={pastorStyles.emailWrapper}>
                <a
                  href={`mailto:${pastor.email}`}
                  style={styles.entryEmail}>{`Email ${pastor.firstName}`}</a>
              </div>
            )}
          </div>
          <div>
            <div
              style={
                styles.entryName
              }>{`${pastor.firstName} ${pastor.lastName}`}</div>
            <div
              style={getStyle({
                ...styles.entryTitle,
                position: 'relative',
              })}>
              {pastor.leaderTitle}
              {(index !== 0 || xs) && (
                <div
                  style={{
                    backgroundColor: '#A39A93',
                    width: xs ? 50 : 70,
                    height: 1,
                    position: 'absolute',
                    left: xs ? 0 : 337,
                    bottom: -20,
                  }}
                />
              )}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: pastor.bio }}
              style={{
                ...styles.entryText,
                marginTop: 40,
              }}
            />
          </div>
          {xs && (
            <div style={pastorStyles.emailWrapper}>
              <a
                href={`mailto:${pastor.email}`}
                style={styles.entryEmail}>{`Email ${pastor.firstName}`}</a>
            </div>
          )}
        </div>
      )
    })
  }

  renderGridSection = (_data) => {
    const { getStyle } = this.props
    const { mobile } = this.state

    return _data.map((entry, index) => {
      return (
        <>
          <div
            key={index}
            style={{
              ...getStyle(styles.gridSection.container),
              opacity: entry.firstName ? 1 : 0,
            }}>
            <LeaderImage
              text={entry.bio}
              image={entry.leaderPicture[0].url}
              firstName={entry.firstName}
              email={entry.email}
              isGrid
            />
            <div
              style={{
                ...styles.entryName,
                marginTop: 10,
              }}>{`${entry.firstName} ${entry.lastName}`}</div>
            <div
              style={{
                ...styles.entryTitle,
              }}>
              {entry.leaderTitle}
            </div>
          </div>
          {_data.length === 2 && index === 1 && !mobile && (
            <div style={{ height: 300, width: 300 }} />
          )}
        </>
      )
    })
  }

  renderNonBoardElders = (elders) => {
    return elders.map((elder, index) => {
      return (
        <div
          key={index}
          style={{ ...styles.entryTitle, fontStyle: 'normal', height: 50 }}>
          {elder.elderName}
        </div>
      )
    })
  }

  render() {
    const { renderPastors, renderGridSection, renderNonBoardElders } = this
    const { getStyle, sm } = this.props
    const { data } = this.props.gql
    const {
      elders,
      nonBoardElders,
      staff,
      mobile,
      imageProperties,
      nonBoardElderNote,
      deacons,
    } = this.state

    return (
      <DocumentMeta
        {...getMeta(
          'Leadership',
          data.seoDescription || _description,
          data.seoImage.length > 0 ? data.seoImage[0].url : _image,
          data.seoKeywords.length > 0
            ? parseKeywords(data.seoKeywords)
            : _keywords,
          imageProperties,
        )}>
        <div style={styles.backgroundBlock} />
        <div
          style={getStyle({
            ...styles.container,
            paddingTop: mobile ? 130 : 195,
          })}>
          <div style={styles.sectionWrapper}>
            <div style={styles.sectionTitle}>Pastors</div>
            <div style={styles.divider} />
            {renderPastors()}
          </div>
          <div style={getStyle(styles.gridSectionWrapper)}>
            <div style={styles.sectionTitle}>Board Elders</div>
            <div style={styles.divider} />
            <div style={getStyle(styles.gridWrapper)}>
              {renderGridSection(elders)}
            </div>
          </div>
          {nonBoardElders.length >= 0 && (
            <div style={getStyle(styles.gridSectionWrapper)}>
              <div style={styles.sectionTitle}>Elders</div>
              <div style={styles.divider} />
              <div
                style={getStyle({
                  ...styles.nonBoardEldersWrapper,
                  maxHeight: sm
                    ? Math.ceil(nonBoardElders?.length / 2) * 50
                    : Math.ceil(nonBoardElders.length / 3) * 50,
                })}>
                {renderNonBoardElders(nonBoardElders)}
              </div>
              <>
                {nonBoardElderNote && (
                  <div style={{ ...styles.entryTitle, marginTop: 15 }}>
                    {nonBoardElderNote}
                  </div>
                )}
              </>
            </div>
          )}
          <div style={getStyle(styles.gridSectionWrapper)}>
            <div style={styles.sectionTitle}>Deacons</div>
            <div style={styles.divider} />
            <div style={getStyle(styles.gridWrapper)}>
              {renderGridSection(deacons)}
            </div>
          </div>
          <div style={getStyle(styles.gridSectionWrapper)}>
            <div style={styles.sectionTitle}>Staff</div>
            <div style={styles.divider} />
            <div style={getStyle(styles.gridWrapper)}>
              {renderGridSection(staff)}
            </div>
          </div>
        </div>
        <Footer />
      </DocumentMeta>
    )
  }
}

const styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 400,
    position: 'absolute',
    zIndex: -1,
    xs: {
      height: 320,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1270,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    paddingTop: 195,
    paddingLeft: 35,
    paddingRight: 35,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    xs: {
      maxWidth: 800,
    },
  },
  gridSectionWrapper: {
    width: '100%',
    marginBottom: 50,
  },
  gridWrapper: {
    display: 'grid',
    gridGap: 25,
    gridTemplateColumns: 'repeat(auto-fit, 300px)',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 1040px)': {
      justifyContent: 'center',
    },
    sm: {
      display: 'flex',
      flexDirection: 'column',
      gridTemplateColumns: 'unset',
    },
  },
  sectionWrapper: {
    width: '100%',
    marginBottom: 50,
  },
  sectionTitle: {
    fontSize: 37,
    fontFamily: 'Neutraface Slab Text',
    color: '#695B5D',
    fontWeight: 300,
  },
  divider: {
    width: '100%',
    height: 1,
    opacity: 0.5,
    backgroundColor: '#695B5D',
    marginTop: 30,
    marginBottom: 35,
  },
  entryName: {
    color: '#65A3BE',
    fontSize: 25,
    letterSpacing: 3.4,
    textTransform: 'uppercase',
  },
  entryTitle: {
    color: '#6A5B5D',
    fontStyle: 'italic',
    fontSize: 18,
  },
  entryText: {
    color: '#6A5B5D',
  },
  entryEmail: {
    color: '#65A3BE',
    fontSize: 18,
  },
  nonBoardEldersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  gridSection: {
    container: {
      textAlign: 'center',
      width: 300,
      marginBottom: 45,
      sm: {
        width: '100%',
      },
    },
  },
}

export default gqlConnect('leadership')(withSizes(Radium(Leadership)))
