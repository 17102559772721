import React, { Component } from 'react'
import {
  ImageCaptionBlock as ImageCaptionBlockComponent,
  withSizes,
} from '@oos-studio/website-common-components'

class ImageCaptionBlock extends Component {
  render() {
    const { data } = this.props
    return <ImageCaptionBlockComponent styles={styles} {...data} />
  }
}

const styles = {
  bottomWrapper: {
    marginLeft: 75,
    marginRight: 75,
    sm: {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  text: {
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    lineHeight: 1.3,
    cursor: 'default',
  },
  image: {
    backgroundColor: '#65A3BE',
    mixBlendMode: 'color',
  },
}

export default withSizes(ImageCaptionBlock)
