import React, { useEffect, useRef, useState } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSizes } from '@oos-studio/website-common-components'
import { useHistory } from 'react-router-dom'
import {
  Collapse,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import NavLink from '../common/NavLink'
import deepmerge from 'deepmerge'
import { mapNavItems } from '../utils/ParseNavigationData'
import { useSelector } from 'react-redux'

const NavbarDesktop = ({ gql, getStyle }) => {
  const { navItems } = gql

  const [open, setOpen] = useState(false)
  const [megaMenu, setMegaMenu] = useState(null)
  const [aside, setAside] = useState(null)
  const [megaMenuOpen, setMegaMenuOpen] = useState(false)
  const [activeHoveredLink, setActiveHoveredLink] = useState(null)
  const [activeHoveredDropdown, setActiveHoveredDropdown] = useState(null)
  const [mmBackgroundHeight, setMmBackgroundHeight] = useState(135)
  const [data, setData] = useState(null)
  const [navBorderWidth, setNavBorderWidth] = useState([0, 0, 0])
  const [navBorderStyle, setNavBorderStyle] = useState(['', '', ''])

  const appState = useSelector((state) => state.app)
  const { showNavUnderline, navColor, dropdownArrowColor } = appState

  const styles = _styles(showNavUnderline, navColor, dropdownArrowColor)

  const history = useHistory()

  let menus = []
  let dropdownCounter = useRef(0)

  useEffect(() => {
    const unlisten = history.listen(() => {
      hideMegaMenu()
    })

    window.addEventListener('resize', onResize)
    window.addEventListener('click', handleOutsideClick)
    window.addEventListener('touchend', handleOutsideClick)

    return () => {
      unlisten()
      window.removeEventListener('resize', onResize)
      window.removeEventListener('click', handleOutsideClick)
      window.removeEventListener('touchend', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    onResize()
  }, [megaMenuOpen])

  useEffect(() => {
    if (gql?.navItems) {
      const items = mapNavItems(navItems, history, getStyle)
      const _data = {
        navigation: {
          brand: {
            url: '/',
            image: {
              title: 'Providence Logo',
              src: require('../assets/ProvidenceLogo.png'),
            },
          },
          dropdownIcon: require('../assets/DropdownCaret.png'),
          hoveredIcon: require('../assets/DropdownCaret_hover.png'),
          items: items,
        },
      }
      setData(_data)
    }
  }, [gql?.navItems])

  const onResize = () => {
    const mmHeight = document.getElementById('megaMenu')?.offsetHeight
    if (mmHeight && mmHeight !== mmBackgroundHeight) {
      setMmBackgroundHeight(mmHeight + 160)
    }
  }

  const handleOutsideClick = (e) => {
    let touchY = null
    let navBottomY = null
    if (e.changedTouches?.length) {
      touchY = e.changedTouches[e.changedTouches.length - 1].clientY
    }
    const mmBackground = document.getElementById('mmBackground')
    if (mmBackground) {
      navBottomY = mmBackground.offsetTop + mmBackground.offsetHeight
    }
    if (touchY > navBottomY) {
      hideMegaMenu()
    }
  }

  const showMegaMenu = async (key) => {
    if (activeHoveredDropdown === key) {
      return
    }

    await hideMegaMenu()
    const item = menus.filter((obj) => {
      return obj?.item?.text === key
    })

    let borderWidth = [0, 0, 0]
    borderWidth[key] = 2

    let borderStyle = ['', '', '']
    borderStyle[key] = 'solid'

    dropdownCounter.current = 0
    setAside(item[0].item.aside)
    setMegaMenu(item[0].item.render())
    setMegaMenuOpen(true)
    setActiveHoveredDropdown(key)
    setNavBorderWidth(borderWidth)
    setNavBorderStyle(borderStyle)
  }

  const hideMegaMenu = (index = null) => {
    setMegaMenu(null)
    setActiveHoveredLink(index)
    setActiveHoveredDropdown(null)
    setAside(null)
    setNavBorderStyle(['', '', ''])
    setNavBorderWidth([0, 0, 0])
    setMegaMenuOpen(false)
  }

  const unHoverLink = () => {
    setActiveHoveredLink(null)
  }

  const toggle = () => {
    setOpen(!open)
  }

  const onClickItem = () => {}

  const onBrandClick = (e) => {
    if (history.location.pathname !== '/') {
      e.preventDefault()
      history.push('/')
    }
  }

  const renderNavigationItems = (item, index) => {
    let navItem = null
    let key = 0

    switch (item.type) {
      case 'link':
        navItem = (
          <NavItem key={index} key={index} style={styles.navItem}>
            <NavLink
              onMouseEnter={() => hideMegaMenu(index)}
              onMouseLeave={() => unHoverLink()}
              history={history}
              useRouter={item.useRouter ? item.useRouter : false}
              onClickItem={onClickItem}
              item={item}
              href={item.url}
              style={{
                ...styles.navLink,
                color:
                  activeHoveredLink === index
                    ? styles.navLink.hovered.color
                    : megaMenuOpen
                      ? styles.mmOpen.navLink.color
                      : styles.navLink.color,
              }}>
              {item.text}
            </NavLink>
          </NavItem>
        )
        break
      case 'dropdown':
        dropdownCounter.current = dropdownCounter.current + 1
        key = item.text
        menus.push({ index: key, item: item })
        navItem = (
          <NavLink
            key={index}
            history={history}
            useRouter={true}
            onClickItem={onClickItem}
            item={item}
            dropdown>
            <DropdownToggle
              style={{
                borderBottomWidth: navBorderWidth[key],
                borderBottomStyle: navBorderStyle[key],
                ...styles.dropdownItem,
                color:
                  activeHoveredDropdown === key
                    ? styles.dropdownItem.hovered.color
                    : megaMenuOpen
                      ? styles.mmOpen.dropdownItem.color
                      : styles.dropdownItem.color,
              }}
              onMouseEnter={() => showMegaMenu(key)}
              onTouchEnd={() => showMegaMenu(key)}
              onMouseLeave={() => unHoverLink()}
              nav>
              {item.text}
              <i
                style={
                  activeHoveredDropdown === key
                    ? deepmerge(
                      styles.dropdownArrow,
                      styles.dropdownArrow.hovered,
                    )
                    : styles.dropdownArrow
                }
              />
            </DropdownToggle>
          </NavLink>
        )
        break
      default:
        break
    }

    return navItem
  }

  const brand = data?.navigation?.brand

  return data ? (
    <div
      id={'navbarContainer'}
      style={styles.container}
      onMouseLeave={() => hideMegaMenu()}>
      <div
        id={'mmBackground'}
        style={
          megaMenuOpen
            ? {
              ...deepmerge(styles.mmBackground, styles.mmOpen.mmBackground),
              height: mmBackgroundHeight,
            }
            : { ...styles.mmBackground, height: mmBackgroundHeight }
        }
      />
      <Navbar
        expand="md"
        color={
          megaMenuOpen
            ? styles.mmOpen.navbar.backgroundColor
            : styles.navbar.backgroundColor
        }
        style={
          megaMenuOpen
            ? deepmerge(styles.navbar, styles.mmOpen.navbar)
            : styles.navbar
        }>
        <NavbarBrand
          onClick={(e) => onBrandClick(e)}
          href="/"
          style={styles.brand}>
          <Media
            object
            src={brand.image.src}
            alt={brand.image.title}
            style={styles.brandImage}
          />
          <NavbarText style={styles.brandTitle}>{brand.title}</NavbarText>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={styles.toggler} />
        <Collapse isOpen={open} navbar style={styles.collapse}>
          <Nav
            id="nav"
            navbar-expand-lg
            navbar
            style={
              megaMenuOpen
                ? deepmerge(styles.nav, styles.mmOpen.nav)
                : styles.nav
            }>
            <div
              style={
                megaMenuOpen
                  ? deepmerge(styles.itemWrapper, styles.mmOpen.itemWrapper)
                  : styles.itemWrapper
              }>
              {data.navigation.items.map((item, index) =>
                renderNavigationItems(item, index),
              )}
            </div>
            <div
              id={'megaMenu'}
              style={
                megaMenuOpen
                  ? deepmerge(styles.megaMenu, styles.mmOpen.megaMenu)
                  : styles.megaMenu
              }>
              {!!aside && (
                <React.Fragment>
                  <Media
                    style={styles.asideImage}
                    object
                    src={aside.brand.image.src}
                    alt={aside.brand.image.title}
                  />
                  <div className={'asideContainer'} style={styles.asideWrapper}>
                    <div className={'asideHeader'} style={styles.asideHeader}>
                      {aside.header}
                    </div>
                    <div className={'asideBody'} style={styles.asideBody}>
                      {aside.text}
                    </div>
                    <div style={styles.asideDivider} />
                  </div>
                </React.Fragment>
              )}
              {megaMenu}
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  ) : null
}

const _styles = (showNavUnderline, navColor, dropdownArrowColor) => {
  return {
    container: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
    },
    mmOpen: {
      itemWrapper: {},
      megaMenu: {
        opacity: 1,
      },
      navbar: {
        backgroundColor: 'white',
      },
      nav: {
        borderBottomWidth: 0,
        marginBottom: -2,
      },
      navLink: {
        color: '#6A5B5D',
      },
      dropdownItem: {
        color: '#6A5B5D',
      },
      mmBackground: {
        opacity: 1,
        height: 465,
        borderBottomWidth: 1,
      },
    },
    mmBackground: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      height: 135,
      width: '100%',
      transition: 'height 0.15s ease-out, opacity 0.25s',
      zIndex: 13,
      pointerEvents: 'none',
      backgroundColor: 'white',
      borderBottomWidth: 0,
      borderBottomOpacity: 0.5,
      borderBottomStyle: 'solid',
      borderBottomColor: 'rgba(106,91,93, 0.5)',
    },
    navbar: {
      padding: 0,
      paddingTop: 50,
      marginTop: -45,
      height: 221,
      zIndex: 14,
      maxWidth: 1260,
      paddingLeft: 30,
      paddingRight: 30,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    brand: {
      padding: 0,
      margin: 0,
      height: 120,
      width: 339,
    },
    itemWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    brandImage: {
      height: 120,
      width: 339,
    },
    collapse: {
      fontSize: 25,
      margin: 0,
      marginLeft: 'auto',
      paddingLeft: 30,
      maxWidth: 806,
      float: 'right',
    },
    brandTitle: {},
    toggler: {},
    nav: {
      justifyContent: 'space-between',
      flex: 1,
      marginTop: -50,
      borderBottomStyle: 'solid',
      borderBottomWidth: showNavUnderline ? 2 : 0,
      borderBottomColor: '#6A5B5D',
    },
    navItem: {
      paddingTop: 50,
    },
    navLink: {
      padding: 0,
      color: navColor, //isWhoIsJesusPage ? '#958E8A' : '#6A5B5D',
      paddingBottom: 8,
      textDecoration: 'none',
      hovered: {
        color: '#CD7D43',
      },
    },
    dropdownMenuContainer: {},
    dropdownContainer: {},
    dropdownItem: {
      padding: 0,
      paddingTop: 50,
      color: navColor, // ? '#958E8A' : '#6A5B5D',
      borderBottomColor: '#CD7D43',
      borderBottomWidth: 4,
      paddingBottom: 10,
      hovered: {
        color: '#CD7D43',
      },
    },
    asideWrapper: {
      fontSize: 25,
      position: 'absolute',
      top: -104,
      left: -255,
      width: 255,
      // height: 400,
      padding: 0,
      paddingTop: 40,
      zIndex: 5,
      backgroundColor: 'white',
    },
    asideBody: {
      opacity: 0,
      fontSize: 18,
      padding: 10,
      marginTop: 15,
      paddingLeft: 35,
      lineHeight: 2,
      color: '#6A5B5D',
      fontStyle: 'italic',
      textAlign: 'left',
    },
    asideImage: {
      display: 'none',
    },
    asideHeader: {
      padding: 0,
      marginLeft: 30,
      marginRight: 30,
      fontSize: 23,
      opacity: 0,

      letterSpacing: 3,
      textTransform: 'uppercase',
      color: '#363636A5',
      borderBottomStyle: 'solid',
      borderBottomWidth: 0,
      borderBottomColor: '#A39A93',
      paddingBottom: 10,
      transition: 'none',
    },
    asideDivider: {
      width: 'auto',
      height: 1,
      backgroundColor: '#A39A93',
      position: 'absolute',
      top: 89,
      left: 30,
      right: 5,
    },
    megaMenu: {
      maxWidth: 861,
      top: 175,
      left: 370,
      right: 0,
      zIndex: 4,
      marginRight: 30,
      paddingLeft: 30,
      position: 'absolute',
      transition: 'opacity 0.25s ease-in',
      padding: 0,
      opacity: 0,
    },
    dropdownArrow: {
      borderColor: dropdownArrowColor,
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 3,
      transform: 'rotate(45deg)',
      marginBottom: 3,
      marginLeft: 7,
      hovered: {
        borderColor: '#CD7D43',
      },
    },
  }
}

export default gqlConnect('navigation')(withSizes(NavbarDesktop))
