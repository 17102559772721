import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Banner extends Component {
  state = {
    mobile: false,
    scrollPos: 0,
    hide: false,
  }
  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1040 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1040 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  handleScroll = () => {
    const scrollPos = window.scrollY
    this.setState({
      scrollPos: scrollPos,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history } = this.props
    const { hide } = this.state

    if (history.location.pathname.includes('live') && !hide) {
      this.setState({
        hide: true,
      })
    } else if (!history.location.pathname.includes('live') && hide) {
      this.setState({
        hide: false,
      })
    }
  }

  componentDidMount() {
    const { handleResize, handleScroll } = this
    const { history } = this.props

    if (window.innerWidth < 1040) {
      this.setState({
        mobile: true,
      })
    }

    if (history.location.pathname.includes('sermons/live')) {
      this.setState({
        hide: true,
      })
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
  }

  onClick = () => {
    const { link, useRouter, history } = this.props
    if (window.isNativeApp && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(link)
      return
    }
    if (useRouter) {
      history.push(link)
      return
    }
    window.open(link, '_blank')
  }

  onDismiss = (e, index) => {
    e.stopPropagation()

    this.props.onDismiss(index)
  }

  render() {
    const {
      text,
      countdown,
      live,
      liveSoon,
      index,
      link,
      showBorder,
      isDismissable,
    } = this.props
    const { onClick, onDismiss } = this

    return (
      <div
        key={index}
        onClick={link ? () => onClick() : null}
        style={{
          ...styles.container,
          backgroundColor: live ? '#6A5B5D' : '#d07d3c',
          cursor: link ? 'pointer' : 'auto',
          borderBottomWidth: showBorder ? 1 : 0,
        }}>
        {isDismissable && (
          <div onClick={(e) => onDismiss(e, index)} style={styles.dismiss}>
            ✕
          </div>
        )}
        <div style={styles.content}>
          {(live || liveSoon) && (
            <div style={styles.live}>
              <div
                style={{
                  ...styles.live.dot,
                  backgroundColor: liveSoon ? 'yellow' : '#FF5440',
                }}
              />
              LIVE
              <div style={styles.live.divider} />
            </div>
          )}
          {text}
          {countdown ? <span style={styles.countdown}>{countdown}</span> : null}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#FAA860',
    display: 'flex',
    width: '100%',
    fontFamily: 'Neutraface 2 Text',
    fontWeight: 500,
    color: '#F8F5EE',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 35,
    paddingRight: 35,
    position: 'relative',
  },
  content: {
    display: 'flex',
  },
  dismiss: {
    position: 'absolute',
    left: 10,
    color: '#F8F5EE',
    cursor: 'pointer',
    fontFamily: 'arial',
    fontSize: 12,
  },
  live: {
    letterSpacing: 3.6,
    display: 'flex',
    alignItems: 'center',
    dot: {
      height: 10,
      width: 10,
      borderRadius: '50%',
      marginRight: 10,
      marginBottom: 2,
    },
    divider: {
      width: 2,
      height: 15,
      backgroundColor: '#F8F5EE',
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 2,
    },
  },
  countdown: {
    width: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
}

Banner.defaultProps = {
  text: '',
  countdown: '',
  link: null,
  live: false,
  useRouter: false,
}

export default withRouter(Banner)
