import React, { useEffect, useRef } from 'react'
import SpeakerFilterSpeaker from './SpeakerFilterSpeaker'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowSpeakers,
  setSelectedSpeaker,
  getLatestSermons,
  setPassageFilterValue,
} from './actions'
import { withSizes } from '@oos-studio/website-common-components'
import { useHover } from '../hooks/useHover'
import { useHistory } from 'react-router-dom'
import DownChevronDark from '../assets/DownChevronDark.png'
import DownChevronLight from '../assets/DownChevronLight.png'
import XIconDark from '../assets/XIconDark.png'
import XIconLight from '../assets/XIconLight.png'

const SpeakerFilter = (props) => {
  let ref = useRef(null)
  const [arrowHoverRef, isArrowHovered] = useHover()

  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)

  const history = useHistory()

  useEffect(() => {
    window.addEventListener('click', handleClick)
    const unsubscribe = history.listen(listen)
    return () => {
      window.removeEventListener('click', handleClick)
      unsubscribe()
    }
  }, [])

  const listen = () => {
    dispatch(setShowSpeakers(false))
    dispatch(setSelectedSpeaker(null))
  }

  const handleClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      return
    }

    if (!state.showSpeakers) {
      dispatch(setShowSpeakers(false))
    }
  }

  const onClick = () => {
    dispatch(setShowSpeakers(!state.showSpeakers))
  }

  const onClearFilter = (e) => {
    e.stopPropagation()

    dispatch(setSelectedSpeaker(null))
    dispatch(setShowSpeakers(false))

    if (state.selectedChapter || state.selectedBook) {
      dispatch(
        getLatestSermons(
          10,
          true,
          state.selectedBook,
          state.selectedChapter,
          null,
        ),
      )
    } else {
      dispatch(getLatestSermons(10))
    }
  }

  return (
    <div ref={ref} style={props.getStyle(styles.container)}>
      <div
        onClick={onClick}
        style={{
          ...styles.heading,
          color: state.showSpeakers ? '#F8F5EE' : '#6A5B5D',
          backgroundColor: state.showSpeakers ? '#6A5B5D' : 'transparent',
          borderColor: state.showSpeakers ? '#6A5B5D' : '#6A5B5D',
        }}>
        <div style={styles.headingTitle}>
          {state.selectedSpeaker || 'Speaker'}
        </div>
        {state.selectedSpeaker ? (
          <div
            ref={arrowHoverRef}
            onClick={onClearFilter}
            style={{
              ...styles.clearFilter,
              backgroundColor: isArrowHovered ? '#a39a9326' : 'transparent',
            }}>
            <img
              src={state.showSpeakers ? XIconLight : XIconDark}
              style={styles.xImage}
            />
          </div>
        ) : (
          <div style={styles.arrowWrapper}>
            <img
              src={state.showSpeakers ? DownChevronLight : DownChevronDark}
              style={{
                ...styles.arrowImage,
                transform: state.showSpeakers
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              }}
            />
          </div>
        )}
      </div>
      {state.showSpeakers && (
        <div style={styles.listWrapper}>
          {props.speakers.map((speaker, index) => {
            return <SpeakerFilterSpeaker key={index} speaker={speaker.title} />
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 400,
    position: 'relative',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
    zIndex: 11,
    flex: 1,
    marginRight: 15,
    xs: {
      maxWidth: 'none',
      width: '100%',
      marginRight: 0,
      marginTop: 15,
      marginBottom: 0,
    },
  },
  heading: {
    fontSize: 18,
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#6A5B5D',
    display: 'inline-flex',
  },
  headingTitle: {
    padding: 10,
    paddingLeft: 15,
    flex: 1,
  },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    maxHeight: 500,
    borderStyle: 'solid',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    borderTopWidth: 0,
    overflow: 'scroll',
    backgroundColor: '#FFFFFF',
  },
  arrowWrapper: {
    padding: 10,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  xImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  clearFilter: {
    padding: 10,
    paddingLeft: 15,
  },
}

export default withSizes(SpeakerFilter)
