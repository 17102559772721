import React, { useEffect, useRef } from 'react'
import PassageFilterBook from './PassageFilterBook'
import bibleData from '../bibleData'
import { useDispatch, useSelector } from 'react-redux'
import {
  getLatestSermons,
  setPassageFilterValue,
  setSelectedBook,
  setShowBooks,
  setSelectedChapter, setSelectedSpeaker,
} from './actions'
import { withSizes } from '@oos-studio/website-common-components'
import { useHover } from '../hooks/useHover'
import { useHistory } from 'react-router-dom'
import DownChevronDark from '../assets/DownChevronDark.png'
import DownChevronLight from '../assets/DownChevronLight.png'
import XIconDark from '../assets/XIconDark.png'
import XIconLight from '../assets/XIconLight.png'

const PassageFilter = (props) => {
  let ref = useRef(null)
  const [arrowHoverRef, isArrowHovered] = useHover()

  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)

  const history = useHistory()

  useEffect(() => {
    window.addEventListener('click', handleClick)
    const unsubscribe = history.listen(listen)
    return () => {
      window.removeEventListener('click', handleClick)
      unsubscribe()
    }
  }, [])

  const listen = () => {
    dispatch(setShowBooks(false))
    dispatch(setPassageFilterValue(null))
  }

  const handleClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      return
    }

    if (!state.showBooks) {
      dispatch(setShowBooks(false))
    }
  }

  const onClick = () => {
    dispatch(setShowBooks(!state.showBooks))
  }

  const onClearFilter = (e) => {
    e.stopPropagation()
    dispatch(setPassageFilterValue(null))
    dispatch(setSelectedBook(null))
    dispatch(setSelectedChapter(null))
    dispatch(setShowBooks(false))

    if (state.selectedSpeaker) {
      dispatch(getLatestSermons(10, true, null, null, state.selectedSpeaker))
    } else {
      dispatch(getLatestSermons(10))
    }
  }

  return (
    <div ref={ref} style={props.getStyle(styles.container)}>
      <div
        onClick={onClick}
        style={{
          ...styles.heading,
          color: state.showBooks ? '#F8F5EE' : '#6A5B5D',
          backgroundColor: state.showBooks ? '#6A5B5D' : 'transparent',
          borderColor: state.showBooks ? '#6A5B5D' : '#6A5B5D',
        }}>
        <div style={styles.headingTitle}>
          {state.passageFilterValue || 'Passage'}
        </div>
        {state.passageFilterValue ? (
          <div
            ref={arrowHoverRef}
            onClick={onClearFilter}
            style={{
              ...styles.clearFilter,
              backgroundColor: isArrowHovered ? '#a39a9326' : 'transparent',
            }}>
            <img
              src={state.showBooks ? XIconLight : XIconDark}
              style={styles.xImage}
            />
          </div>
        ) : (
          <div style={styles.arrowWrapper}>
            <img
              src={state.showBooks ? DownChevronLight : DownChevronDark}
              style={{
                ...styles.arrowImage,
                transform: state.showBooks ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        )}
      </div>
      {state.showBooks && (
        <div style={styles.listWrapper}>
          {bibleData.map((book, index) => {
            return <PassageFilterBook key={index} book={book} />
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 400,
    position: 'relative',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
    zIndex: 10,
    flex: 1,
    marginRight: 15,
    xs: {
      maxWidth: 'none',
      width: '100%',
      marginRight: 0,
      marginTop: 15,
      marginBottom: 0,
    },
  },
  heading: {
    color: '#6A5B5D',
    fontSize: 18,
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
  },
  headingTitle: {
    padding: 10,
    paddingLeft: 15,
    flex: 1,
  },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 500,
    borderStyle: 'solid',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    borderTopWidth: 0,
    overflow: 'scroll',
    backgroundColor: '#FFFFFF',
  },
  arrowWrapper: {
    padding: 10,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  xImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  clearFilter: {
    padding: 10,
    paddingLeft: 15,
  },
}

export default withSizes(PassageFilter)
