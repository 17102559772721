import React, { Component } from 'react'
import { Form, FormGroup, Input } from 'reactstrap'
import { Button, withSizes } from '@oos-studio/website-common-components'
import '../index.css'
import { connect } from 'react-redux'
import {
  sendEmail,
  resetEmailData,
  setEmailAddress,
  setEmailMessage,
  setEmailName,
} from './actions'
import Radium from 'radium'
import RectButton from '../common/RectButton'

class FooterContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
  }

  componentWillUnmount() {
    const { resetEmailData } = this.props
    resetEmailData()
  }

  onChangeName = (val) => {
    const { setEmailName } = this.props
    setEmailName(val)
  }

  onChangeEmail = (val) => {
    const { setEmailAddress } = this.props
    setEmailAddress(val)
  }

  onChangeMessage = (val) => {
    const { setEmailMessage } = this.props
    setEmailMessage(val)
  }

  onSubmit = (e) => {
    const {
      sendEmail,
      isSendingEmail,
      data,
      emailMessage,
      emailName,
      emailAddress,
    } = this.props

    e.preventDefault()

    if (isSendingEmail) {
      return
    }

    const email = {
      from: data.fromEmail,
      to: data.toEmail,
      confirmationEmail: data.confirmationEmailText,
      confirmationEmailSubject: data.confirmationEmailSubject,
      name: emailName,
      message: emailMessage,
      email: emailAddress,
    }

    sendEmail(email)
  }

  render() {
    const { getStyle, data, emailStatusMessage, isSendingEmail } = this.props
    const { onChangeName, onChangeEmail, onChangeMessage } = this

    const styles = getStyle(_styles)
    return (
      <div style={styles.container}>
        <div style={styles.header}>{data.contactFormHeader}</div>
        <Form style={styles.form}>
          <FormGroup style={styles.formGroup}>
            <div style={styles.formLabel}>NAME</div>{' '}
            <Input
              onChange={(e) => onChangeName(e.target.value)}
              style={styles.input}
              type="text"
              name="name"
              id="name"
            />
          </FormGroup>
          <FormGroup style={styles.formGroup}>
            <div style={styles.formLabel}>EMAIL</div>
            <Input
              onChange={(e) => onChangeEmail(e.target.value)}
              style={styles.input}
              type="email"
              name="email"
              id="email"
            />
          </FormGroup>
          <FormGroup style={styles.formGroup}>
            <div style={styles.formLabel}>MESSAGE</div>
            <Input
              onChange={(e) => onChangeMessage(e.target.value)}
              style={styles.textArea}
              type="textarea"
              name="message"
              id="message"
            />
          </FormGroup>
        </Form>
        <div style={styles.sendWrapper}>
          <div style={styles.sendMessage}>{emailStatusMessage}</div>
          <RectButton
            text={isSendingEmail ? 'Submitting...' : 'Submit'}
            onClick={this.onSubmit}
            hoverLight
            light
          />
        </div>
      </div>
    )
  }
}

const _styles = {
  container: {
    marginTop: 10,
    marginLeft: 35,
  },
  header: {
    fontSize: 22,
    color: '#FDECBB',
    paddingLeft: 125,
    letterSpacing: 2.6,
    marginBottom: 20,
    '@media (max-width: 1111px)': {
      fontSize: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  formGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  input: {
    height: 45,
    borderRadius: 0,
    backgroundColor: '#F8F5EE',
    color: '#6A5B5D',
  },
  formLabel: {
    fontSize: 20,
    minWidth: 100,
    marginRight: 25,
    textAlign: 'right',
    paddingTop: 10,
    letterSpacing: 2,
    color: '#F8F5EE',
    '@media (max-width: 1111px)': {
      fontSize: 18,
    },
  },
  textArea: {
    height: 138,
    paddingTop: 15,
    borderRadius: 0,
    backgroundColor: '#F8F5EE',
    color: '#6A5B5D',
  },
  sendWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 125,
    alignItems: 'flex-start',
  },
  sendMessage: {
    color: '#FCEBBE',
    marginBottom: 15,
    textAlign: 'left',
  },
  button: {
    borderColor: '#F8F5EE',
    borderWidth: 1,
    backgroundColor: 'transparent',
    fontSize: 20,
    color: '#F8F5EE',
    borderRadius: 0,
    letterSpacing: 3.4,
    hovered: {
      borderWidth: 1,
      backgroundColor: 'transparent',
      color: '#FAA860',
      borderColor: '#FAA860',
    },
    sending: {
      borderColor: '#F8F5EE',
      borderWidth: 1,
      backgroundColor: 'transparent',
      fontSize: 20,
      color: '#F8F5EE',
      borderRadius: 0,
      letterSpacing: 3.4,
      hovered: {
        borderColor: '#F8F5EE',
        borderWidth: 1,
        backgroundColor: 'transparent',
        fontSize: 20,
        color: '#F8F5EE',
        borderRadius: 0,
        letterSpacing: 3.4,
      },
    },
  },
  md: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 35,
      paddingBottom: 35,
      marginLeft: 0,
    },
    header: {
      fontSize: 27,
      paddingLeft: 0,
      minWidth: 0,
      textTransform: 'uppercase',
      '@media (max-width: 991px)': {
        fontSize: 27,
      },
    },
    form: {
      width: '100%',
    },
    formGroup: {
      flexDirection: 'column',
      marginBottom: 10,
    },
    formLabel: {
      minWidth: 0,
      textAlign: 'left',
      marginRight: 0,
      marginBottom: 10,
      '@media (max-width: 991px)': {
        fontSize: 20,
      },
    },
    input: {},
    textArea: {
      marginBottom: 30,
    },
    sendWrapper: {
      marginLeft: 0,
      alignItems: 'center',
    },
    sendMessage: {
      textAlign: 'center',
    },
    button: {
      alignSelf: 'center',
      width: 215,
      sending: {
        alignSelf: 'center',
        width: 215,
      },
    },
  },
  xs: {
    header: {
      fontSize: 22,
    },
  },
}

const mapStateToProps = (state) => {
  return {
    isEmailSuccessful: state.footer.isEmailSuccessful,
    isSendingEmail: state.footer.isSendingEmail,
    emailStatusMessage: state.footer.emailStatusMessage,
    emailMessage: state.footer.emailMessage,
    emailName: state.footer.emailName,
    emailAddress: state.footer.emailAddress,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (email) => dispatch(sendEmail(email)),
    setEmailMessage: (message) => dispatch(setEmailMessage(message)),
    setEmailName: (name) => dispatch(setEmailName(name)),
    setEmailAddress: (address) => dispatch(setEmailAddress(address)),
    resetEmailData: () => dispatch(resetEmailData()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSizes(Radium(FooterContactForm)))
