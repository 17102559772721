import React, { Component } from 'react'
import gsap, { Power2 } from 'gsap'
import { NavLink } from '@oos-studio/website-common-components'
import { withRouter } from 'react-router-dom'

class ResourcesMobile extends Component {
  open = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#resourcesContainer', { display: 'flex' }, 0)
    tl.to('#resourcesContainer', { height: 'auto', paddingTop: 15 }, 0)
    tl.to('#resourcesContainer', { opacity: 1 }, 0)
  }
  close = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#resourcesContainer', { display: 'none' }, duration)
    tl.to('#resourcesContainer', { opacity: 0 }, 0)
    tl.to('#resourcesContainer', { height: 0, paddingTop: 0 }, 0)
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  render() {
    const { data, useRouter, onClickItem, history } = this.props

    return (
      <div id="resourcesContainer" style={styles.container}>
        {data.map((r, index) => {
          return (
            <NavLink
              history={history}
              useRouter={
                r.imageEntry.length > 0 || (r.imageUrl && r.imageUrl[0] === '/')
              }
              onClickItem={onClickItem}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              item={{
                url:
                  r.imageEntry.length > 0 ? r.imageEntry[0]?.uri : r.imageUrl,
              }}>
              <div
                style={{
                  ...styles.imageCenteredText,
                  backgroundImage: `url(${r.image[0]?.url}`,
                }}>
                {r.imageText}
              </div>
            </NavLink>
          )
        })}
      </div>
    )
  }
}

const resourcesData = [
  {
    image: {
      image: 'https://picsum.photos/1920/1080',
    },
    title: 'Sermons',
  },
  {
    image: {
      image: 'https://picsum.photos/1920/1080',
    },
    title: 'Blog',
  },
]

const styles = {
  imageCenteredText: {
    width: '100%',
    padding: 0,
    marginTop: 10,
    height: 150,
    backgroundColor: 'rgba(106,91,93,0.5)',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    fontSize: 32,
    color: 'white',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '150px',
    maxWidth: 500,
    margin: 'auto',
  },
  container: {
    display: 'none',
    flexDirection: 'column',
    width: '100%',
    opacity: 0,
    paddingBottom: 0,
    paddingTop: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  img: {
    container: {
      width: '100%',
      padding: 0,
      marginTop: 10,
    },
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    image: {
      objectFit: 'cover',
      width: '100%',
      maxWidth: 500,
      height: 150,
    },
    text: {
      fontSize: 32,
      color: '#6A5B5D',
    },
  },
}

export default withRouter(ResourcesMobile)
