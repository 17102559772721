import React from 'react'
import RectButton from '../common/RectButton'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSizes } from '@oos-studio/website-common-components'

const ConnectForm = ({ visible, close, gql, xs }) => {
  const { data } = gql

  return (
    <div
      style={{
        ...styles.container,
        bottom: visible ? (xs ? 90 : 100) : -40,
        opacity: visible ? 1 : 0,
        transition: 'all 0.15s',
        pointerEvents: visible ? 'all' : 'none',
      }}>
      <div style={styles.closeWrapper} onClick={close}>
        <img src={require('../assets/XIconDark.png')} style={styles.close} />
      </div>
      <div style={styles.textWrapper}>{data.connectFormText}</div>
      <div style={styles.buttonWrapper}>
        {data.buttons.map((button, index) => {
          return (
            <div
              style={{
                marginBottom: index === data.buttons.length - 1 ? 0 : 10,
              }}
              key={index}>
              <RectButton
                onClick={() => {
                  close()
                  window.open(button.formLink, '__blank')
                }}
                fontSize={16}
                fullWidth
                text={button.text}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    width: 'calc(100vw - 35px)',
    maxWidth: 400,
    height: 'fit-content',
    // maxHeight: 500,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#A39A93',
    borderRadius: 5,
    zIndex: 99999,
    boxShadow: '5px 5px 20px 0 rgb(0 0 0 / 10%)',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    paddingTop: 45,
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    cursor: 'pointer',
    position: 'absolute',
    top: 15,
    right: 15,
  },
  close: {
    width: 12,
    height: 12,
  },
  textWrapper: {
    marginBottom: 25,
    fontSize: 18,
    color: '#6A5B5D',
    textAlign: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}

export default gqlConnect('connectForms')(withSizes(ConnectForm))
