import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { Tween, Timeline } from 'react-gsap'
import ArrowAnimated from '../../common/ArrowAnimated'

class Hero extends Component {
  render() {
    const {
      getStyle,
      xs,
      titleImage,
      backgroundImage,
      mobileBackgroundImage,
    } = this.props

    return (
      <div style={styles.container}>
        <img
          src={titleImage}
          style={getStyle(styles.titleImage)}
          alt={'titleImage'}
        />
        <img
          src={xs ? mobileBackgroundImage : backgroundImage}
          style={getStyle(styles.backgroundImage)}
          alt={'backgroundImage'}
        />
        <ArrowAnimated styles={{ borderColor: '#CE7D43' }} />
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100vw',
    height: 'calc(100vh + 50px)',
    position: 'relative',
  },
  titleImage: {
    position: 'absolute',
    top: -95,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: 700,
    height: 269,
    sm: {
      width: 576,
      height: 221,
    },
    xs: {
      width: 405,
      height: 155,
      top: -60,
    },
    xxs: {
      width: 300,
      height: 115,
    },
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}

export default withSizes(Hero)
