import {
  SET_DROPDOWN_ARROW_COLOR,
  SET_IS_NATIVE_APP,
  SET_NAV_COLOR,
  SET_SHOW_NAV_UNDERLINE,
} from './actions'

const initialState = {
  isNative: false,
  navColor: '#6A5B5D',
  showNavUnderline: true,
  dropdownArrowColor: '#6A5B5D',
}

const app = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_IS_NATIVE_APP:
      return {
        ...state,
        isNative: true,
      }
    case SET_NAV_COLOR:
      return {
        ...state,
        navColor: payload,
      }
    case SET_SHOW_NAV_UNDERLINE:
      return {
        ...state,
        showNavUnderline: payload,
      }
    case SET_DROPDOWN_ARROW_COLOR:
      return {
        ...state,
        dropdownArrowColor: payload,
      }
    default:
      return state
  }
}

export default app
