import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import CardGridOld from '../website-common-comps-refactor/CardGrid'
import Radium from 'radium'
import PageFilter from './PageFilter'
import RectButton from './RectButton'

class CardGrid extends Component {
  state = {
    activeCard: -1,
    filteredData: null,
    activeFilters: [],
    isFront: false,
  }

  mapDataToProps = (data) => {
    const { flipCard } = this
    const { activeCard, isFront } = this.state

    return data?.map((card, index) => {
      const isFlipped = activeCard === index
      return {
        header: '',
        image: {
          title: 'pageCardImg',
          src: card.cardImage[0].url,
        },
        title: card.cardTitle,
        subtitles: [card.cardSubtitle],
        text: card.cardFrontText || '',
        flippedText: card.cardDescription,
        isFlipped: isFlipped,
        flippedHeight: 'calc(100% - 75px)',
        onClick: isFlipped ? () => flipCard(index) : null,
        button: {
          text: '',
          url: '',
          render: () => {
            const useRouter = card.entryLink?.length > 0 && card.linkToPage
            const routerUrl = card.entryLink[0]?.slug
            const url = useRouter
              ? routerUrl
              : card.linkToPage
              ? card.externalLink
              : isFlipped && card.leaderEmail
              ? `mailto:${card.leaderEmail}`
              : null
            return (
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <RectButton
                  fontSize={16}
                  onClick={url ? null : () => flipCard(index)}
                  url={url}
                  isFlipped={isFlipped}
                  text={isFlipped ? 'Contact Leader' : card.cardButtonText}
                  external={!useRouter}
                  isFront={isFront}
                />
              </div>
            )
          },
        },
      }
    })
  }

  flipCard = (index) => {
    const { activeCard, isFront } = this.state

    this.setState({
      isFront: !isFront,
    })

    if (index !== activeCard) {
      this.setState({
        activeCard: index,
      })
    } else {
      this.setState({
        activeCard: -1,
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props
    const { activeFilters } = this.state
    if (activeFilters !== prevState.activeFilters) {
      if (activeFilters?.length <= 0) {
        this.setState({
          filteredData: null,
        })
      } else {
        const formattedData = data?.map((card) => {
          return {
            ...card,
            metadata: card.metadata?.replaceAll(' ', '')?.toLowerCase(),
          }
        })

        let filteredData = []
        const tmp = formattedData?.forEach((fData) => {
          let canAdd = true
          for (const filter of activeFilters) {
            const formattedKey = filter.filter.replaceAll(' ', '').toLowerCase()
            const formattedValue = filter.option
              .replaceAll(' ', '')
              .toLowerCase()
            const formattedFilter = formattedKey + ':' + formattedValue
            if (!fData.metadata?.includes(formattedFilter)) {
              canAdd = false
            }
          }
          if (canAdd) {
            filteredData.push(fData)
          }
        })

        this.setState({
          filteredData: filteredData?.length > 0 ? filteredData : null,
        })
      }
    }
  }

  onSelectFilterOption = (filter, option) => {
    const { activeFilters } = this.state

    if (filter && !option) {
      this.setState({
        activeFilters: activeFilters.filter((f) => f.filter !== filter),
      })
    } else {
      const tmpActiveFilters = activeFilters.filter((f) => f.filter !== filter)
      this.setState({
        activeFilters: tmpActiveFilters
          ? [...tmpActiveFilters, { filter, option }]
          : [{ filter, option }],
      })
    }
  }

  renderFilters = (data) => {
    const { getStyle } = this.props

    if (!data) {
      return null
    }

    let metadata = data?.map((card) => {
      return card.metadata
    })

    if (metadata?.length <= 0 || !metadata.find((m) => !!m)) {
      return null
    }

    let filters = []
    metadata.forEach((m) => {
      const metaSplit = m?.split(',')
      if (metaSplit?.length > 0) {
        metaSplit.forEach((ms) => {
          const filterSplit = ms?.split(':')
          if (filterSplit?.length > 0) {
            const filter = filters.find((f) => f.title === filterSplit[0])
            if (!filter) {
              filters.push({
                title: filterSplit[0],
                options: [filterSplit[1]],
              })
            } else {
              const option = filter.options.find((o) => o === filterSplit[1])
              if (!option) {
                filter.options.push(filterSplit[1])
              }
            }
          }
        })
      }
    })

    filters = filters.filter((f) => f.options[0] !== undefined)

    return (
      <div style={getStyle(styles.filterContainer)}>
        <div style={getStyle(styles.filterSubWrapper)}>
          {filters.map((f, index) => {
            let options = f.options
            const days = [
              'sunday',
              'monday',
              'tuesday',
              'wednesday',
              'thursday',
              'friday',
              'saturday',
            ]
            let daysMap = {}
            days.forEach((day, index) => {
              daysMap[day] = index
            })
            let weekdayFound = false
            for (const option of options) {
              const _option = option?.split(' ')?.join('')?.toLowerCase()
              if (days.includes(_option)) {
                weekdayFound = true
              }
            }
            if (weekdayFound) {
              options = options.sort((a, b) => {
                const _a = a?.split(' ')?.join('')?.toLowerCase()
                const _b = b?.split(' ')?.join('')?.toLowerCase()
                return daysMap[_a] - daysMap[_b] || _a.localeCompare(_b)
              })
            } else {
              options = options.sort((a, b) => a.localeCompare(b))
            }
            return (
              <PageFilter
                onSelectOption={(option) =>
                  this.onSelectFilterOption(f.title, option)
                }
                key={index}
                title={f.title}
                options={options}
              />
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const {
      data,
      getStyle,
      hideGap,
      isInstagramCard,
      instagramCardData,
    } = this.props
    const { filteredData, activeFilters, isFront } = this.state
    const { mapDataToProps, renderFilters } = this

    let _data
    if (filteredData) {
      _data = filteredData
    } else if (activeFilters?.length) {
      _data = []
    } else {
      _data = data
    }
    const cards = isInstagramCard ? [instagramCardData] : mapDataToProps(_data)

    return (
      <div
        style={{
          position: 'relative',
          width: '100vw',
          backgroundColor: '#F8F5EE',
        }}>
        <div style={{ ...styles.background, height: hideGap ? 0 : 85 }} />
        {renderFilters(data)}
        {_data?.length || (isInstagramCard && cards[0] !== null) ? (
          <CardGridOld
            cards={cards}
            isFront={isFront}
            styles={{
              ...styles,
              card: getStyle(styles.card),
              cardFlipped: getStyle(styles.cardFlipped),
              row:
                cards.length % 3 !== 0
                  ? getStyle(styles.rowCentered)
                  : getStyle(styles.row),
              wrapper: getStyle(styles.wrapper),
            }}
          />
        ) : (
          <div style={getStyle(styles.noResultsWrapper)}>
            <div style={getStyle(styles.noResultsSubWrapper)}>
              <div style={getStyle(styles.noResultsText)}>
                No small groups found
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  background: {
    backgroundColor: '#FFFFFF',
    height: 85,
    position: 'absolute',
    zIndex: 0,
    width: '100%',
  },
  filterContainer: {
    width: '100%',
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 35,
    paddingLeft: 75,
    paddingRight: 75,
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  filterSubWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 1200,
    justifyContent: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto',
    sm: {
      flexDirection: 'column',
    },
  },
  container: {
    maxWidth: 'none',
    position: 'relative',
  },
  flippedText: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    color: '#6A5B5D',
    padding: 25,
    transition: 'all 0.15s',
    cursor: 'pointer',
    width: '100%',
    transform: 'rotateY(180deg)',
  },
  card: {
    backgroundColor: 'white',
    color: '#6A5B5D',
    paddingBottom: 30,
    transform: 'rotateY(0deg)',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  },
  cardFlipped: {
    backgroundColor: 'white',
    color: '#6A5B5D',
    paddingBottom: 30,
    transform: 'rotateY(180deg)',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  },
  body: {
    paddingBottom: 0,
  },
  footer: {
    marginTop: 25,
  },
  image: {
    height: 300,
    objectFit: 'cover',
  },
  separator: {
    borderColor: '#A39A93',
    borderWidth: 1,
  },
  header: {
    color: '#D07D3C',
    display: 'none',
    letterSpacing: 3.4,
  },
  subtitle: {
    color: '#A39A93',
    letterSpacing: 1.8,
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: 18,
  },
  wrapper: {
    maxWidth: 1350,
  },
  text: {
    textAlign: 'center',
  },
  arrow: {
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: 5,
    marginRight: 4,
    marginLeft: 4,
    marginBottom: 10,
    transition: 'left 0.5s, border-color 0.25s',
    transform: 'rotate(135deg)',
    fontSize: 25,
    borderColor: '#6A5B5D',
    hovered: {
      borderColor: '#D07D3C',
    },
  },
  row: {
    marginLeft: 55,
    justifyContent: 'flex-start',
    marginRight: 55,
    lg: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  rowCentered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 75,
    paddingRight: 75,
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  noResultsSubWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 1200,
    justifyContent: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 55,
    sm: {
      flexDirection: 'column',
    },
  },
  noResultsText: {
    fontSize: 40,
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    color: '#6A5B5D',
    xs: {
      fontSize: 32,
    },
  },
}

export default withSizes(Radium(CardGrid))
