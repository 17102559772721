export default [
  {
    id: 'navigation',
    mapToProps: (res) => {
      return {
        navItems: res.data.globalSet.navigationBarItems,
      }
    },
    query: `
   query getNavigation{
  globalSet(handle:"navigationBar"){
    __typename,
  ... on navigationBar_GlobalSet{
      navigationBarItems{
        ... on navigationBarItems_items_BlockType{
          navigationBarUrl,
          displayName,
          entry{
            slug,
            title,
            uri,
            url,
          }
         menu{
            ... on menus_menu_Entry{
              title,
              menuAsideText,
            }
            children{
              ... on menus_map_Entry{
                googleApiKey,
                pinLocation{
                  latitude,
                  longitude,
                },
                infoWindowText,
              }
             ... on menus_links_Entry{
                links{
                  ... on links_urlLink_BlockType{
                    displayName,                
                    linkUrl,
                  }
                  ... on links_entryLink_BlockType{              
                    displayName,
                    entry{
                      title,
                      slug,
                      uri,
                    }
                  }
                }
              
              }
              ... on menus_image_Entry{
                title, 
                image{
                  url,
                }
                imageEntry{
                  slug,
                  uri,
                }
                imageText,
          imageUrl,
              }
              ... on menus_information_Entry{
                title,
                heading,
                menuInformationItems{
                  ... on menuInformationItems_items_BlockType{
                    text
                    subText
                    itemSubtitle
                    itemButtonUrl
                    itemButtonText
                    itemButtonEntry{
                      slug
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`,
  },
  {
    id: 'homepage',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `query getHomepage{
  entry(section:"home"){
    ... on home_home_Entry{
      introText,
      introOverlayImage{
        url,
      },
      seoImage{
        url,
      },
      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      slider{
        ... on slider_slide_BlockType{
          slideImage{
            url
          }
          slideImageMobile{
            url
          }
          slideTitleSmall
          slideTitleLarge
          buttonText
          contentVerticalAlignment
          contentHorizontalAlignment
          buttonUrl
          buttonEntry{
            slug
            uri
          }
        }
      }
      introSlides{
        ... on introSlides_slide_BlockType{
          slideUrl,
          image{
            url,
          },
          entry{
            slug,
            title,
          }
        }
      },
      parallaxUrl,
      parallaxEntry{
        slug,
        title,
      },
      frontImage{
        url,
      },
      backImage{
        url,
      },
      description,
      buttonText,
      aboutUrl,
      aboutEntry{
        slug,
        title,
      },
      aboutBackgroundImage{
        url,
      },
      aboutBackOverlayImage{
        url,
      },
      aboutMiddleOverlayImage{
        url,
      },
      aboutFrontOverlayImage{
        url,
      },
      smallGroupsDescription,
      smallGroupsBackgroundImage{
        url,
      },
      sealImage{
        url,
      },
      mapImage{
        url,
      },
      mapButtonText,
      mapUrl,
      mapEntry{
        slug,
        title,
      }
    },
  }
}`,
  },
  {
    id: 'homeFeed',
    mapToProps: (res) => {
      return {
        data: res.data,
      }
    },
    query: `query getHomeFeed{
    globalSet(handle:"instagram"){
    name,   
   \t... on instagram_GlobalSet{
      instagramData{
       ... on instagramData_loginData_BlockType{
        instagramUsername,
        instagramPassword,
        accessToken
        profileUrl
      }
      }
    }
  }
  entries(section:"blog", limit:1){
    title,
    slug,
    ... on blog_blog_Entry{
      heroImage{
        url,
      },
      dropCap,
      postDate,
      author {
        name,
      },
      postPreview,
      body{
        ... on body_bodyText_BlockType{
          text,
        },
        ... on body_asset_BlockType{
          position,
        \tasset{
            url,
          }
          caption,
        },
      }
    }
  }
}`,
  },
  {
    id: 'blog',
    mapToProps: (res) => {
      return {
        data: res.data.entries,
      }
    },
    query: `query getBlog{
  entries(section:"blog"){
    title,
    ... on blog_blog_Entry{
      blogTopicCategory{
        title,
        slug,
      },
      heroImage{
        url,
      },
      dropCap,
      postPreview,
      postDate,
      slug,
      uri,
      author {
        name,
        title,
        ... on User{
          userTitle,
        blogBioImage {
          url
        },
        }
      },
      body{
        ... on body_bodyText_BlockType{
          text,
          typeHandle,
        },
        ... on body_asset_BlockType{
          position,
          typeHandle,
        \tasset{
            url,
          }
          caption,
        },
      }
    }
  }
}`,
  },
  {
    id: 'seriesDefaultImage',
    mapToProps: (res) => {
      return {
        image: res.data.globalSet.defaultImage[0].url,
      }
    },
    query: `query getDefaultSeriesImage{
  globalSet(handle:"series"){
    ... on series_GlobalSet{
      defaultImage{
        url,
      }
    }
  }
}`,
  },
  {
    id: 'blogPost',
    mapToProps: (res) => {
      return {
        data: res.data.entries,
      }
    },
    query: `query getBlogPost{
  entries(section:"blog"){
    ... on blog_blog_Entry{
      title,
      author{
        name,
        ... on User{
        userTitle,
        blogBioImage {
          url
        },
        }
      },
      blogTopicCategory{
        title,
        slug,
      },
      heroImage{
        url,
      },
      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      dropCap,
      postPreview,
      postDate,
      slug,
      body{
        ... on body_bodyText_BlockType{
          text,
          typeHandle,
        },
        ... on body_asset_BlockType{
          position,
          caption,
          typeHandle,
          asset{
            url,
          }
        },
      },
    }
  }
}`,
  },
  {
    id: 'page',
    slug: true,
    mapToProps: (res) => {
      const filteredEntries = res.data.entries.filter((entry) =>
        entry.__typename.includes('pages_page_Entry'),
      )
      return {
        data: filteredEntries[0],
        instagramData: res.data.globalSet.instagramData,
      }
    },
    query: `query getPage($slug:[String]){
  globalSet(handle:"instagram"){
    ... on instagram_GlobalSet{
      instagramData{
        ... on instagramData_loginData_BlockType{
          instagramUsername
          instagramPassword
          accessToken
          profileUrl
        }
      }
    }
  }
  entries(slug:$slug){
    title,
    ... on pages_page_Entry{
      pageHeroImage{
        url,
      },
      pageBody,
      pageBodyNew{
        ... on pageBodyNew_instagramcard_BlockType{
          instagramUsername
        }
        ... on pageBodyNew_pageTitle_BlockType{
          pageTitleField
        }
        ... on pageBodyNew_textBlock_BlockType{
          text
        }
        ... on pageBodyNew_spacer_BlockType{
          spacer
        }
        
    ... on pageBodyNew_assetBlock_BlockType{
          isPageHero
          assetField {
            id
            url
          }
        }
          ... on pageBodyNew_slide_BlockType{
            slideImage{
              url
            }
            slideImageMobile{
              url
            }
            slideTitleSmall
            slideTitleLarge
            buttonText
            buttonUrl
            buttonEntry{
              uri
              slug
            }
            contentVerticalAlignment
            contentHorizontalAlignment
          }
        ... on pageBodyNew_card_BlockType {
          cardImage {
            url
          }
          cardTitle
          leaderEmail
          cardSubtitle
          cardFrontText
          cardButtonText
          linkToPage
          entryLink {
            uri
            slug
            title
          }
          externalLink
          cardDescription
          metadata
        }
        ... on pageBodyNew_map_BlockType{
          mapEntry{
            ... on maps_maps_Entry{
              pins {
                ... on pins_pinBlockType_BlockType {
                  pinLocation {
                    latitude
                    longitude
                  }
                  description
                  pinTitle
                  buttonEntry {
                    slug
                    uri
                  }
                  buttonUrl
                  buttonText
                }
              }
            }
          }
        }
        ... on pageBodyNew_apiEventFeed_BlockType{
          eventFeedTitle
          integration
          eventFeedBackgroundColor{
            ... on colors_colors_Entry{
          color{
            ... on color_colorBlockType_BlockType{
              colorValue,
            }
          }
        }
          }
        }
          ... on pageBodyNew_singleEvent_BlockType{
            eventTitle
            eventDate
            eventTime
            eventLocation
            eventDescription
            targetGroup
          }
      }
      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      pageCardGrid{
        ... on pageCardGrid_card_BlockType{
          cardTitle,
          cardSubtitle,
          cardImage{
            url,
          },
          linkToPage,
          cardDescription,
          cardFrontText,
          cardButtonText,
          leaderEmail,
          externalLink,
          metadata,
          entryLink{
            slug,
            uri,
            title,
          },
        }
      }
      eventFeedTitle,
      eventFeed{
        ... on eventFeed_event_BlockType{
          eventTitle,
          eventDate,
          targetGroup,
          eventDescription,
        }
      },
    }
  }
}`,
  },
  {
    id: 'footer',
    mapToProps: (res) => {
      return {
        data: res.data.globalSet,
      }
    },
    query: `query getFooter{
  globalSet(handle:"footer"){
    ... on footer_GlobalSet{
      logo{
        url,
      },
      address,
      phoneNumber,
      email,
      confirmationEmailText,
      confirmationEmailSubject,
      providenceConnect{
      \t... on providenceConnect_displayText_BlockType{
          text,
        },
        ... on providenceConnect_link_BlockType{
          connectLink,
        }
      },
      affiliation,
      copyright,
      contactFormHeader,
      fromEmail:contactFormFromEmail,
      toEmail:contactFormToEmail,
      socialMediaLinks{
        ... on socialMediaLinks_mediaSite_BlockType{
          siteName,
          siteUrl,
          icon{
            url,
          },
        }
      }
    }
  }
}`,
  },
  {
    id: 'sermonGlobal',
    mapToProps: (res) => {
      return {
        data: res.data.globalSet,
        speakers: res.data.categories.filter((cat) => {
          return cat.hasOwnProperty('title')
        }),
      }
    },
    query: `query getSermonGlobal{
  globalSet(handle:"sermon"){
    ... on sermon_GlobalSet{
      defaultAudioPlayerImage{
        url,
      },
      defaultImage{
        url,
      }
    }
  }
  categories{
    ... on sermonSpeakers_Category{
      title,
    }
  }
}`,
  },
  {
    id: 'calendar',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `query getCalendar{
  entry(section:"calendar"){
    ... on calendar_calendar_Entry{
      seoImage{
        url,
      },
      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      calendarUrl,
      eventFeedTitle,
      pageBodyNew{
        ... on pageBodyNew_slide_BlockType{
          slideImage{
              url
            }
            slideImageMobile{
              url
            }
            slideTitleSmall
            slideTitleLarge
            buttonText
            buttonUrl
            buttonEntry{
              uri
              slug
            }
            contentVerticalAlignment
            contentHorizontalAlignment
        }
        ... on pageBodyNew_apiEventFeed_BlockType{
          integration
          eventFeedBackgroundColor{
            ... on colors_colors_Entry{
          color{
            ... on color_colorBlockType_BlockType{
              colorValue,
            }
          }
        }
          }
        }
      }
    }
  }
}`,
  },
  {
    id: 'leadership',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `query getLeadership{
  entry(section:"leadership"){
    ... on leadership_leadership_Entry{
      seoImage{
        url,
      },
      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      nonBoardElderNote,
      leaders{
        ... on leaders_nonBoardElder_BlockType{
          elderName,
        }
        ... on leaders_leader_BlockType{
          firstName,
          lastName,
          leaderTitle,
          bio,
          email,
          leaderType,
          leaderPicture{
            url,
          }
        }
      }
    }
  }
}`,
  },
  {
    id: 'banners',
    mapToProps: (res) => {
      return {
        data: res.data.entries,
      }
    },
    query: `query getNotifications{
  entries(section:"bannerNotifications", status: ["live", "pending", "expired"]){
    ... on bannerNotifications_bannerNotifications_Entry{
      id,
      notificationText,
      status,
      dismissable,
      bannerLink,
      bannerSlug,
      postDate
      expiryDate
    }
  }
}`,
  },
  {
    id: 'whoIsJesus',
    mapToProps: (res) => {
      const data = res.data.entries
      return {
        hero: {
          titleImage: data[0].heroTitleImage[0].url,
          backgroundImage: data[0].heroBackgroundImage[0].url,
          mobileBackgroundImage:
            data[0].heroMobileBackgroundImage.length > 0
              ? data[0].heroMobileBackgroundImage[0].url
              : data[0].heroBackgroundImage[0].url,
        },
        section1: {
          body: data[0].section1Body,
          callOut: data[0].section1CallOut,
          backgroundImage: data[0].section1BackgroundImage[0].url,
          callOutBackgroundColor:
          data[0].section1CallOutBackgroundColor[0].color[0].colorValue,
          dropCap: data[0].whoIsJesusDropCap,
        },
        section2: {
          body: data[0].section2Body,
          callOut: data[0].section2CallOut,
          backgroundImage: data[0].section2BackgroundImage[0].url,
        },
        section3: {
          body: data[0].section3Body,
          callOutBackgroundColor:
            data[0].section3CallOutBackgroundColor.length > 0
              ? data[0].section3CallOutBackgroundColor[0].color[0].colorValue
              : '#FFFFFF',
          callOut: data[0].section3CallOutText,
          callOutImage: data[0].section3CallOutImage[0].url,
          backgroundImage: data[0].section3BackgroundImage[0].url,
        },
        callToAction: {
          text: data[0].callToAction[0].callToActionText,
          buttonText: data[0].callToAction[0].buttonText,
          buttonEntry: data[0].callToAction[0].buttonEntry[0].uri,
        },
      }
    },
    query: `
    query getWhoIsJesus {
  entries (section:"whoIsJesus"){
    ... on whoIsJesus_whoIsJesus_Entry{
      heroTitleImage {
        url,
      },
      heroBackgroundImage {
        url,
      },
      heroMobileBackgroundImage { 
        url,
      },
      whoIsJesusDropCap,
      section1Body,
      section1CallOut,
      section1BackgroundImage {
        url,
      },
      section1CallOutBackgroundColor {
        url,
        ... on colors_colors_Entry{
          color{
            ... on color_colorBlockType_BlockType{
              colorValue,
            }
          }
        }
      },
      section2Body,
      section2CallOut,
      section2BackgroundImage {
        url,
      },
      section3CallOutBackgroundColor {
        url,
        ... on colors_colors_Entry{
          color{
            ... on color_colorBlockType_BlockType{
              colorValue,
            }
          }
        }
      },
      section3Body,
      section3CallOutText,
      section3CallOutImage {
        url,
      },
      section3BackgroundImage {
        url,
      },
      callToAction{
        ... on callToAction_callToActionBlock_BlockType{
          callToActionText,
          buttonText,
          buttonEntry{
            uri,
            slug,
          }
        }
      }
    }
  }
}
    `,
  },
  {
    id: 'pillars',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `
    query getPillars{
  entry(section:"pillars"){
    id
    ... on pillars_pillars_Entry{
      pillarsHeroImageDesktop{
        url
      }
      pillarsHeroImagesMobile{
        url
      }
      pillarsCompactHeroImageDesktop{
        url
      }
      pillarsQuote
      pillarsQuoteReference
      repentance{
        ... on repentance_repentance_BlockType{
          image{
            url
          }
          heading
          repentanceText
        }
      }
      pillarsCorporateWorship{
        ... on pillarsCorporateWorship_corporateWorshipPillar_BlockType{
          image{
            url
          }
          heading
          corporateWorshipText
        }
      }
      pillarsPersonalDevotions{
        ... on pillarsPersonalDevotions_personalDevotionsPillar_BlockType{
          image{
            url
          }
          heading
          personalDevotionPillarText
        }
      }
      pillarsSmallGroups{
        ... on pillarsSmallGroups_smallGroupsPillar_BlockType{
          image{
            url
          }
          heading
          smallGroupsPillarText
        }
      }
      pillarsService{
        ...on pillarsService_servicePillar_BlockType{
          image{
            url
          }
          heading
          servicePillarText
        }
      }
      pillarsEvangelism{
        ... on pillarsEvangelism_evangelismPillar_BlockType{
          image{
            url
          }
          heading
          evangelismPillarText
        }
      }
      pillarsDiscipleship{
        ... on pillarsDiscipleship_discipleshipPillar_BlockType{
          image{
            url
          }
          heading
          discipleshipPillarText
        }
      }
    }
  }
}
    `,
  },
  {
    id: 'connectForms',
    mapToProps: (res) => {
      return {
        data: res.data.globalSet,
      }
    },
    query: `
    query getConnectForms{
    globalSet(handle:"connectForms"){
    ... on connectForms_GlobalSet{
      connectFormText
      buttons{
        ... on buttons_button_BlockType{
          text
          formLink
        }
      }
    }
  }
}
    `,
  },
]
