import React, { Component } from 'react'
import { Article, withSizes } from '@oos-studio/website-common-components'
import { buildArticle } from './util'

class Section2 extends Component {
  render() {
    const { backgroundImage, callOut, body, getStyle } = this.props

    return (
      <div
        style={{
          ...getStyle(styles.container),
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <div style={getStyle(styles.callOut)}>{callOut}</div>
        <div style={getStyle(styles.divider)} />
        <div className={'articlePage'}>
          <Article
            styles={{
              container: {
                maxWidth: 800,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: 0,
                borderTopWidth: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            data={buildArticle(body, false)}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 100,
    paddingBottom: 100,
    md: {
      paddingLeft: 35,
      paddingRight: 35,
    },
    sm: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
  callOut: {
    width: '100%',
    maxWidth: 1000,
    fontSize: 45,
    textAlign: 'center',
    fontWeight: 300,
    fontFamily: 'Neutraface Slab Text',
    color: '#6A5B5D',
    sm: {
      fontSize: 25,
    },
  },
  divider: {
    width: 200,
    height: 3,
    backgroundColor: '#CE7D43',
    marginTop: 75,
    marginBottom: 75,
    sm: {
      marginTop: 50,
      marginBottom: 50,
    },
  },
  body: {
    width: '100%',
    maxWidth: 800,
  },
}

export default withSizes(Section2)
