import MegaMenu from '../common/MegaMenu'
import React from 'react'
import GoogleMapView from '../common/GoogleMapView'
import ImNewMobile from '../navbar/ImNewMobile'
import MinistriesMobile from '../navbar/MinistriesMobile'
import ResourcesMobile from '../navbar/ResourcesMobile'
import '../index.css'

const getMegaMenuColumns = (columns, getStyle, _history) => {
  return columns.map((col, index) => {
    let column
    const lastColumn = index === columns.length - 1
    switch (col.__typename) {
      case 'menus_links_Entry':
        column = {
          heading: '',
          type: 'links',
          links: col.links.map((link) => {
            return {
              title: link.displayName,
              url: link.entry ? link.entry[0]?.slug : link.linkUrl,
              useRouter: !!link.entry,
            }
          }),
        }
        break
      case 'menus_image_Entry':
        column = {
          heading: '',
          type: 'image',
          src: col.image[0]?.url,
          styles: {
            column: {
              paddingRight: lastColumn ? 0 : 30,
            },
            image: {
              display: 'block',
              overlay: {
                right: lastColumn ? 0 : 30,
                backgroundImage: `url(${col.image[0]?.url})`,
              },
            },
          },
          title: col.title,
          overlayText: col.imageText,
          url:
            col.imageEntry.length > 0 ? col.imageEntry[0]?.uri : col.imageUrl,
          useRouter:
            col.imageEntry.length > 0 ||
            (col.imageUrl && col.imageUrl[0] === '/'),
        }
        break
      case 'menus_information_Entry':
        column = {
          heading: '',
          type: 'text',
          render: () => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '0px 15px',
                }}>
                <div
                  style={getStyle({
                    fontSize: 18,
                    fontWeight: 500,
                    color: '#958E8A',
                    textTransform: 'uppercase',
                    letterSpacing: 1.8,
                    marginBottom: 10,
                  })}>
                  {col.heading}
                </div>
                {col.menuInformationItems.map((info, index) => {
                  return (
                    <div style={{ marginBottom: 15, width: '100%', }} key={index}>
                      <div
                        style={{
                          borderColor: '#A39A93',
                          borderStyle: 'solid',
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          paddingBottom: 5,
                          marginBottom: 5,
                          whiteSpace: 'nowrap',
                          color: '#6A5B5D',
                          fontSize: 18,
                        }}>
                        <span style={{ fontWeight: 'bold' }}>
                          {info.text}:{' '}
                        </span>
                        <span>{info.subText}</span>
                        {info.itemSubtitle && (
                          <div
                            style={{
                              fontSize: 18,
                              marginTop: -3,
                              fontStyle: 'italic',
                            }}>
                            {info.itemSubtitle}
                          </div>
                        )}
                      </div>
                      <div
                        className={'mmInfoButton'}
                        onClick={() => {
                          if (info.itemButtonEntry?.length > 0) {
                            _history.push(info.itemButtonEntry[0]?.uri)
                          } else {
                            window.open(info.itemButtonUrl, '__blank')
                          }
                        }}
                        style={{
                          fontSize: 18,
                          color: '#6A5B5D',
                          cursor: 'pointer',
                        }}>
                        {info.itemButtonText}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          },
        }
        break
      case 'menus_map_Entry':
        column = {
          heading: '',
          type: 'text',
          render: () => {
            return (
              <GoogleMapView
                position={'relative'}
                infoWindowText={col.infoWindowText}
                height={250}
                marker={{
                  lat: col.pinLocation.latitude,
                  lng: col.pinLocation.longitude,
                }}
              />
            )
          },
        }
        break
      default:
        column = {}
        break
    }

    return column
  })
}

export const mapMobileNavItems = (navItems) => {
  return navItems.map((item, index) => {
    if (item.menu.length > 0) {
      return {
        text: item.menu[0].title,
        url: '#',
        type: 'dropdown',
        menu: (d) => {
          return index === 0 ? (
            <ImNewMobile data={item.menu[0].children} display={d} />
          ) : index === 1 ? (
            <MinistriesMobile data={item.menu[0].children} display={d} />
          ) : (
            <ResourcesMobile data={item.menu[0].children} display={d} />
          )
        },
      }
    } else {
      return {
        text: item.displayName,
        url:
          item.entry?.length > 0 ? item.entry[0].slug : item.navigationBarUrl,
        type: 'link',
        useRouter: item.entry?.length > 0,
      }
    }
  })
}

export const mapNavItems = (navItems, _history, getStyle, minified = false) => {
  return navItems.map((item) => {
    if (item.menu.length > 0) {
      return {
        text: item.menu[0].title,
        url: '#',
        type: 'dropdown',
        aside: {
          header: item.menu[0].title,
          text: item.menu[0].menuAsideText.replace(/(<([^>]+)>)/gi, ''),
          brand: {
            url: '',
            image: {
              title: '',
              src: null,
            },
          },
        },
        render: (aside) => (
          <MegaMenu
            renderAside={minified}
            hasAside={true}
            aside={aside}
            history={_history}
            useRouter={true}
            styles={megaMenuStyles}
            columns={getMegaMenuColumns(
              item.menu[0].children,
              getStyle,
              _history,
            )}
          />
        ),
      }
    } else {
      return {
        text: item.displayName,
        url:
          item.entry?.length > 0 ? item.entry[0].slug : item.navigationBarUrl,
        type: 'link',
        useRouter: item.entry?.length > 0,
      }
    }
  })
}

const megaMenuStyles = {
  container: {
    padding: 0,
    margin: 0,
    marginTop: 30,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 275,
    width: '100%',
    float: 'right',
  },
  defaultColumn: {
    padding: 0,
    margin: 0,
    fontSize: 22,
    color: '#6A5B5D',
  },
  defaultLink: {
    color: '#6A5B5D',
    height: 50,
    textDecoration: 'none',
    fontSize: 25,
    padding: 0,
    whiteSpace: 'nowrap',
    hover: {
      color: '#CD7D43',
    },
  },
  defaultImage: {
    maxWidth: 350,
    width: '100%',
    height: 190,
    overlay: {
      maxWidth: 350,
      left: 0,
      right: 0,
      width: 'auto',
      height: 190,
      color: 'white',
      fontSize: 32,
      backgroundColor: 'rgba(106,91,93,0.5)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundBlendMode: 'multiply',
    },
  },
}
