// api.instagram.com/oauth/authorize?client_id=781322739028383&redirect_uri=https://www.providencechurch.us/&scope=user_profile,user_media&response_type=code
// AQCnGsZN0lshWunG2IxTyxTNnEWof2Jmnwqx_j-ayGjX7nea_GKLbaYAhX7BO1UnirVEPzDKIUzzJYDExtpo0CSRgkmfmiO5DRk58HYjeJS-7pxj_H8LyBOAr0hNF9JklqO1TzWYPJ7O9FUqETIxvvOneLa_MeMEpTo2HtBkOONSzcKbbw2VEeNKXWLNVE70bb7SOMJHGLDD3KYQI9STzwf7APafAbGW-3l78kwDhiPOpw#

// curl -X POST \
//   https://api.instagram.com/oauth/access_token \
//     -F client_id=781322739028383 \
//   -F client_secret=2262a04403e2c95d7da7ddf68b5c9d9a \
//   -F grant_type=authorization_code \
//   -F redirect_uri=https://www.providencechurch.us/ \
//   -F code=AQCnGsZN0lshWunG2IxTyxTNnEWof2Jmnwqx_j-ayGjX7nea_GKLbaYAhX7BO1UnirVEPzDKIUzzJYDExtpo0CSRgkmfmiO5DRk58HYjeJS-7pxj_H8LyBOAr0hNF9JklqO1TzWYPJ7O9FUqETIxvvOneLa_MeMEpTo2HtBkOONSzcKbbw2VEeNKXWLNVE70bb7SOMJHGLDD3KYQI9STzwf7APafAbGW-3l78kwDhiPOpw

// curl -i -X GET https://graph.instagram.com/access_token \
//   ?grant_type=ig_exchange_token \
//   &client_secret=2262a04403e2c95d7da7ddf68b5c9d9a \
//   &access_token=IGQVJVRENqVzl4ajgtWVJlX2tXUTF3ODdGTkJoUWx5MWtJeVFfMjV3ZAk56N2I1NElMVVJtMmlWZAGEzbHZAKM2lCYmNPRlN2UlJBVGtsempmZAnQwb1hNcHozcXNGbXFQSmhTaFk3YjhqZAHlkVDBXR1AxRk9nWmlnZAlNYcXlJ

export const getLatestPost = async (token) => {
  let postId = ''
  let username = ''

  const resUser = await fetch(
    `https://graph.instagram.com/me/media?fields=id,username,caption&access_token=${token}`,
  ).catch((err) => {
    console.log(err)
  })
  const userJson = await resUser?.json()

  postId = userJson.data ? userJson?.data[0].id : null
  username = userJson.data ? `@${userJson?.data[0].username}` : null

  const resMedia = await fetch(
    `https://graph.instagram.com/${postId}?fields=caption,media_url,media_type&access_token=${token}`,
  ).catch((err) => {
    console.log(err)
  })

  const retData = await resMedia?.json()

  return {
    caption: retData?.caption,
    image: retData?.media_url,
    username: username,
    type: retData?.media_type,
  }
}
