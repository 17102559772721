import React, { Component } from 'react'
import Banner from '../banner/Banner'
import { checkForLiveStream, getChannelInfo } from '../utils/BoxcastHandler'
import { connect } from 'react-redux'
import {
  setLiveText,
  setUpcomingVideoStartTime,
  setVideo,
} from '../sermons/actions'
import config from '../config'
import { withRouter } from 'react-router-dom'

class LiveBannerWrapper extends Component {
  constructor(props) {
    super(props)
    this.timer = 0
    this.startCountdown = this.startCountdown.bind(this)
    this.decrementTime = this.decrementTime.bind(this)
  }

  state = {
    stream: null,
    redirectId: null,
    isStreamSoon: false,
    isStreamNow: false,
    wasRecentStream: false,
    time: {},
    seconds: 0,
  }

  formatTime(secs) {
    let hours = Math.floor(secs / (60 * 60))
    let minutes = Math.floor((secs % (60 * 60)) / 60)
    let seconds = Math.ceil((secs % (60 * 60)) % 60)
    return {
      h: hours,
      m: minutes,
      s: seconds,
    }
  }

  startCountdown() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.decrementTime, 1000)
    }
  }

  decrementTime() {
    let seconds = this.state.seconds - 1
    this.setState({
      time: this.formatTime(seconds),
      seconds: seconds,
    })

    if (seconds === 0) {
      clearInterval(this.timer)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { wasRecentStream, redirectId, stream } = this.state
    const { history } = this.props

    if (prevState.wasRecentStream && !wasRecentStream) {
      history.push(`/sermons/player/${redirectId}`)
    }

    if (stream?.id && !prevState.stream) {
      if (stream) {
        let start = new Date(stream?.starts_at)
        let seconds =
          Math.round(start.getTime() / 1000) -
          Math.round(new Date().getTime() / 1000)
        if (seconds > 0) {
          this.setState({ seconds }, () => {
            let remaining = this.formatTime(seconds)
            this.setState({ time: remaining })
            this.startCountdown()
          })
        }
      }
    }
  }

  async componentDidMount() {
    const {
      checkForCurrentStream,
      checkForFutureStream,
      checkForPastStreamWithinLimits,
    } = this

    let stream = await checkForLiveStream()

    if (config.boxcast.isProduction && stream) {
      for (const channel of stream?.in_channel_ids) {
        const info = await getChannelInfo(channel)
        if (info.content_settings.development) {
          stream = null
          break
        }
      }
    }

    this.setState({
      stream:
        checkForFutureStream(stream) ||
        checkForCurrentStream(stream) ||
        checkForPastStreamWithinLimits(stream)
          ? stream
          : null,
      isStreamNow: checkForCurrentStream(stream),
      isStreamSoon: checkForFutureStream(stream),
      wasRecentStream: checkForPastStreamWithinLimits(stream),
    })

    const check = async () => {
      stream = await checkForLiveStream()

      if (config.boxcast.isProduction && stream) {
        for (const channel of stream?.in_channel_ids) {
          const info = await getChannelInfo(channel)
          if (info.content_settings.development) {
            // stream = null
            break
          }
        }
      }

      this.setState({
        stream:
          checkForFutureStream(stream) ||
          checkForCurrentStream(stream) ||
          checkForPastStreamWithinLimits(stream)
            ? stream
            : null,
        isStreamNow: checkForCurrentStream(stream),
        isStreamSoon: checkForFutureStream(stream),
        wasRecentStream: checkForPastStreamWithinLimits(stream),
      })

      if (stream?.id && !this.state.redirectId) {
        this.setState({
          redirectId: stream.id,
        })
      }
    }

    check()
    setInterval(check, 10000)
  }

  checkForCurrentStream = (stream) => {
    return stream?.timeframe === 'current'
  }

  checkForPastStreamWithinLimits = (stream) => {
    const streamDate = new Date(stream?.starts_at)
    const now = new Date()

    return now > streamDate && now - streamDate <= 43200000
  }

  checkForFutureStream = (stream) => {
    const streamDate = new Date(stream?.starts_at)
    const now = new Date()

    const streamSoon = streamDate - now <= 5400000 //is stream within 90 mins from now?
    return (
      streamSoon &&
      (stream?.timeframe === 'future' || stream?.timeframe === 'preroll')
    )
  }

  render() {
    const {
      stream,
      isStreamSoon,
      isStreamNow,
      wasRecentStream,
      time,
    } = this.state
    const { setVideo, setLiveText, setUpcomingVideoStartTime } = this.props

    const streamDate = new Date(stream?.starts_at)
    const startTime = streamDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })

    let countdown = null

    if (time) {
      const hours = `${time.h < 10 ? `0${time.h}` : time.h}`
      const minutes = `${time.m < 10 ? `0${time.m}` : time.m}`
      const seconds = `${time.s < 10 ? `0${time.s}` : time.s}`
      if (
        hours === 'undefined' ||
        minutes === 'undefined' ||
        seconds === 'undefined'
      ) {
        countdown = null
      } else {
        countdown = `(${hours}:${minutes}:${seconds})`
      }
    }

    const liveText = 'Watch the service broadcast live now'
    const soonText = `Livestream starting at ${startTime}`

    if (isStreamSoon) {
      setLiveText(soonText)
      setUpcomingVideoStartTime(stream?.starts_at)
    } else if (!isStreamNow) {
      setLiveText('There are currently no scheduled livestreams.')
    }

    if (!isStreamSoon || wasRecentStream) {
      setVideo(stream)
    }

    return isStreamNow || isStreamSoon ? (
      <Banner
        text={isStreamSoon ? soonText : liveText}
        countdown={isStreamNow ? null : countdown}
        live={isStreamNow}
        liveSoon={isStreamSoon}
        link={'/live'}
        useRouter={true}
      />
    ) : null
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setVideo: (video) => dispatch(setVideo(video)),
    setLiveText: (text) => dispatch(setLiveText(text)),
    setUpcomingVideoStartTime: (start) =>
      dispatch(setUpcomingVideoStartTime(start)),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(LiveBannerWrapper))
