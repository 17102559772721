import React, { useState, useEffect } from 'react'
import CardGrid from './CardGrid'
import { styles as globalStyles } from '../styles'
import { getLatestPost } from '../utils/InstagramHandler'
import truncateText from '../utils/truncateText'
import RectButton from './RectButton'

const InstagramCard = ({ data, username }) => {
  const [card, setCard] = useState(null)
  const [token, setToken] = useState(null)
  const [profileUrl, setProfileUrl] = useState(null)

  const getInstagramPost = async () => {
    let post = null
    // try {
      post = await getLatestPost(token)
    // } catch (e) {
    //   console.log('ERROR GETTING INSTAGRAM POST: ', e)
    // }

    // if (!post) return

    const caption = truncateText(post?.caption, 230)

    const _card = {
      header: 'INSTAGRAM',
      image: {
        title: 'igPic',
        src: post?.image,
        tag: post?.type === 'VIDEO' ? 'video' : 'img',
        id: post?.type === 'VIDEO' ? 'instagram-video' : undefined,
        loop: true,
        controls: true,
      },
      isFlipped: false,
      title: post?.username,
      subtitles: [],
      text: `<p style="margin-bottom: 0px;">${caption}</p>`,
      button: {
        text: 'VIEW MORE',
        url: profileUrl || 'https://www.instagram.com/providencechurch/',
        render: () => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <RectButton
                url={
                  profileUrl || 'https://www.instagram.com/providencechurch/'
                }
                external
                newTab
                text={'View More'}
              />
            </div>
          )
        },
      },
    }

    if (post?.image) {
      setCard(_card)
    }
  }

  useEffect(() => {
    const account = data.find((d) => d.instagramUsername === username)
    setToken(account.accessToken)
    setProfileUrl(account.profileUrl)
  }, [])

  useEffect(() => {
    if(token) {
      getInstagramPost()

      const instagramVideo = document.getElementById('instagram-video')

      if (instagramVideo) {
        instagramVideo.play()
      }
    }
  }, [token])

  return !card ? null : (
    <CardGrid
      // styles={{
      //   button: globalStyles.button,
      //   ...styles,
      //   row: styles.row,
      //   wrapper: styles.wrapper,
      // }}
      instagramCardData={card}
      isInstagramCard
      hideGap
    />
  )
}

const styles = {
  container: {
    backgroundColor: '#F8F5EE',
    paddingBottom: 50,
  },
  card: {
    backgroundColor: 'white',
    color: '#6A5B5D',
    paddingBottom: 30,
  },
  image: {
    height: 250,
    objectFit: 'cover',
  },
  separator: {
    borderColor: '#6A5B5D',
  },
  header: {
    color: '#D07D3C',
    letterSpacing: 3.4,
  },
  wrapper: {
    // maxWidth: 1350,
  },
  arrow: {
    hovered: {},
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: 17,
  },
  row: {
    marginLeft: 55,
    marginRight: 55,
    xs: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
}

export default InstagramCard
