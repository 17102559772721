import React from 'react'
import Slider from 'react-slick'
import { withSizes } from '@oos-studio/website-common-components'
import ImageSliderSlide from './ImageSliderSlide'
import './large-slider.css'

const ImageSliderSection = ({ data, getStyle, xs, sm, md, lg, isHomePage }) => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    centerMode: false,
  }

  const styles = _styles(isHomePage)

  return (
    <div id={'large-slider-container'} style={getStyle(styles.container)}>
      <div className={'large-slider'} style={getStyle(styles.sliderWrapper)}>
        <Slider {...sliderSettings}>
          {data?.map((slide, index) => {
            return <ImageSliderSlide key={index} slide={{ ...slide }} />
          })}
        </Slider>
      </div>
    </div>
  )
}

const _styles = (isHomePage) => {
  return {
    container: {
      backgroundColor: isHomePage ? '#CFC6BE' : 'transparent',
      width: '100%',
      // height: 500,
      display: 'flex',
      justifyContent: 'center',
      paddingTop: isHomePage ? 0 : 75,
      paddingLeft: 70,
      paddingRight: 70,
      paddingBottom: 75,
      md: {
        paddingTop: isHomePage ? 0 : 50,
        paddingLeft: 35,
        paddingRight: 35,
        paddingBottom: 50,
      },
    },
    sliderWrapper: {
      maxWidth: 1200,
      width: '100%',
      height: 440,
      marginTop: isHomePage ? -50 : 0,
      md: {
        height: 350,
      },
      sm: {
        height: 300,
        marginTop: -25,
      },
      xs: {
        height: 225,
      },
    },
  }
}

export default withSizes(ImageSliderSection)
