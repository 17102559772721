import React, { Component } from 'react'
import { Article, withSizes } from '@oos-studio/website-common-components'
import { buildArticle } from './util'
import CallToAction from './CallToAction'

class Section3 extends Component {
  render() {
    const {
      backgroundImage,
      callOut,
      callOutBackgroundColor,
      body,
      getStyle,
      callOutImage,
      text,
      buttonText,
      buttonEntry,
    } = this.props
    return (
      <div
        style={{
          ...getStyle(styles.container),
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <div
          style={{
            ...getStyle(styles.callOutContainer),
            backgroundColor: callOutBackgroundColor,
          }}>
          <img
            src={getStyle(callOutImage)}
            style={getStyle(styles.callOutImage)}
            alt={'callOutImg'}
          />
          <div style={getStyle(styles.callOutText)}>{callOut}</div>
        </div>
        <div className={'articlePage'}>
          <Article
            styles={{
              container: {
                maxWidth: 800,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: 0,
                borderTopWidth: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            data={buildArticle(body, false)}
          />
        </div>
        <div style={getStyle(styles.divider)} />
        <CallToAction
          text={text}
          buttonEntry={buttonEntry}
          buttonText={buttonText}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100vw',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 70,
    paddingRight: 70,
    paddingBottom: 100,
    md: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  callOutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    marginTop: -50,
    marginBottom: 75,
    paddingTop: 30,
    paddingBottom: 75,
    paddingLeft: 75,
    paddingRight: 75,
    fontSize: 45,
    textAlign: 'center',
    fontWeight: 300,
    fontFamily: 'Neutraface Slab Text',
    color: '#6A5B5D',
    boxShadow: '0px 75px 75px #D0E1E7BE',
    sm: {
      paddingBottom: 30,
      paddingLeft: 30,
      paddingRight: 30,
      fontSize: 25,
      marginBottom: 50,
      marginTop: -25,
    },
  },
  callOutImage: {
    width: 400,
    marginBottom: 50,
    sm: {
      width: '100%',
    },
  },
  callOutText: {},
  divider: {
    width: 200,
    height: 3,
    backgroundColor: '#CE7D43',
    marginTop: 75,
    marginBottom: 75,
    sm: {
      marginTop: 50,
      marginBottom: 50,
    },
  },
  body: {},
}

export default withSizes(Section3)
