import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'

const MAPS_API_KEY = 'AIzaSyCg9z7kEJUIMPOFsEVPTJ5lkzNqR-anEOg'

class GoogleMapView extends Component {
  constructor(props) {
    super(props)

    const { maxWidth, width } = props

    this.state = {
      width:
        width && width !== 'auto'
          ? width
          : window.innerWidth * 0.25 > maxWidth
          ? maxWidth
          : window.innerWidth * 0.25,
      showInfoWindow: false,
      activeMarker: {},
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const { maxWidth, width } = this.props
    if (!width || width === 'auto') {
      this.setState({
        width:
          window.innerWidth * 0.25 > maxWidth
            ? maxWidth
            : window.innerWidth * 0.25,
      })
    }
  }

  onMarkerClicked = (props, marker) => {
    this.setState({
      showInfoWindow: true,
      activeMarker: marker,
    })
  }

  render() {
    const { onMarkerClicked } = this
    const { width, showInfoWindow, activeMarker } = this.state
    const {
      height,
      marker,
      zoom,
      position,
      google,
      infoWindowText,
    } = this.props
    return (
      <Map
        google={google}
        zoom={zoom ? zoom : 11}
        style={{
          ...styles.map,
          width: width,
          height: height,
          position: position ? position : 'unset',
        }}
        containerStyle={{
          ...styles.container,
          width: width,
          height: height,
        }}
        initialCenter={{ lat: marker.lat, lng: marker.lng }}>
        <Marker
          style={{ zIndex: -10 }}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={onMarkerClicked}
        />
        <InfoWindow
          google={google}
          visible={showInfoWindow}
          marker={activeMarker}>
          <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
            {infoWindowText}
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

GoogleMapView.defaultProps = {
  maxWidth: 290,
  height: 304,
}

const styles = {
  container: {
    overflow: 'hidden',
    position: 'unset',
  },
  map: {},
}

export default GoogleApiWrapper({ apiKey: MAPS_API_KEY })(GoogleMapView)
