import React, { useEffect, useRef } from 'react'
import DateFilterDate from './DateFilterDate'
import { useDispatch, useSelector } from 'react-redux'
import {
  getLatestSermons,
  setSelectedMonth,
  setSelectedDay,
  setDayFilterValue,
  getBroadcastDates,
  setShowDateDropdown, setSelectedYear,
} from './actions'
import { withSizes } from '@oos-studio/website-common-components'
import { useHover } from '../hooks/useHover'
import { useHistory } from 'react-router-dom'
import DownChevronDark from '../assets/DownChevronDark.png'
import DownChevronLight from '../assets/DownChevronLight.png'
import XIconDark from '../assets/XIconDark.png'
import XIconLight from '../assets/XIconLight.png'

const DateFilter = (props) => {
  let ref = useRef(null)
  const [arrowHoverRef, isArrowHovered] = useHover()

  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)

  const history = useHistory()

  useEffect(() => {
    dispatch(getBroadcastDates())
    window.addEventListener('click', handleClick)
    const unsubscribe = history.listen(listen)
    return () => {
      window.removeEventListener('click', handleClick)
      unsubscribe()
    }
  }, [])

  const listen = () => {
    dispatch(setShowDateDropdown(false))
    dispatch(setDayFilterValue(null))
  }

  const handleClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      return
    }

    if (!state.showDateDropdown) {
      dispatch(setShowDateDropdown(false))
    }
  }

  const onClick = () => {
    dispatch(setShowDateDropdown(!state.showDateDropdown))
  }

  const onClearFilter = (e) => {
    e.stopPropagation()
    dispatch(setDayFilterValue(null))
    dispatch(setSelectedMonth(null))
    dispatch(setSelectedYear(null))
    dispatch(setSelectedDay(null))
    dispatch(setShowDateDropdown(false))

    dispatch(getLatestSermons(10))
  }

  return !state.broadcastDates ? null : (
    <div ref={ref} style={props.getStyle(styles.container)}>
      <div
        onClick={onClick}
        style={{
          ...styles.heading,
          color: state.showDateDropdown ? '#F8F5EE' : '#6A5B5D',
          backgroundColor: state.showDateDropdown ? '#6A5B5D' : 'transparent',
          borderColor: state.showDateDropdown ? '#6A5B5D' : '#6A5B5D',
        }}>
        <div style={styles.headingTitle}>{state.dayFilterValue || 'Date'}</div>
        {state.dayFilterValue ? (
          <div
            ref={arrowHoverRef}
            onClick={onClearFilter}
            style={{
              ...styles.clearFilter,
              backgroundColor: isArrowHovered ? '#a39a9326' : 'transparent',
            }}>
            <img
              src={state.showDateDropdown ? XIconLight : XIconDark}
              style={styles.xImage}
            />
          </div>
        ) : (
          <div style={styles.arrowWrapper}>
            <img
              src={state.showDateDropdown ? DownChevronLight : DownChevronDark}
              style={{
                ...styles.arrowImage,
                transform: state.showDateDropdown
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              }}
            />
          </div>
        )}
      </div>
      {state.showDateDropdown && (
        <div style={styles.listWrapper}>
          {Object.keys(state.broadcastDates).map((year, index) => {
            return (
              <DateFilterDate
                year={year}
                months={state.broadcastDates[year].months}
                key={index}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 400,
    position: 'relative',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
    zIndex: 10,
    flex: 1,
    marginRight: 15,
    xs: {
      maxWidth: 'none',
      width: '100%',
      marginRight: 0,
      marginTop: 15,
      marginBottom: 0,
    },
  },
  heading: {
    color: '#6A5B5D',
    fontSize: 18,
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'inline-flex',
  },
  headingTitle: {
    padding: 10,
    paddingLeft: 15,
    flex: 1,
  },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 400,
    borderStyle: 'solid',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    borderTopWidth: 0,
    overflowY: 'scroll',
    backgroundColor: '#FFFFFF',
  },
  arrowWrapper: {
    padding: 10,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  xImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  clearFilter: {
    padding: 10,
    paddingLeft: 15,
  },
}

export default withSizes(DateFilter)
