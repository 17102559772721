import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDayFilterValue,
  setSelectedYear,
  setSelectedMonth,
  getLatestSermons,
  setShowDateDropdown, setSelectedSpeaker, setPassageFilterValue, setSelectedDay,
} from './actions'
import { useHover } from '../hooks/useHover'

const DateFilterDay = (props) => {
  const { year, months } = props
  const [hoverRef, isHovered] = useHover()

  const [hoveredMonth, setHoveredMonth] = useState(null)
  const [hoveredDay, setHoveredDay] = useState(null)

  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)
  const showMonths = state.selectedYear === year

  const onClick = () => {
    if (state.selectedYear === year) {
      dispatch(setSelectedYear(null))
    } else {
      dispatch(setSelectedYear(year))
    }
  }

  const onClickMonth = (month) => {
    if (state.selectedMonth === month) {
      dispatch(setSelectedMonth(null))
    } else {
      dispatch(setSelectedMonth(month))
    }
  }

  const onClickDay = (day) => {
    dispatch(setSelectedDay(day))
    dispatch(setShowDateDropdown(false))
    dispatch(
      setDayFilterValue(`${state.selectedMonth} ${day} ${state.selectedYear}`),
    )
    dispatch(setPassageFilterValue(null))
    dispatch(setSelectedSpeaker(null))

    const startsAt = new Date(
      `${state.selectedMonth} ${day} ${state.selectedYear}`,
    )
    const endsAt = new Date(
      `${state.selectedMonth} ${day} ${state.selectedYear}`,
    )
    endsAt.setDate(endsAt.getDate() + 1)
    dispatch(
      getLatestSermons(
        10,
        true,
        null,
        null,
        null,
        startsAt.toISOString(),
        endsAt.toISOString(),
      ),
    )
  }

  return (
    <div ref={hoverRef} style={styles.container}>
      <div
        onClick={onClick}
        style={{
          ...styles.sectionWrapper,
          backgroundColor: showMonths
            ? '#FDECBB'
            : isHovered
            ? '#a39a9326'
            : 'transparent',
        }}>
        {year}
      </div>
      {showMonths && (
        <div style={styles.monthsWrapper}>
          {months.map((month, index) => {
            return (
              <div
                onMouseEnter={() => setHoveredMonth(index)}
                onMouseLeave={() => setHoveredMonth(null)}
                style={{
                  ...styles.sectionWrapper,
                  backgroundColor:
                    hoveredMonth === index &&
                    state.selectedMonth !== month.month
                      ? '#a39a9326'
                      : 'transparent',
                }}
                key={index}
                onClick={() => onClickMonth(month.month)}>
                <div style={styles.month}>{month.month}</div>
                {state.selectedMonth === month.month && (
                  <div style={styles.daysWrapper}>
                    {month.days.map((d, dIndex) => {
                      return (
                        <div
                          onClick={() => onClickDay(d)}
                          onMouseEnter={() => setHoveredDay(dIndex)}
                          onMouseLeave={() => setHoveredDay(null)}
                          style={{
                            ...styles.day,
                            backgroundColor:
                              hoveredDay === dIndex
                                ? '#a39a9326'
                                : 'transparent',
                          }}
                          key={dIndex}>
                          {d}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
  },
  sectionWrapper: {
    fontSize: 18,
    color: '#6A5B5D',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  monthsWrapper: {},
  month: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  daysWrapper: {
    backgroundColor: 'white',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    justifyItems: 'center',
    gridGap: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: 'default',
  },
  day: {
    color: '#6A5B5D',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
  },
}

export default DateFilterDay
