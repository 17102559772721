const initialState = {
  isSendingEmail: false,
  isEmailSuccessful: false,
  emailStatusMessage: '',
  emailMessage: '',
  emailName: '',
  emailAddress: '',
}

const footer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SENDING':
      return Object.assign({}, state, { isSendingEmail: action.payload })
    case 'SET_SUCCESS':
      return Object.assign({}, state, { isEmailSuccessful: action.payload })
    case 'SET_STATUS_MESSAGE':
      return Object.assign({}, state, { emailStatusMessage: action.payload })
    case 'SET_EMAIL_MESSAGE':
      return Object.assign({}, state, { emailMessage: action.payload })
    case 'SET_NAME':
      return Object.assign({}, state, { emailName: action.payload })
    case 'SET_ADDRESS':
      return Object.assign({}, state, { emailAddress: action.payload })
    default:
      return state
  }
}

export default footer
