import React, { Component } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSizes } from '@oos-studio/website-common-components'
import Footer from '../../footer/Footer'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _description,
  _image,
  _keywords,
  parseKeywords,
  getImageProperties,
} from '../../utils/defaultMeta'
import EventFeed from '../../common/EventFeed'
import config from '../../config'
import ImageSliderSection from '../../home/ImageSliderSection'
import Dropdown from '../../common/Dropdown'

class Calendar extends Component {
  state = {
    mobile: false,
    imageProperties: null,
    slider: null,
    integrationEvents: null,
    integrationFeedBackgroundColor: 'white',
    iframeView: 'month',
  }

  async componentDidMount() {
    const { handleResize } = this
    const { data } = this.props.gql

    const imageProperties =
      data.seoImage.length > 0
        ? await getImageProperties(data.seoImage[0]?.url)
        : null

    this.setState({
      imageProperties: imageProperties,
    })

    if (window.innerWidth < 1040) {
      this.setState({
        mobile: true,
      })
    }

    const integrationItem = data?.pageBodyNew.find(
      (item) => item.__typename === 'pageBodyNew_apiEventFeed_BlockType',
    )
    if (integrationItem) {
      if (integrationItem.eventFeedBackgroundColor?.length) {
        this.setState({
          integrationFeedBackgroundColor:
            integrationItem.eventFeedBackgroundColor[0]?.color[0]?.colorValue,
        })
      }
      let url = ''
      switch (integrationItem.integration) {
        case 'pushPay':
          url = `${config.providenceApi.baseUrl}/pushpay/events`
          break
        case 'planningCenter':
          url = `${config.providenceApi.baseUrl}/pco/events`
          break
        default:
          break
      }

      const response = await fetch(url)
      const resJson = await response.json()
      if (resJson?.length > 0) {
        this.setState({
          integrationEvents: resJson,
        })
      }
    }

    const slider = data?.pageBodyNew.filter(
      (item) => item.__typename === 'pageBodyNew_slide_BlockType',
    )

    if (slider?.length > 0) {
      const sliderElement = <ImageSliderSection data={slider} />
      this.setState({
        slider: sliderElement,
      })
    }

    window.addEventListener('resize', handleResize)
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1040 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1040 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  onSelectViewOptions = (index) => {
    this.setState({
      iframeView: index === 0 ? 'month' : index === 1 ? 'list' : 'gallery',
    })
  }

  render() {
    const { data } = this.props.gql
    const { getStyle } = this.props
    const {
      mobile,
      imageProperties,
      integrationFeedBackgroundColor,
      integrationEvents,
    } = this.state
    const { onSelectViewOptions } = this

    const iframeUrl = `${data.calendarUrl}&view=${this.state.iframeView}`

    return (
      <DocumentMeta
        {...getMeta(
          'Calendar',
          data.seoDescription || _description,
          data.seoImage.length > 0 ? data.seoImage[0].url : _image,
          data.seoKeywords.length > 0
            ? parseKeywords(data.seoKeywords)
            : _keywords,
          imageProperties,
        )}>
        <React.Fragment>
          <div style={getStyle(styles.backgroundBlock)} />
          <div
            style={getStyle({
              ...styles.container,
              paddingTop: mobile ? 130 : 195,
            })}>
            <div
              style={getStyle({
                ...styles.title,
              })}>
              Calendar
            </div>
            <div style={getStyle(styles.viewSelectorWrapper)}>
              {/*<span>View: </span>*/}
              <Dropdown
                options={['Month View', 'List View', 'Gallery View']}
                defaultOptionIndex={0}
                onSelectOption={onSelectViewOptions}
              />
            </div>
            <div style={getStyle(styles.calendarWrapper)}>
              <iframe style={styles.calendar} src={iframeUrl} />
            </div>
            {integrationEvents && (
              <div style={styles.feedWrapper}>
                <EventFeed
                  showDateIcon
                  events={integrationEvents}
                  backgroundColor={integrationFeedBackgroundColor}
                  title={data.eventFeedTitle}
                />
              </div>
            )}
            {this.state.slider && this.state.slider}
          </div>
          <Footer />
        </React.Fragment>
      </DocumentMeta>
    )
  }
}

const styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 500,
    position: 'absolute',
    zIndex: -1,
    sm: {
      height: 375,
    },
  },
  title: {
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    color: '#695B5D',
    fontSize: 45,
    textAlign: 'left',
    marginBottom: 40,
    alignSelf: 'flex-start',
    xs: {
      fontSize: 35,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1340,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    paddingTop: 195,
    paddingLeft: 70,
    paddingRight: 70,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  calendarWrapper: {
    position: 'relative',
    // height: 770,
    width: '100%',
    paddingBottom: '64%',
    overflow: 'hidden',
    paddingLeft: 70,
    paddingRight: 70,
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  calendar: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderWidth: 0,
    overflow: 'hidden',
  },
  feedWrapper: {},
  feedEntry: {},
  viewSelectorWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewSelector: {},
}

export default gqlConnect('calendar')(withSizes(Calendar))
