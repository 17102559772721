import React from 'react'
import { mergeStyles, ImageSlider } from '@oos-studio/website-common-components'
import { Textfit } from 'react-textfit'
import './intro-slider.css'
import HighlightButton from '../common/HighlightButton'
import '../index.css'
import { formatStyledText } from '../utils/formatText'

const FullScreenIntroDesktop = (props) => {
  const {
    text,
    image,
    slideshow,
    styles,
    sliderSettings,
    history,
    leftContentTop,
  } = props

  return (
    <React.Fragment>
      <div style={styles.container}>
        <div style={styles.backgroundColors}>
          <div style={styles.left}>
            <div style={styles.leftWrapper}>
              <div style={{ ...styles.leftContent, top: leftContentTop }}>
                <div style={styles.content}>
                  <div>
                    <div style={styles.textWrapper} className={'intro-desktop'}>
                      <div style={styles.text}>
                        {formatStyledText(text).map((t) => {
                          return t.italic ? (
                            <span style={{ fontStyle: 'italic' }}>
                              {t.text}
                            </span>
                          ) : (
                            <span>{t.text}</span>
                          )
                        })}
                      </div>
                    </div>
                    <HighlightButton
                      text={'Learn More'}
                      url={'/values'}
                      containerHeight={50}
                      containerWidth={200}
                      styles={{
                        buttonWrapper: {
                          marginTop: 15,
                          marginLeft: 5,
                        },
                      }}
                    />
                  </div>
                  <div id={'introSlider'} style={styles.sliderWrapper}>
                    <div style={styles.gradient} />
                    <div style={styles.darkGradient} />
                    <ImageSlider
                      settings={sliderSettings}
                      data={slideshow}
                      history={history}
                      styles={styles.imageSlider}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.right}>
            <div style={styles.rightGradient} />
            <div style={styles.overlay}>
              <img alt={'overlay'} src={image} style={styles.overlay.img} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const defaultStyles = {
  leftContent: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 925,
  },
  leftWrapper: {
    position: 'relative',
    height: '100%',
    marginLeft: 75,
  },
  container: {
    position: 'relative',
    height: 'calc(100vh)',
    minHeight: 650,
    maxHeight: 1080,
  },
  backgroundColors: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    backgroundColor: '#F8F5EE',
    flex: 1,
  },
  right: {
    backgroundColor: '#FDEBBE',
    flex: 1,
    marginRight: -670,
    position: 'relative',
  },
  rightGradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 10,
    height: 210,
    background:
      'transparent linear-gradient(180deg, rgba(253,235,190,0.9) 25%, rgba(253,235,190,0) 100%) 0% 0% no-repeat padding-box',
  },
  content: {
    width: '100%',
    height: '100%',
    maxWidth: 800,
    paddingRight: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textWrapper: {
    minHeight: 'none',
  },
  text: {
    fontWeight: 400,
    fontFamily: 'Neutraface Slab Text',
    color: '#A39A93',
    minHeight: 'none',
    lineHeight: 1.1,
    height: '100%',
  },
  slideshow: {
    backgroundColor: 'blue',
    width: 800,
    height: 400,
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    left: -585,
    right: 650,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 9,
    marginLeft: 50,
    pointerEvents: 'none',
    img: {
      width: '100%',
      minWidth: 1125,
      height: 'auto',
    },
  },
  sliderWrapper: {
    position: 'relative',
    height: '55%',
  },
  imageSlider: {
    container: {
      backgroundColor: 'rgba(0,0,0,0)',
      padding: 0,
      paddingBottom: 50,
      height: '100%',
    },
    item: {
      //position: 'absolute',
      height: '100%',
    },
    navLink: {
      position: 'absolute',
      height: '100%',
      lg: {},
    },
  },
  gradient: {
    position: 'absolute',
    bottom: 50,
    top: 0,
    width: '100%',
    right: -1,
    zIndex: 8,
    pointerEvents: 'none',

    background:
      'transparent linear-gradient(242deg, #F8F5EED9 0%, rgba(255,255,255,0) 80%) 0% 0% no-repeat padding-box',
  },
  darkGradient: {
    position: 'absolute',
    bottom: 50,
    top: 0,
    left: 0,
    width: '70%',
    zIndex: 5,
    pointerEvents: 'none',
    background:
      'transparent linear-gradient(33deg, rgba(0,0,0,1) 0%, #00000000 50%)',
  },
}

export default mergeStyles(defaultStyles)(FullScreenIntroDesktop)
