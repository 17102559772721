import React, { Component } from 'react'
import FullScreenIntroDesktop from './FullScreenIntroDesktop'
import FullScreenIntroMobile from './FullScreenIntroMobile'
import { withRouter } from 'react-router-dom'

class FullScreenIntro extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fsIntro: FullScreenIntroDesktop,
      leftContentTop: props.fullNavLeftContentTop,
    }
  }

  handleResize = () => {
    const { fsIntro, leftContentTop } = this.state
    const {
      trigger,
      navTrigger,
      fullNavLeftContentTop,
      miniNavLeftContentTop,
    } = this.props

    if (window.innerWidth < trigger && fsIntro !== FullScreenIntroMobile) {
      this.setState({
        fsIntro: FullScreenIntroMobile,
      })
    }
    if (window.innerWidth >= trigger && fsIntro !== FullScreenIntroDesktop) {
      this.setState({
        fsIntro: FullScreenIntroDesktop,
      })
    }
    if (
      window.innerWidth < navTrigger &&
      leftContentTop !== miniNavLeftContentTop
    ) {
      this.setState({
        leftContentTop: miniNavLeftContentTop,
      })
    } else if (
      window.innerWidth >= navTrigger &&
      leftContentTop !== fullNavLeftContentTop
    ) {
      this.setState({
        leftContentTop: fullNavLeftContentTop,
      })
    }
  }

  componentDidMount() {
    const { handleResize } = this
    const { trigger } = this.props

    if (window.innerWidth < trigger) {
      this.setState({
        fsIntro: FullScreenIntroMobile,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
  }

  render() {
    const { fsIntro, leftContentTop } = this.state
    const { text, image, slides, styles, history } = this.props

    const FSIntroType = fsIntro

    const slider = slides.map((slide) => {
      return {
        imageSliderItemImage: [
          {
            url: slide.image[0].url,
          },
        ],
        imageSliderItemUrl:
          slide.entry.length > 0
            ? slide.entry[0]?.slug
            : slide.slideUrl || null,
        isEntry: slide.entry.length > 0,
      }
    })

    return (
      <FSIntroType
        text={text}
        image={image}
        slideshow={slider}
        styles={styles}
        history={history}
        sliderSettings={sliderSettings}
        leftContentTop={leftContentTop}
      />
    )
  }
}

FullScreenIntro.defaultProps = {
  trigger: 750,
  navTrigger: 1040,
  fullNavLeftContentTop: 180,
  miniNavLeftContentTop: 120,
}

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: false,
}

export default withRouter(FullScreenIntro)
