import React, { Component } from 'react'
import Radium from 'radium'
import { withRouter } from 'react-router-dom'

class NotFound extends Component {
  render() {
    const { history } = this.props

    return (
      <React.Fragment>
        <div style={styles.backgroundBlock} />
        <div style={styles.container}>
          <div style={styles.header}>Page Not Found</div>
          <div style={styles.text}>
            Click{' '}
            <span onClick={() => history.push('/')} style={styles.link}>
              here
            </span>{' '}
            to return to the homepage
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    height: 500,
    width: '100%',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    '@media (max-width: 645px)': {
      height: 350,
    },
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 200,
    maxWidth: 1200,
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
  },
  header: {
    fontSize: 45,
    color: '#6A5B5D',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
  },
  text: {
    fontFamily: 'Neutraface 2 Text',
    fontSize: 22,
    color: '#6A5B5D',
  },
  link: {
    color: '#65A3BE',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}

export default withRouter(Radium(NotFound))
