import React, { Component } from 'react'
import { mergeStyles } from '@oos-studio/website-common-components'
import deepmerge from 'deepmerge'
import '../index.css'
import Button from './Button'
import withTouchscreenSupport from '../utils/TouchscreenSupport'

const BUTTON_IMG = require('../assets/PlayButton.png')
const BUTTON_IMG_HOVER = require('../assets/PlayButton_hover.png')

class PlayButton extends Component {
  state = {
    localHovered: false,
    manageHover: true,
  }

  componentDidMount() {
    if (this.props.hovered !== undefined && this.props.hovered !== null) {
      this.setState({
        manageHover: false,
      })
    }
  }

  onHover = () => {
    const { manageHover } = this.state
    if (manageHover) {
      this.setState({
        localHovered: true,
      })
    }
  }

  onLeaveHover = () => {
    const { manageHover } = this.state
    if (manageHover) {
      this.setState({
        localHovered: false,
      })
    }
  }

  render() {
    const { styles, item, hovered, touchscreen } = this.props
    const { onLeaveHover, onHover } = this
    const { localHovered } = this.state

    return (
      <Button playVideo resetStyles url={item?.url}>
        <div
          onMouseEnter={touchscreen ? null : () => onHover()}
          onMouseLeave={touchscreen ? null : () => onLeaveHover()}
          id={'playButton'}
          style={styles.container}>
          <div
            style={
              hovered || localHovered
                ? deepmerge(styles.button, styles.button.hovered)
                : styles.button
            }>
            <img src={BUTTON_IMG_HOVER} alt={'play'} style={styles.img} />
            <img
              src={BUTTON_IMG}
              alt={'play'}
              style={{
                ...styles.img,
                position: 'absolute',
                opacity: hovered || localHovered ? 0 : 1,
                transition: 'opacity 0.35s',
              }}
            />
          </div>
        </div>
      </Button>
    )
  }
}

const defaultStyles = {
  container: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderStyle: 'solid',
    paddingLeft: 3,
    height: 45,
    width: 45,
    backgroundColor: 'transparent',
    color: '#6A5B5D',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    transition: 'background-color 0.35s',
    hovered: {
      borderRadius: '50%',
      backgroundColor: '#6A5B5D',
      color: '#FAA860',
      borderColor: '#6A5B5D',
    },
  },
  img: {
    height: 17,
    width: 17,
  },
}

export default withTouchscreenSupport(mergeStyles(defaultStyles)(PlayButton))
