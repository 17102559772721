import React, { Component } from 'react'
import { FloatingParallax as FloatingParallaxComponent } from '@oos-studio/website-common-components'
import { withRouter } from 'react-router-dom'

const styles = {
  container: {
    backgroundColor: '#6A5B5D',
  },
  floatingContainer: {
    transition: 'transform 0.5s',
    overflow: 'hidden',
  },
  floatingContainerSmall: {
    transition: 'transform 0.5s',
    overflow: 'hidden',
  },
  imageLayer: {
    transition: 'transform 0.5s',
  },
}

class FloatingParallax extends Component {
  layers = [
    {
      image: this.props.backImage,
      y: [-10, 10],
      smallY: [-7, 7],
      imageStyle: {
        transition: 'transform 0.5s',
      },
    },
    {
      image: this.props.frontImage,
      y: [4, -4],
      smallY: [3, -3],
      imageStyle: {
        transition: 'transform 0.5s',
      },
    },
  ]

  state = {
    hovered: false,
  }

  render() {
    const { history, url, useRouter } = this.props
    const { hovered } = this.state

    const wrapperTransform = hovered ? 'scale(1.025)' : 'scale(1)'
    const imageTransform = hovered ? 'scale(1.035)' : 'scale(1.12)'

    styles.floatingContainer = {
      ...styles.floatingContainer,
      transform: wrapperTransform,
    }

    styles.floatingContainerSmall = {
      ...styles.floatingContainerSmall,
      transform: wrapperTransform,
    }

    this.layers[0].imageStyle = {
      ...this.layers[0].imageStyle,
      transform: imageTransform,
    }

    return (
      <FloatingParallaxComponent
        onHover={() => this.setState({ hovered: true })}
        onBlur={() => this.setState({ hovered: false })}
        url={url}
        history={history}
        useRouter={useRouter}
        layers={this.layers}
        styles={styles}
      />
    )
  }
}

export default withRouter(FloatingParallax)
