import React from 'react'
import deepmerge from 'deepmerge'
import '../index.css'
import withTouchscreenSupport from '../utils/TouchscreenSupport'

const BUTTON_IMG = require('../assets/Download Circle.svg')
const BUTTON_IMG_HOVER = require('../assets/Download Circle_hovered.svg')

const DownloadButton = ({ touchscreen, onClick }) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <div
      onClick={onClick}
      onMouseEnter={touchscreen ? null : () => setHovered(true)}
      onMouseLeave={touchscreen ? null : () => setHovered(false)}
      id={'downloadButton'}
      style={styles.container}>
      <div
        style={
          hovered || hovered
            ? deepmerge(styles.button, styles.button.hovered)
            : styles.button
        }>
        <img src={BUTTON_IMG_HOVER} alt={'play'} style={styles.img} />
        <img
          src={BUTTON_IMG}
          alt={'download'}
          style={{
            ...styles.img,
            position: 'absolute',
            opacity: hovered || hovered ? 0 : 1,
            transition: 'opacity 0.35s',
          }}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    width: 45,
    height: 45,
    borderRadius: '50%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderStyle: 'solid',
    paddingLeft: 3,
    height: 45,
    width: 45,
    backgroundColor: 'transparent',
    color: '#6A5B5D',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    transition: 'background-color 0.35s',
    hovered: {
      borderRadius: '50%',
      backgroundColor: '#6A5B5D',
      color: '#FAA860',
      borderColor: '#6A5B5D',
    },
  },
  img: {
    paddingRight: 3,
    transform: 'scale(0.75)',
  },
}

export default withTouchscreenSupport(DownloadButton)
