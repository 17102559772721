import React, { Component } from 'react'
import { Article, withSizes } from '@oos-studio/website-common-components'
import { buildArticle } from './util'

class Section1 extends Component {
  render() {
    const {
      backgroundImage,
      callOutBackgroundColor,
      callOut,
      body,
      dropCap,
      getStyle,
    } = this.props

    return (
      <div
        style={{
          ...getStyle(styles.container),
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <div
          style={{
            ...getStyle(styles.callOut),
            backgroundColor: callOutBackgroundColor,
          }}>
          {callOut}
        </div>
        <div className={'articlePage whoIsJesus'}>
          <Article
            styles={{
              container: {
                maxWidth: 800,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: 0,
                borderTopWidth: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            data={buildArticle(body, dropCap)}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 70,
    paddingRight: 70,
    paddingBottom: 100,
    md: {
      paddingLeft: 35,
      paddingRight: 35,
    },
    sm: {
      paddingBottom: 50,
    },
  },
  callOut: {
    width: '100%',
    maxWidth: 1200,
    marginTop: -50,
    padding: 100,
    fontSize: 45,
    textAlign: 'center',
    fontWeight: 300,
    fontFamily: 'Neutraface Slab Text',
    color: '#F8F5EE',
    marginBottom: 75,
    zIndex: 1,
    boxShadow: '0px 75px 75px #0000001A',
    sm: {
      padding: 30,
      fontSize: 25,
      marginBottom: 50,
    },
  },
  body: {
    width: '100%',
    maxWidth: 800,
  },
}

export default withSizes(Section1)
