import React from 'react'
import { useHistory } from 'react-router-dom'
import { mergeStyles } from '@oos-studio/website-common-components'

const HighlightButton = ({
  text,
  url,
  styles,
  containerWidth,
  containerHeight,
}) => {
  const history = useHistory()
  const [buttonHovered, setButtonHovered] = React.useState(false)

  return (
    <div
      className={'highlightButton'}
      style={{
        ...styles.buttonWrapper,
        width: containerWidth,
        height: containerHeight,
      }}
      onClick={() => history.push(url)}>
      <div
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
        style={{
          ...styles.button,
          backgroundColor: buttonHovered ? '#6A5B5D' : 'transparent',
          color: buttonHovered ? '#FAA860' : '#6A5B5D',
        }}>
        {text}
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: '#FDEBBE',
        }}
      />
    </div>
  )
}

const defaultStyles = {
  buttonWrapper: {
    position: 'relative',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    zIndex: 10,
  },
  button: {
    position: 'absolute',
    top: -5,
    left: -5,
    borderColor: '#6A5B5D',
    borderRadius: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    letterSpacing: 3.4,
    paddingTop: 9,
    fontSize: 20,
    fontFamily: 'Neutraface 2 Text',
    cursor: 'pointer',
    paddingBottom: 9,
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'uppercase',
  },
}

export default mergeStyles(defaultStyles)(HighlightButton)
