import React from 'react'
import { withSizes } from '@oos-studio/website-common-components'

const AuthorBio = ({ url, name, title, md }) => {
  return name ? (
    <div
      style={{
        ...defaultStyles.container,
        margin: md ? '40px 0px 0px 0px' : '40px 0px 0px 120px',
      }}>
      {url ? <img src={url} alt={url} style={defaultStyles.avatar} /> : null}
      <div style={defaultStyles.info}>
        {name ? <span style={defaultStyles.name}>By {name}</span> : null}
        {title ? <span style={defaultStyles.title}>{title}</span> : null}
      </div>
    </div>
  ) : null
}

const defaultStyles = {
  container: {
    display: 'flex',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: 20,
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  name: {
    textAlign: 'left',
    fontSize: '22px',
    lineHeight: '22px',
    fontFamily: 'Neutraface 2 Text',
    letterSpacing: 0,
    color: '#6A5B5D',
    opacity: 1,
    fontWeight: 400,
  },
  title: {
    textAlign: 'left',
    fontStyle: 'italic',
    fontSize: '19px',
    lineHeight: '19px',
    fontFamily: 'Neutraface 2 Text',
    letterSpacing: 0,
    color: '#6A5B5D',
    opacity: 1,
    fontWeight: 300,
    marginTop: 6,
  },
}

export default withSizes(AuthorBio)
