import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  getAudioRecording,
  getBroadcast,
  getHighlight,
} from '../../utils/BoxcastHandler'
import { withSizes } from '@oos-studio/website-common-components'
import VideoAudioSwitch from '../VideoAudioSwitch'
import Footer from '../../footer/Footer'
import InformationHeader from '../../common/InformationHeader'
import deepmerge from 'deepmerge'
import Radium from 'radium'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import '../../index.css'
import { gqlConnect } from 'react-connect-graphql-beta'
import { connect } from 'react-redux'
import Live from './Live'
import withTouchscreenSupport from '../../utils/TouchscreenSupport'
import DocumentMeta from 'react-document-meta'
import ViewAllButton from '../ViewAllButton'
import DownloadMenu from '../DownloadMenu'
import DownloadButton from '../DownloadButton'

class SermonPlayer extends Component {
  state = {
    video: null,
    recording: null,
    downloadHovered: false,
    document: null,
    activeType: 0,
    highlights: [],
    sermonHighlight: null,
    showDownloadMenu: false,
    openedMenu: false,
  }

  downloadMenuRef = React.createRef()

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1250 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1250 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  handleClick = (e) => {
    if (
      this.downloadMenuRef &&
      !this.downloadMenuRef.current?.contains(e.target) &&
      this.state.showDownloadMenu
    ) {
      if (this.state.openedMenu) {
        this.setState({
          openedMenu: false,
        })
      } else {
        this.setState({
          showDownloadMenu: false,
        })
      }
    }
  }

  async componentDidMount() {
    const { history, live, video } = this.props
    const { handleResize, handleClick } = this

    if (window.innerWidth < 1250) {
      this.setState({
        mobile: true,
      })
    }
    window.addEventListener('click', handleClick)
    window.addEventListener('resize', handleResize)

    if (live) {
      this.setState({
        video: video,
      })
    } else {
      const urlPieces = history.location.pathname.split('/')
      const id = urlPieces[urlPieces.length - 1]

      this.setState({
        video: await getBroadcast(id),
        recording: await getAudioRecording(id),
        highlights: await window.boxcast.getBroadcastHighlights(id, {
          q: 'name:sermon',
        }),
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
    window.removeEventListener('resize', this.handleResize)
  }

  playVideo = () => {
    const video = document.querySelector('video')
    if (video) {
      video.play()
    }
  }

  pauseVideo = () => {
    const video = document.querySelector('video')
    if (video) {
      video.pause()
    }
  }

  pauseAudio = () => {
    const audio = document.querySelector('audio')
    if (audio) {
      audio.pause()
    }
  }

  onToggleType = (newType) => {
    const { pauseVideo, pauseAudio } = this
    this.setState({
      activeType: newType,
    })
    if (newType === 0) {
      pauseAudio()
    } else {
      pauseVideo()
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { playVideo } = this
    const { history } = this.props

    setTimeout(() => {
      if (history?.location?.state?.play) {
        playVideo()
      }
    }, 500)

    if (
      prevProps.video !== this.props.video &&
      this.props.history.location.pathname.includes('live')
    ) {
      this.setState({
        video: this.props.video,
      })
    }

    if (
      this.state.video !== prevState.video &&
      this.state.video &&
      !this.state.highlights.length
    ) {
      window
        .boxcast(`#sermon-player-${this.state.video.channel_id}`)
        .loadChannel(this.state.video.channel_id, {})
    }

    if (
      (this.state.highlights.length !== prevState.highlights.length &&
        this.state.highlights.length &&
        this.state.video) ||
      (this.state.video !== prevState.video &&
        this.state.video &&
        this.state.highlights.length)
    ) {
      window
        .boxcast(`#sermon-player-${this.state.video.channel_id}`)
        .loadHighlight(this.state.highlights[0].id, {})

      this.setState({
        sermonHighlight: await getHighlight(
          this.state.video.id,
          this.state.highlights[0].id,
        ),
      })
    }
  }

  onDownloadHover = () => {
    this.setState({
      downloadHovered: true,
    })
  }

  onDownloadLeaveHover = () => {
    this.setState({
      downloadHovered: false,
    })
  }

  onViewNotes = (url) => (event) => {
    if (window.isNativeApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          linking: {
            url,
          },
        }),
      )
      event.preventDefault()
    }
  }

  onClickDownload = () => {
    this.setState({
      showDownloadMenu: true,
      openedMenu: true,
    })
  }

  onCloseDownloadMenu = () => {
    this.setState({
      showDownloadMenu: false,
    })
  }

  render() {
    const {
      video,
      recording,
      downloadHovered,
      activeType,
      mobile,
      sermonHighlight,
      showDownloadMenu,
    } = this.state
    const {
      getStyle,
      sm,
      md,
      live,
      touchscreen,
      isNative,
    } = this.props
    const { data } = this.props.gql
    const {
      onDownloadHover,
      onDownloadLeaveHover,
      onToggleType,
      onViewNotes,
      onClickDownload,
      downloadMenuRef,
    } = this

    const docAvailable =
      video?.content_settings?.documents !== undefined &&
      video?.content_settings?.documents?.length > 0

    let docName = ''
    let docUrl = ''
    if (docAvailable) {
      docName = video?.content_settings?.documents[0].name
      docUrl = video?.content_settings?.documents[0].url
    }

    _styles.mobile = {
      ..._styles.mobile,
      container: {
        ..._styles.mobile.container,
        paddingTop: isNative ? 35 : 150,
      },
    }
    const styles = mobile ? deepmerge(_styles, _styles.mobile) : _styles

    const meta = video
      ? {
          title: video.name,
          description: '',
          meta: {
            charset: 'utf-8',
            name: {
              keywords: video.tags ? video.tags : '',
              author: '',
            },
            itemProp: {
              name: video.name,
              description: video.description,
              image: video.preview,
            },
            property: {
              'og:title': video.name,
              'og:type': 'sermon',
              'og:image': video.preview,
              'og:site_name': 'Providence Church',
              'og:description': '',
              'og:url': window.location.href,
              'fb:app_id': '2317018421941218',
            },
          },
          auto: {
            ograph: true,
          },
        }
      : null

    const showDownloadButton =
      !!recording?.download_url || !!recording?.audio_download_url || !!docUrl

    return live && !video && !recording ? (
      <Live />
    ) : (
      <DocumentMeta {...meta}>
        <React.Fragment>
          <div
            style={getStyle({
              ...styles.backgroundBlock,
              sm: {
                height: isNative ? 200 : 375,
              },
            })}
          />
          <div style={getStyle(styles.container)}>
            <React.Fragment>
              <ViewAllButton />
              {(recording?.audio_download_status === 'ready' ||
                sermonHighlight?.audio_download_status === 'ready') && (
                <VideoAudioSwitch onSwitch={(type) => onToggleType(type)} />
              )}
              <div
                style={getStyle({
                  ...styles.mediaWrapper,
                  paddingBottom:
                    activeType === 1 && sm ? 'calc(57% + 100px)' : '57%',
                  transition: 'padding-bottom 0.5s',
                })}>
                {video && <div id={`sermon-player-${video.channel_id}`} />}
                {(recording?.audio_download_status === 'ready' ||
                  sermonHighlight?.audio_download_status === 'ready') && (
                  <div
                    style={{
                      ...styles.audioPlayerWrapper,
                      transform: activeType === 1 ? 'none' : 'translate(200%)',
                      transition: 'transform 0.5s',
                    }}>
                    <img
                      src={data.defaultAudioPlayerImage[0]?.url}
                      alt={'sermonPreview'}
                      style={{
                        ...styles.audioPreview,
                        height: sm ? 'auto' : 'calc(100% - 100px)',
                        transition: 'height 0.5s',
                      }}
                    />
                    <AudioPlayer
                      className={'sermonAudioPlayer'}
                      autoPlayAfterSrcChange={false}
                      style={styles.audioPlayer}
                      src={`${
                        sermonHighlight
                          ? sermonHighlight?.audio_download_url
                          : recording?.audio_download_url
                      }`}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
            {video && (
              <React.Fragment>
                <div style={styles.headerWrapper}>
                  <InformationHeader
                    speaker={video.content_settings.podcast_summary}
                    topic={video.content_settings.podcast_subtitle}
                    date={video.starts_at}
                    title={video.name}
                  />
                  {!md && showDownloadButton && (
                    <div style={styles.downloadButtonWrapper}>
                      <DownloadButton onClick={onClickDownload} />
                    </div>
                  )}
                  {!md && (
                    <div ref={(r) => (downloadMenuRef.current = r)}>
                      <DownloadMenu
                        docUrl={docUrl}
                        recording={recording}
                        show={showDownloadMenu}
                        top={35}
                        right={0}
                      />
                    </div>
                  )}
                </div>
                <div style={getStyle(styles.download)}>
                  {docAvailable && (
                    <a
                      href={docUrl}
                      onClick={onViewNotes(docUrl)}
                      download={docName}
                      target={'_blank'}
                      rel={'noreferrer'}
                      onMouseEnter={
                        touchscreen ? null : () => onDownloadHover()
                      }
                      onMouseLeave={
                        touchscreen ? null : () => onDownloadLeaveHover()
                      }
                      style={
                        downloadHovered
                          ? deepmerge(
                              getStyle(styles.viewNotesButton),
                              styles.viewNotesButton.hovered,
                            )
                          : getStyle(styles.viewNotesButton)
                      }>
                      <img
                        style={styles.downloadImg}
                        src={
                          downloadHovered
                            ? require('../../assets/Outline_Rollover.png')
                            : require('../../assets/Outline.png')
                        }
                        alt={'download'}
                      />
                      VIEW NOTES
                    </a>
                  )}
                  {md && showDownloadButton && (
                    <div style={getStyle(styles.downloadButtonWrapper)}>
                      <DownloadButton onClick={onClickDownload} />
                    </div>
                  )}
                  {md && (
                    <div ref={(r) => (downloadMenuRef.current = r)}>
                      <DownloadMenu
                        docUrl={docUrl}
                        recording={recording}
                        show={showDownloadMenu}
                        top={-70}
                        right={0}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
          <Footer />
        </React.Fragment>
      </DocumentMeta>
    )
  }
}

const _styles = {
  backgroundBlock: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 500,
    position: 'absolute',
    zIndex: -1,
  },
  container: {
    width: '100%',
    maxWidth: 1270,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    paddingTop: 195,
    paddingLeft: 35,
    paddingRight: 35,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    md: {
      maxWidth: 800,
    },
  },
  mediaWrapper: {
    position: 'relative',
    height: 0,
    width: '100%',
    marginTop: 30,
    overflow: 'hidden',
    md: {},
  },
  audioPlayerWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  audioPreview: {
    width: '100%',
    objectFit: 'cover',
  },
  audioPlayer: {
    width: '100%',
    color: 'blue',
    height: 100,
  },
  iframe: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderWidth: 0,
    overflow: 'hidden',
  },
  headerWrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  download: {
    alignSelf: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    position: 'relative',
    md: {
      width: '100%',
    },
  },
  downloadImg: {
    height: 23,
    paddingBottom: 5,
    marginRight: 15,
  },
  downloadButtonWrapper: {
    marginTop: 68,
    marginLeft: 25,
    md: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  viewNotesButton: {
    color: '#D07D3C',
    flex: 1,
    letterSpacing: 3.4,
    backgroundColor: '#FFFFFF',
    borderWidth: 0,
    padding: 0,
    marginTop: 50,
    fontSize: 23,
    textDecoration: 'none',
    hovered: {
      color: '#FAA860',
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
    },
    md: {
      marginTop: 0,
    },
  },
  mobile: {
    container: {
      paddingTop: window.isNativeApp ? 35 : 150,
    },
  },
}

SermonPlayer.defaultProps = {
  live: false,
}

const mapStateToProps = (state) => {
  return {
    video: state.sermons.video,
    isNative: state.app.isNative,
  }
}

export default connect(mapStateToProps)(
  withTouchscreenSupport(
    gqlConnect('sermonGlobal')(withRouter(withSizes(Radium(SermonPlayer)))),
  ),
)
