import React, { Component } from 'react'
import { Button } from '@oos-studio/website-common-components'
import { styles as globalStyles } from '../styles'
import withTouchscreenSupport from '../utils/TouchscreenSupport'
import RectButton from './RectButton'

class LoadMoreButton extends Component {
  render() {
    const { loading, loadMore, touchscreen } = this.props

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 40,
        }}>
        <RectButton
          onClick={loading ? null : () => loadMore()}
          text={loading ? 'LOADING...' : 'LOAD MORE'}
        />
      </div>
    )
  }
}

export default withTouchscreenSupport(LoadMoreButton)
