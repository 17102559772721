import React from 'react'
import { useHover } from '../hooks/useHover'

const PageFilterOption = (props) => {
  const { key, option, onSelect } = props
  const [hoverRef, isHovered] = useHover()

  return (
    <div
      key={key}
      ref={hoverRef}
      onClick={onSelect}
      style={{
        ...styles.container,
        backgroundColor: isHovered ? '#a39a9333' : 'transparent',
      }}>
      {option}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    fontSize: 18,
    color: '#6A5B5D',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
}

export default PageFilterOption
