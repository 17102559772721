import React, { useState, useEffect } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import PillarsDesktop from './PillarsDesktop'
import PillarsMobile from './PillarsMobile'
import {
  setDropdownArrowColor,
  setNavColor,
  setShowNavUnderline,
} from '../../actions'
import { useDispatch } from 'react-redux'

const Pillars = ({ gql }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  const dispatch = useDispatch()

  const onResizeWindow = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true)
    }
    if (window.innerWidth >= 1000) {
      setIsMobile(false)
    }
  }

  const {
    pillarsHeroImageDesktop,
    pillarsCompactHeroImageDesktop,
    pillarsQuote,
    pillarsQuoteReference,
    repentance,
    pillarsCorporateWorship: corporateWorship,
    pillarsPersonalDevotions: personalDevotions,
    pillarsSmallGroups: smallGroups,
    pillarsService: service,
    pillarsEvangelism: evangelism,
    pillarsDiscipleship: discipleship,
  } = gql.data

  const mapPillarsToStates = () => {
    return [
      {
        headings: [],
        bodies: [],
        image: pillarsCompactHeroImageDesktop[0].url,
      },
      {
        headings: [pillarsQuote],
        bodies: [pillarsQuoteReference],
        image: pillarsCompactHeroImageDesktop[0].url,
      },
      {
        title: 'Repentance',
        headings: repentance.map((r) => r.heading),
        bodies: repentance.map((r) => r.repentanceText),
        image: repentance[0].image[0].url,
      },
      {
        title: 'Corporate Worship',
        headings: corporateWorship.map((c) => c.heading),
        bodies: corporateWorship.map((c) => c.corporateWorshipText),
        image: corporateWorship[0].image[0].url,
      },
      {
        title: 'Personal Devotions',
        headings: personalDevotions.map((p) => p.heading),
        bodies: personalDevotions.map((p) => p.personalDevotionPillarText),
        image: personalDevotions[0].image[0].url,
      },
      {
        title: 'Small Groups',
        headings: smallGroups.map((s) => s.heading),
        bodies: smallGroups.map((s) => s.smallGroupsPillarText),
        image: smallGroups[0].image[0].url,
      },
      {
        title: 'Service',
        headings: service.map((s) => s.heading),
        bodies: service.map((s) => s.servicePillarText),
        image: service[0].image[0].url,
      },
      {
        title: 'Evangelism',
        headings: evangelism.map((e) => e.heading),
        bodies: evangelism.map((e) => e.evangelismPillarText),
        image: evangelism[0].image[0].url,
      },
      {
        title: 'Discipleship',
        headings: discipleship.map((d) => d.heading),
        bodies: discipleship.map((d) => d.discipleshipPillarText),
        image: discipleship[0].image[0].url,
      },
      {
        headings: [],
        bodies: [],
        image: discipleship[0].image[0].url,
      },
    ]
  }

  useEffect(() => {
    dispatch(setShowNavUnderline(false))
    dispatch(setNavColor('#F8F5EE'))
    dispatch(setDropdownArrowColor('#F8F5EE'))

    window.addEventListener('resize', onResizeWindow)
    return () => {
      window.removeEventListener('resize', onResizeWindow)
      dispatch(setShowNavUnderline(true))
      dispatch(setNavColor('#6A5B5D'))
      dispatch(setDropdownArrowColor('#6A5B5D'))
    }
  }, [])

  let states = mapPillarsToStates()

  return isMobile ? (
    <PillarsMobile
      states={[
        ...states,
        {
          headings: [],
          bodies: [],
          image: pillarsCompactHeroImageDesktop[0].url,
        },
      ]}
    />
  ) : (
    <PillarsDesktop
      heroImage={pillarsHeroImageDesktop[0].url}
      states={states}
    />
  )
}

export default gqlConnect('pillars')(Pillars)
