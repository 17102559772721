import React from 'react'

const withTouchscreenSupport = (Component) => {
  return class extends React.Component {
    state = {
      touchscreen: false,
      touching: false,
    }
    componentDidMount() {
      window.addEventListener('touchend', () => {
        this.setState({
          touchscreen: true,
        })
      })
    }

    render() {
      const { touchscreen } = this.state
      return <Component touchscreen={touchscreen} {...this.props} />
    }
  }
}

export default withTouchscreenSupport
