import React, { Component } from 'react'
import LiveBannerWrapper from './LiveBannerWrapper'
import Banner from './Banner'
import { gqlConnect } from 'react-connect-graphql-beta'
import Radium from 'radium'
import { withCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import banner from "./Banner";

class BannerHandler extends Component {
  state = {
    notifications: [],
    mobile: false,
    scrollPos: 0,
    url: window.location.href,
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1040 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1040 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }

  handleScroll = () => {
    const scrollPos = window.scrollY
    this.setState({
      scrollPos: scrollPos,
    })
  }

  componentWillUnmount() {
    this.unlisten() // Stop listening for URL changes when component unmounts
  }

  handleUrlChange = (location) => {
    this.setState({ url: location.pathname }) // Update the state with the new URL when it changes
  }

  componentDidMount() {
    const { handleResize, handleScroll } = this
    const { data } = this.props.gql
    const { cookies } = this.props

    this.unlisten = this.props.history.listen(this.handleUrlChange)

    if (window.innerWidth < 1040) {
      this.setState({
        mobile: true,
      })
    }

    const hiddenBanners = cookies.get('hideBanner')?.split(',')

    this.setState({
      notifications: hiddenBanners
        ? data.filter((d) => {
            return !hiddenBanners.includes(d.id.toString())
          })
        : data,
    })

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
  }

  clearNotification = (index) => {
    const { notifications } = this.state
    const { cookies } = this.props

    const currentHidden = cookies.get('hideBanner')
    cookies.set('hideBanner', `${currentHidden},${notifications[index].id}`, {
      path: '/',
    })

    notifications.splice(index, 1)
    this.setState({
      notifications: notifications,
    })
  }

  render() {
    const { notifications, mobile, scrollPos } = this.state
    const { clearNotification } = this

    return (
      <div
        style={{
          ...styles.container,
          top: window.isNativeApp
            ? 0
            : mobile
            ? 90 - scrollPos < 0
              ? 0
              : 90 - scrollPos
            : 0,
        }}>
        <LiveBannerWrapper />
        {notifications.length > 0 &&
          notifications.map((notification, index) => {
            const now = new Date()
            const currentSlug = `/${window.location.pathname
              .split('/')
              .slice(1)}`
            let bannerSlug = notification.bannerSlug
            if (bannerSlug && bannerSlug[0] !== '/') {
              bannerSlug = '/' + bannerSlug
            }
            const postDate = new Date(notification.postDate)
            const expiryDate = new Date(notification.expiryDate)
            const hasExpiryDate = expiryDate.getFullYear() !== 1969
            const isNotificationActive =
              postDate < now && (!hasExpiryDate || expiryDate > now)
            return isNotificationActive && !bannerSlug ? (
              <Banner
                text={notification.notificationText}
                index={index}
                link={notification.bannerLink}
                showBorder={index < notifications.length - 1}
                isDismissable={notification.dismissable}
                onDismiss={clearNotification}
              />
            ) : isNotificationActive && bannerSlug === currentSlug ? (
              <Banner
                text={notification.notificationText}
                index={index}
                link={notification.bannerLink}
                showBorder={index < notifications.length - 1}
                isDismissable={notification.dismissable}
                onDismiss={clearNotification}
              />
            ) : null
          })}
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    zIndex: 9999,
  },
}

export default gqlConnect('banners')(
  withCookies(Radium(withRouter(BannerHandler))),
)
