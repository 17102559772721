import React, { useEffect, useRef } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { useHover } from '../hooks/useHover'
import PageFilterOption from './PageFilterOption'
import { useHistory } from 'react-router-dom'
import DownChevronDark from '../assets/DownChevronDark.png'
import DownChevronLight from '../assets/DownChevronLight.png'
import XIconDark from '../assets/XIconDark.png'
import XIconLight from '../assets/XIconLight.png'

const PageFilter = (props) => {
  let ref = React.useRef(null)
  const [arrowHoverRef, isArrowHovered] = useHover()

  const history = useHistory()

  const [showOptions, setShowOptions] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(null)

  const { title, options, onSelectOption: _onSelectOption, getStyle } = props

  useEffect(() => {
    window.addEventListener('click', handleClick)
    const unsubscribe = history.listen(listen)
    return () => {
      window.removeEventListener('click', handleClick)
      unsubscribe()
    }
  }, [])

  const listen = () => {
    setSelectedOption(null)
    setShowOptions(false)
  }

  const handleClick = (e) => {
    if (ref?.current?.contains(e.target)) {
      return
    }
    setShowOptions(false)
  }

  const onSelectOption = (option) => {
    setSelectedOption(option)
    setShowOptions(false)
    if (_onSelectOption) {
      _onSelectOption(option)
    }
  }

  const onClearFilter = (e) => {
    e.stopPropagation()
    setSelectedOption(null)
    if (_onSelectOption) {
      _onSelectOption(null)
    }
    setShowOptions(false)
  }

  return (
    <div
      ref={ref}
      style={getStyle({
        ...styles.container,
        zIndex: showOptions ? 1000 : 0,
      })}>
      <div
        onClick={() => setShowOptions(!showOptions)}
        style={{
          ...styles.heading,
          color: showOptions ? '#F8F5EE' : '#6A5B5D',
          backgroundColor: showOptions ? '#6A5B5D' : 'transparent',
          borderColor: showOptions ? '#6A5B5D' : '#A39A93',
        }}>
        <div style={styles.headingTitle}>{selectedOption || title}</div>
        {selectedOption ? (
          <div
            ref={arrowHoverRef}
            onClick={onClearFilter}
            style={{
              ...styles.clearFilter,
              backgroundColor: isArrowHovered ? '#a39a9326' : 'transparent',
            }}>
            <img
              src={showOptions ? XIconLight : XIconDark}
              style={styles.xImage}
            />
          </div>
        ) : (
          <div style={styles.arrowWrapper}>
            <img
              src={showOptions ? DownChevronLight : DownChevronDark}
              style={{
                ...styles.arrowImage,
                transform: showOptions
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              }}
            />
          </div>
        )}
      </div>
      {showOptions && (
        <div style={styles.listWrapper}>
          {options.map((option, index) => {
            return (
              <PageFilterOption
                key={index}
                option={option}
                onSelect={() => onSelectOption(option)}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 400,
    minWidth: 200,
    marginRight: 25,
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 35,
    sm: {
      maxWidth: 'none',
    },
  },
  heading: {
    color: '#6A5B5D',
    fontSize: 18,
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 2,
    display: 'inline-flex',
  },
  headingTitle: {
    padding: 10,
    paddingLeft: 15,
    flex: 1,
  },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    borderStyle: 'solid',
    borderColor: '#A39A93',
    borderWidth: 2,
    borderTopWidth: 0,
    backgroundColor: '#FFFFFF',
  },
  arrowWrapper: {
    padding: 10,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  xImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  clearFilter: {
    padding: 10,
    paddingLeft: 15,
  },
}

export default withSizes(PageFilter)
