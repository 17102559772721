import React from 'react'
import Homepage from './home/screens/Homepage'
import Sermons from './sermons/screens/Sermons'
import BlogList from './blog/screens/BlogList'
import NavBar from './navbar/NavBar'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom'
import SermonPlayer from './sermons/screens/SermonPlayer'
import ScrollToTop from './utils/ScrollToTop'
import BlogPost from './blog/screens/BlogPost'
import { StyleRoot } from 'radium'
import Calendar from './calendar/screens/Calendar'
import Page from './common/Page'
import { gqlConnect } from 'react-connect-graphql-beta'
import BannerHandler from './banner/BannerHandler'
import Leadership from './leadership/screens/Leadership'
import ListenForIsNativeApp from './utils/ListenForIsNativeApp'
import WhoIsJesus from './whoIsJesus/WhoIsJesus'
import Pillars from './pillars/screens/Pillars'
import FloatingConnect from './connect/FloatingConnect'

const getPage = (path) => {
  const PageComp = gqlConnect('page', { slug: path })(Page)
  return <PageComp />
}

function App() {
  const history = useHistory()
  return (
    <StyleRoot>
      <ListenForIsNativeApp />
      <Router>
        <BannerHandler />
        <NavBar />
        <ScrollToTop />
        <FloatingConnect />
        <Switch>
          <Route exact path={'/'} component={Homepage} />
          <Route
            exact
            path={'/sermons/series'}
            component={() => <Sermons isGrid={true} />}
          />
          <Route
            path={'/sermons/series/*'}
            component={() => <Sermons isSeries={true} />}
          />
          <Route
            exact
            path={'/sermons/latest'}
            component={() => <Sermons isLatest={true} />}
          />
          <Route path={'/sermons/player/*'} component={SermonPlayer} />
          <Route exact path={'/live'} component={() => <SermonPlayer live />} />
          <Route path={'/blog/post/*'} component={BlogPost} />
          <Route path={'/blog'} component={BlogList} />
          <Route path={'/calendar'} component={Calendar} />
          <Route path={'/leadership'} component={Leadership} />
          <Route path={'/who-is-jesus'} component={WhoIsJesus} />
          <Route path={'/values'} component={Pillars} />
          <Route
            path={'/*'}
            component={() => getPage(history?.location.pathname)}
          />
        </Switch>
      </Router>
    </StyleRoot>
  )
}

export default App
