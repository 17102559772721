import React, { Component } from 'react'
import {
  withSizes,
  mergeStyles,
  ImageSlider,
} from '@oos-studio/website-common-components'
import HighlightButton from '../common/HighlightButton'
import '../index.css'
import { formatStyledText } from '../utils/formatText'

class FullScreenIntroMobile extends Component {
  render() {
    const {
      text,
      image,
      slideshow,
      getStyle,
      styles,
      history,
      sliderSettings,
    } = this.props

    return (
      <div style={styles.container}>
        <div style={getStyle(styles.topWrapper)}>
          <div style={getStyle(styles.text)}>
            {formatStyledText(text).map((t) => {
              return t.italic ? (
                <span style={{ fontStyle: 'italic' }}>{t.text}</span>
              ) : (
                <span>{t.text}</span>
              )
            })}
          </div>
          <HighlightButton
            text={'Learn More'}
            url={'/values'}
            containerHeight={30}
            containerWidth={135}
            styles={{
              buttonWrapper: { marginTop: 25, marginBottom: 50 },
              button: {
                fontSize: 13,
                padding: '5px 10px',
                fontFamily: 'Neutraface 2 Text',
              },
            }}
          />
        </div>
        <div style={styles.overlayWrapper}>
          <div style={styles.overlay}>
            <img alt={'overlay'} src={image} style={styles.overlay.img} />
          </div>
        </div>
        <div id={'introSlider'} style={styles.sliderWrapper}>
          <ImageSlider
            settings={{
              ...sliderSettings,
              centerMode: true,
              variableWidth: true,
            }}
            history={history}
            data={slideshow}
            styles={getStyle(styles.imageSlider)}
          />
        </div>
      </div>
    )
  }
}

const defaultStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 90,
    overflow: 'hidden',
    backgroundColor: '#F8F5EE',
  },
  topWrapper: {
    zIndex: 1,
    flex: 3,
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 75,
    paddingRight: 150,
    minHeight: 'none',
    sm: {
      paddingTop: 35,
      paddingBottom: 35,
      paddingLeft: 35,
    },
  },
  text: {
    backgroundColor: 'transparent',
    fontFamily: 'Neutraface Slab Text',
    color: '#A39A93',
    fontSize: 48,
    lineHeight: 1.1,
    minWidth: 170,
    sm: {
      fontSize: 42,
    },
    xs: {
      fontSize: 32,
    },
    xxs: {
      fontSize: 24,
    },
  },
  overlayWrapper: {
    backgroundColor: '#FDEBBE',
    height: 150,
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    right: -250,
    img: {
      height: 550,
    },
  },
  sliderWrapper: {
    width: '100%',
    height: 'auto',
  },
  imageSlider: {
    container: {
      backgroundColor: '#6A5B5D',
      padding: 0,
      paddingBottom: 0,
      paddingTop: 25,
    },
    item: {
      height: '100%',
    },
    navLink: {
      outlineWidth: 0,
      height: '100%',
      lg: {},
    },
  },
}

export default mergeStyles(defaultStyles)(withSizes(FullScreenIntroMobile))
