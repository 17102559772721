const initialState = {
  video: null,
  liveText: null,
  selectedBook: null,
  selectedChapter: null,
  selectedSpeaker: null,
  selectedYear: null,
  selectedMonth: null,
  selectedDay: null,
  showDateDropdown: false,
  passageFilterValue: null,
  dayFilterValue: null,
  showBooks: false,
  showSpeakers: false,
  canLoadMoreChannels: true,
  canLoadMoreSeries: true,
  canLoadMoreLatest: true,
  allSermonSeries: {
    total: 0,
    data: null,
  },
  latestSermons: {
    total: 0,
    videos: [],
    image: [],
    name: '',
  },
  selectedSeries: {
    total: 0,
  },
  fetching: false,
  upcomingVideoStartTime: null,
  broadcastDates: null,
}

const sermons = (state = initialState, action) => {
  let loadMoreType
  switch (action.type) {
    case 'SET_VIDEO':
      return Object.assign({}, state, { video: action.payload })
    case 'SET_LIVE_TEXT':
      return Object.assign({}, state, { liveText: action.payload })
    case 'SET_UPCOMING_VIDEO_START_TIME':
      return Object.assign({}, state, {
        upcomingVideoStartTime: action.payload,
      })
    case 'SET_ALL_SERMON_SERIES':
      return Object.assign({}, state, { allSermonSeries: action.payload })
    case 'SET_LATEST_SERMONS':
      return Object.assign({}, state, { latestSermons: action.payload })
    case 'SET_SELECTED_SERIES':
      return Object.assign({}, state, { selectedSeries: action.payload })
    case 'SET_FETCHING':
      return Object.assign({}, state, { fetching: action.payload })
    case 'SET_SELECTED_BOOK':
      return Object.assign({}, state, { selectedBook: action.payload })
    case 'SET_SELECTED_CHAPTER':
      return Object.assign({}, state, { selectedChapter: action.payload })
    case 'SET_SELECTED_YEAR':
      return Object.assign({}, state, { selectedYear: action.payload })
    case 'SET_SELECTED_MONTH':
      return Object.assign({}, state, { selectedMonth: action.payload })
    case 'SET_SELECTED_DAY':
      return Object.assign({}, state, { selectedDay: action.payload })
    case 'SET_SHOW_BOOKS':
      return Object.assign({}, state, { showBooks: action.payload })
    case 'SET_PASSAGE_FILTER_VALUE':
      return Object.assign({}, state, { passageFilterValue: action.payload })
    case 'SET_DAY_FILTER_VALUE':
      return Object.assign({}, state, { dayFilterValue: action.payload })
    case 'SET_FILTER_IDS':
      return Object.assign({}, state, { filteredIds: action.payload })
    case 'SET_SELECTED_SPEAKER':
      return Object.assign({}, state, { selectedSpeaker: action.payload })
    case 'SET_SHOW_SPEAKERS':
      return Object.assign({}, state, { showSpeakers: action.payload })
    case 'SET_CAN_LOAD_MORE':
      loadMoreType = action.payload.loadMoreType
      if (loadMoreType === 'series') {
        return Object.assign({}, state, {
          canLoadMoreSeries: action.payload.canLoadMore,
        })
      } else if (loadMoreType === 'channels') {
        return Object.assign({}, state, {
          canLoadMoreChannels: action.payload.canLoadMore,
        })
      } else {
        return Object.assign({}, state, {
          canLoadMoreLatest: action.payload.canLoadMore,
        })
      }
    case 'SET_BROADCAST_DATES':
      return Object.assign({}, state, {
        broadcastDates: action.payload,
      })
    case 'SET_SHOW_DATE_DROPDOWN':
      return Object.assign({}, state, {
        showDateDropdown: action.payload,
      })
    default:
      return state
  }
}

export default sermons
