import React from 'react'
import { Button as ButtonCommon } from '@oos-studio/website-common-components'
import { styles as globalStyles } from '../styles'
import Button from '../common/Button'
import { withSizes } from '@oos-studio/website-common-components'
import './large-slider.css'

const ImageSliderSlide = ({ slide, getStyle, md }) => {
  const {
    slideImage: image,
    slideImageMobile: mobileImage,
    slideTitleSmall: titleSmall,
    slideTitleLarge: titleLarge,
    buttonText,
    contentVerticalAlignment: vertical,
    contentHorizontalAlignment: horizontal,
    buttonUrl,
    buttonEntry,
  } = slide

  let contentWrapperJustify
  let textWrapperJustify
  switch (horizontal) {
    case 'left':
      contentWrapperJustify = 'flex-start'
      break
    case 'right':
      contentWrapperJustify = 'flex-end'
      break
    default:
      contentWrapperJustify = 'center'
      break
  }

  switch (vertical) {
    case 'top':
      textWrapperJustify = 'flex-start'
      break
    case 'bottom':
      textWrapperJustify = 'flex-end'
      break
    default:
      textWrapperJustify = 'center'
      break
  }

  const url = buttonEntry.length > 0 ? buttonEntry[0].slug : buttonUrl
  const external = buttonEntry.length <= 0

  const imgSrc = md && mobileImage[0]?.url ? mobileImage[0]?.url : image[0]?.url

  return (
    <div style={getStyle(styles.container)}>
      <img src={imgSrc} style={styles.image} />
      <div
        style={{
          ...getStyle(styles.contentWrapper),
          justifyContent: contentWrapperJustify,
        }}>
        <div
          className={'text-wrapper'}
          style={{
            ...getStyle(styles.textWrapper),
            justifyContent: textWrapperJustify,
            alignItems: contentWrapperJustify,
          }}>
          <div style={getStyle(styles.titleSmall)}>{titleSmall}</div>
          <div style={getStyle(styles.titleLarge)}>{titleLarge}</div>
          {buttonText && (
            <Button resetStyles url={url} external={external}>
              <div className={'custom-button'}>
                <ButtonCommon
                  styles={getStyle({
                    ...globalStyles.button,
                    textTransform: 'uppercase',
                    marginTop: 40,
                    borderColor: '#F8F5EE',
                    color: '#F8F5EE',
                    paddingTop: 9,
                    paddingBottom: 9,
                    paddingLeft: 20,
                    paddingRight: 20,
                    hovered: {
                      borderWidth: 1,
                      backgroundColor: 'transparent',
                      color: '#FAA860',
                      borderColor: '#FAA860',
                    },
                    sm: {
                      marginTop: 25,
                      fontSize: 18,
                      paddingTop: 7,
                      paddingBottom: 7,
                      paddingLeft: 17,
                      paddingRight: 17,
                    },
                    xs: {
                      marginTop: 15,
                      fontSize: 15,
                      paddingTop: 6,
                      paddingBottom: 6,
                      paddingLeft: 15,
                      paddingRight: 15,
                    },
                  })}>
                  {buttonText}
                </ButtonCommon>
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    backgroundColor: 'blue',
    position: 'relative',
  },
  contentWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    backgroundColor: 'transparent',
    xs: {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 50,
    sm: {
      padding: 30,
    },
    xs: {
      padding: 20,
    },
  },
  titleSmall: {
    color: '#F8F5EE',
    fontSize: 22,
    fontWeight: 300,
    letterSpacing: 2.25,
    textTransform: 'uppercase',
    sm: {
      fontSize: 16,
    },
    xs: {
      fontSize: 14,
    },
  },
  titleLarge: {
    lineHeight: 1.2,
    fontWeight: 300,
    color: '#F8F5EE',
    fontSize: 45,
    fontFamily: 'Neutraface Slab Text',
    sm: {
      fontSize: 36,
    },
    xs: {
      fontSize: 28,
    },
  },
}

export default withSizes(ImageSliderSlide)
