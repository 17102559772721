import React, { Component } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import Footer from '../footer/Footer'
import { connect } from 'react-redux'
import { setShowNavUnderline, setNavColor } from '../actions'
import Hero from './components/Hero'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'

class WhoIsJesus extends Component {
  componentDidMount() {
    const { setShowNavUnderline, setNavColor } = this.props
    setShowNavUnderline(false)
    setNavColor('#958E8A')
  }

  componentWillUnmount() {
    const { setShowNavUnderline, setNavColor } = this.props
    setShowNavUnderline(true)
    setNavColor('#6A5B5D')
  }

  render() {
    const { hero, section1, section2, section3, callToAction } = this.props.gql
    return (
      <>
        <Hero {...hero} />
        <Section1 {...section1} />
        <Section2 {...section2} />
        <Section3 {...section3} {...callToAction} />
        <Footer />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setShowNavUnderline: (show) => dispatch(setShowNavUnderline(show)),
    setNavColor: (color) => dispatch(setNavColor(color)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(gqlConnect('whoIsJesus')(WhoIsJesus))
