import React, { Component } from 'react'
import { NavBarMobile as NavBarComp } from '@oos-studio/website-common-components'
import '../index.css'
import gsap, { Power2 } from 'gsap'
import { gqlConnect } from 'react-connect-graphql-beta'
import { mapMobileNavItems } from '../utils/ParseNavigationData'
import { withRouter } from 'react-router-dom'

class NavBarMobile extends Component {
  _navBarRef = React.createRef()

  toggleCollapse = (open) => {
    const { getNavBarRef } = this

    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    if (open) {
      tl.to(
        '#mobileCollapse',
        {
          height: 'auto',
        },
        0,
      )
    } else {
      getNavBarRef().closeDropdownMenu()
      tl.to(
        '#mobileCollapse',
        {
          height: 0,
        },
        0,
      )
    }
  }

  getNavBarRef = () => {
    return this._navBarRef
  }

  onBrandClick = (e) => {
    const { history } = this.props

    if (history.location.pathname !== '/') {
      e.preventDefault()
      history.push('/')
    }
  }

  render() {
    const { navItems } = this.props.gql
    const { getNavBarRef, onBrandClick } = this
    const { history } = this.props

    let _data = null
    if (getNavBarRef()) {
      _data = getAllNavigationData(mapMobileNavItems(navItems))
    }

    return (
      <NavBarComp
        onBrandClick={onBrandClick}
        navBarRef={(r) => (this._navBarRef = r)}
        history={history}
        items={_data.navigation.items}
        brand={_data.navigation.brand}
        styles={styles}
        icon={_data.navigation.dropdownIcon}
        fixed={false}
        openToggleIcon={_data.navigation.openToggleIcon}
        toggleCollapse={this.toggleCollapse}
        closeToggleIcon={_data.navigation.closeToggleIcon}
        lockBodyScroll={false}
      />
    )
  }
}

const getAllNavigationData = (items) => {
  return {
    navigation: {
      brand: {
        url: '#/',
        image: {
          title: 'Providence Logo',
          src: require('../assets/ProvidenceLogo.png'),
        },
      },
      dropdownIcon: require('../assets/DropdownCaret.png'),
      openToggleIcon: require('../assets/toggleOpen.png'),
      closeToggleIcon: require('../assets/toggleClose.png'),
      items: items,
      //   items: [
      //     {
      //       text: "I'm New",
      //       url: '#',
      //       type: 'dropdown',
      //       menu: (d) => {
      //         return <ImNewMobile display={d} />
      //       },
      //     },
      //     {
      //       text: 'Ministries',
      //       url: '#',
      //       type: 'dropdown',
      //       menu: (d) => {
      //         return <MinistriesMobile display={d} />
      //       },
      //     },
      //     {
      //       text: 'Resources',
      //       url: '#',
      //       type: 'dropdown',
      //       menu: (d) => {
      //         return <ResourcesMobile display={d} />
      //       },
      //     },
      //     {
      //       text: 'Calendar',
      //       url: '#/3',
      //       type: 'link',
      //     },
      //     {
      //       text: 'Giving',
      //       url: '#/4',
      //       type: 'link',
      //     },
      //   ],
    },
  }
}

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    backdropFilter: 'blur(8px)',
    '-webkit-backdrop-filter': 'blur(8px)',
    minWidth: 320,
    position: 'fixed',
    height: 90,
  },
  navbar: {
    padding: 0,
    overflow: 'unset',
    boxShadow: '0px 1px 35px rgba(106, 91, 93, 0.25)',
  },
  brand: {},
  brandImage: {
    marginLeft: 30,
    marginTop: 3,
    width: 200,
    height: 'auto',
  },
  toggler: {
    height: 18,
    width: 18,
    cursor: 'pointer',
  },
  collapse: {
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    height: 0,
    width: '100%',
  },
  nav: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  navItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    paddingRight: 25,
    paddingLeft: 25,
  },
  navLink: {
    width: '100%',
    color: '#6A5B5D',
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#A39A93',
    paddingLeft: 15,
    textDecoration: 'none',
  },
  dropdownItem: {
    width: '100%',
    color: '#6A5B5D',
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: 0,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 22,
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#A39A93',
  },
  ucDropdown: {
    paddingRight: 25,
    paddingLeft: 25,
    width: '100%',
    marginBottom: 10,
  },
  dropdownIcon: {
    height: 'auto',
    width: 20,
    transform: 'none',
    transition: 'all 0.25s',
    click: {
      transform: 'rotate(180deg)',
    },
  },
  dropdownArrow: {
    borderColor: '#6A5B5D',
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: 5,
    transform: 'rotate(45deg)',
    marginBottom: 1,
    transition: 'transform 0.25s',
    marginLeft: 5,
    clicked: {
      transform: 'rotate(-135deg)',
    },
  },
  dropdownMenu: {},
  toggleContainer: {
    padding: 35,
    paddingBottom: 25,
  },
}

export default gqlConnect('navigation')(withRouter(NavBarMobile))
