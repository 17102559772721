import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import Radium from 'radium'

class LeaderImage extends Component {
  state = {
    hovered: false,
    touchscreen: false,
  }

  onHover = () => {
    this.setState({
      hovered: true,
    })
  }

  onLeaveHover = () => {
    this.setState({
      hovered: false,
    })
  }

  componentDidMount() {
    window.addEventListener('touchend', () => {
      this.setState({
        touchscreen: true,
      })
    })
  }

  render() {
    const { text, image, email, firstName, getStyle, isGrid } = this.props
    const { hovered, touchscreen } = this.state
    const { onHover, onLeaveHover } = this

    return (
      <div
        style={getStyle({
          ...styles.container,
          cursor: text ? 'pointer' : 'unset',
          sm: isGrid
            ? {
                width: 'calc(100vw - 70px)',
                height: 'calc(100vw - 70px)',
              }
            : {},
        })}>
        {image && (
          <img src={image} alt={'leaderImg'} style={getStyle(styles.img)} />
        )}
        {text && (
          <div
            onClick={
              touchscreen
                ? hovered
                  ? () => onLeaveHover()
                  : () => onHover()
                : null
            }
            onMouseEnter={touchscreen ? null : onHover}
            onMouseLeave={touchscreen ? null : onLeaveHover}
            style={{
              ...getStyle(styles.textBlock),
              opacity: hovered ? 1 : 0,
              transition: 'opacity 0.25s',
            }}>
            <div>{text?.replace(/(<([^>]+)>)/gi, '')}</div>
            {email && firstName && (
              <div style={{ marginTop: 25 }}>
                <a
                  style={{ color: '#65A3BE' }}
                  href={`mailto:${email}`}>{`Email ${firstName}`}</a>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#CFC6BE',
    padding: 10,
    width: 300,
    height: 300,
    position: 'relative',
    xs: {
      width: 'calc(100vw - 70px)',
      height: 'calc(100vw - 70px)',
    },
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  textBlock: {
    position: 'absolute',
    textAlign: 'left',
    top: 0,
    right: 0,
    left: 0,
    bottom: 'unset',
    minHeight: 300,
    padding: 30,
    backgroundColor: '#F8F5EE',
    color: '#6A5B5D',
    fontFamily: 'Neutraface 2 Text',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    sm: {
      height: '100%',
      bottom: 0,
    },
  },
}

LeaderImage.defaultProps = {
  isGrid: false,
}

export default withSizes(Radium(LeaderImage))
