import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import deepmerge from 'deepmerge'
import { withSizes } from '@oos-studio/website-common-components'

const RectButton = ({
  url,
  external,
  newTab,
  onClick: _onClick,
  light,
  hoverLight,
  text,
  getStyle,
  fontSize: _fontSize,
  fullWidth,
  isFront,
  isFlipped,
}) => {
  const [hovered, setHovered] = useState(false)
  const history = useHistory()

  const onClick = (e) => {
    if (_onClick) {
      _onClick(e)
      return
    }
    if (window.isNativeApp) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(url)
      }
    } else {
      if (external) {
        if (newTab) {
          window.open(url, '__blank')
        } else {
          window.location = url
        }
      } else {
        history.push(`/${url}`)
      }
    }
  }

  let containerStyles = styles.container
  if (light) {
    if (hovered) {
      containerStyles = deepmerge(
        styles.containerLight,
        hoverLight
          ? styles.containerLight.hoveredLight
          : styles.containerLight.hovered,
      )
    } else {
      containerStyles = styles.containerLight
    }
  } else {
    if (hovered) {
      containerStyles = deepmerge(
        styles.container,
        hoverLight ? styles.container.hoveredLight : styles.container.hovered,
      )
    }
  }

  const fontSize = _fontSize || containerStyles.fontSize

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      style={getStyle({
        ...containerStyles,
        fontSize,
        width: fullWidth ? '100%' : 'fit-content',
        transform: isFront && isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
      })}>
      {text}
    </div>
  )
}

const styles = {
  container: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    color: '#6A5B5D',
    borderColor: '#6A5B5D',
    borderRadius: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    paddingTop: 9,
    width: 'fit-content',
    paddingBottom: 9,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: 3.4,
    hovered: {
      backgroundColor: '#6A5B5D',
      color: '#FAA860',
    },
    hoveredLight: {
      color: '#FAA860',
      borderColor: '#FAA860',
    },
    xxs: {
      fontSize: 16,
      paddingBottom: 7,
    },
  },
  containerLight: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    color: '#F8F5EE',
    borderColor: '#F8F5EE',
    borderRadius: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    paddingTop: 9,
    paddingBottom: 9,
    width: 'fit-content',
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: 3.4,
    hovered: {
      backgroundColor: '#6A5B5D',
      borderColor: '#6A5B5D',
      color: '#FAA860',
    },
    hoveredLight: {
      color: '#FAA860',
      borderColor: '#FAA860',
    },
    xxs: {
      fontSize: 16,
      paddingBottom: 7,
    },
  },
}

export default withSizes(RectButton)
