import React, { useEffect, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'
import { Media } from 'reactstrap'
import Button from './Button'
import { useHistory } from 'react-router-dom'
import RectButton from './RectButton'
import { withSizes } from '@oos-studio/website-common-components'

const ImageAlignedText = ({
  image = { src: '', title: '' },
  text = '',
  button = { text: '', onClick: null },
  textAlign = 'left',
  parallaxLayers = [],
  smallMax = 730,
  getStyle,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [containerHeight, setContainerHeight] = useState(0)

  const useRouter = button.entry.length > 0

  const onResize = () => {
    setTimeout(() => setContainerHeight(getContainerHeight()), 10)
  }

  const getContainerHeight = () => {
    const textAndButtons = document.getElementsByClassName(
      'aligned-text-and-button',
    )
    const topPaddings = document.getElementsByClassName(
      'image-aligned-text-top-padding',
    )
    let _containerHeight = containerHeight

    if (textAndButtons.length > 0 && topPaddings.length > 0) {
      const textAndButtonHeight = textAndButtons[0].clientHeight
      const windowWidth = window.innerWidth
      const paddingTop = topPaddings[0].offsetHeight
      let paddingBottom = 250
      if (windowWidth <= 575) {
        paddingBottom = 100
      } else if (windowWidth <= 1200) {
        paddingBottom = 125
      } else if (windowWidth <= 1500) {
        paddingBottom = 200
      }
      _containerHeight = textAndButtonHeight + paddingTop + paddingBottom
    }

    return _containerHeight
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const getX = (layer) => {
    const { x, smallX } = layer
    if (windowWidth <= smallMax) {
      return smallX ? smallX : x
    }
    return x
  }

  const renderLayer = (layer, index) => {
    return (
      <div key={index} style={getStyle(styles.parallaxContainer)}>
        <Parallax x={getX(layer)}>
          <img
            alt={layer.title ? layer.title : 'img'}
            src={layer.src}
            style={getStyle(styles.parallaxImage)}
          />
        </Parallax>
      </div>
    )
  }

  const contentSide = textAlign === 'right' ? 'flex-end' : 'flex-start'

  return (
    <div style={getStyle({ ...styles.container, height: containerHeight })}>
      <Media
        object
        alt={image.title ? image.title : 'img'}
        src={image.src}
        style={getStyle(styles.image)}
      />
      {parallaxLayers.map((layer, index) => renderLayer(layer, index))}
      <div
        style={getStyle(styles.content)}
        className={'image-aligned-text-top-padding'}>
        <div
          style={{
            justifyContent: contentSide,
            ...getStyle(styles.overlay),
          }}>
          <div
            className={'aligned-text-and-button'}
            style={{
              float: textAlign,
              alignItems: contentSide,
              ...getStyle(styles.subContainer),
            }}>
            <div
              style={{
                textAlign: textAlign,
                ...getStyle(styles.textWrapper),
              }}>
              {text}
            </div>
            {button.text.length > 0 && (
              <div style={getStyle(styles.buttonWrapper)}>
                <RectButton
                  text={button.text}
                  url={useRouter ? button.entry[0].slug : button.url}
                  external={!useRouter}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    height: 675,
  },
  content: {
    marginLeft: 75,
    marginRight: 75,
    paddingTop: 125,
    sm: {
      marginLeft: 35,
      marginRight: 35,
      paddingTop: 175,
    },
    xs: {
      paddingTop: 85,
    },
  },
  image: {
    position: 'absolute',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  parallaxContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '90%',
    overflow: 'hidden',
  },
  parallaxImage: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  overlay: {
    position: 'relative',
    maxWidth: 1200,
    margin: 'auto',
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textWrapper: {
    width: '66%',
    fontSize: 52,
    overflow: 'hidden',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    color: '#F8F5EE',
    lineHeight: 1.3,
    cursor: 'default',
    md: {
      width: '100%',
    },
    sm: {
      fontSize: 45,
    },
    xs: {
      fontSize: 35,
    },
  },
  buttonWrapper: {
    marginTop: 30,
  },
  button: {
    color: 'white',
    hovered: {
      backgroundColor: 'white',
      color: 'grey',
      borderColor: 'grey',
    },
  },
}

export default withSizes(ImageAlignedText)
