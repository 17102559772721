import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import DateIcon from './DateIcon'
import { Link } from 'react-router-dom'
import Button from './Button'

class InformationHeader extends Component {
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  formatDate = (_date) => {
    const { months } = this

    const date = new Date(_date)
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  render() {
    const {
      md,
      getStyle,
      topic,
      speaker,
      date,
      title,
      topicType,
      topicRoute,
    } = this.props
    const { formatDate } = this

    return (
      <div style={getStyle(styles.container)}>
        {!md && (
          <div>
            <DateIcon date={date} />
          </div>
        )}
        <div style={getStyle(styles.titleWrapper)}>
          {topic && (
            <div
              style={{
                ...styles.topic,
                color: topicType === 'link' ? '#65A3BE' : '#6A5B5D',
              }}>
              {topicType === 'link' ? (
                <Button resetStyles url={topicRoute}>
                  <div
                    style={{
                      ...styles.topic,
                      color: topicType === 'link' ? '#65A3BE' : '#6A5B5D',
                    }}>
                    {topic}
                  </div>
                </Button>
              ) : (
                topic
              )}
            </div>
          )}
          <div style={getStyle(styles.divider)} />
          <div style={getStyle(styles.titleBottom)}>
            <div style={getStyle(styles.title)}>{title}</div>
            {md && <div style={styles.date}>{formatDate(date)}</div>}
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 55,
    height: 100,
    md: {
      height: 'auto',
    },
  },
  titleWrapper: {
    flex: 1,
    //  height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 40,
    fontSize: 18,
    color: '#6A5B5D',
    md: {
      display: 'unset',
      marginLeft: 0,
      height: 'auto',
    },
  },
  divider: {
    backgroundColor: '#A39A93',
    height: 1,
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
    md: {
      marginTop: 25,
      marginBottom: 15,
    },
  },
  titleBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    md: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginBottom: 15,
    },
  },
  title: {
    fontSize: 45,
    color: '#695B5D',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    lineHeight: 1.2,
    md: {},
  },
  speaker: {
    whiteSpace: 'nowrap',
  },
}

InformationHeader.defaultProps = {
  topicType: 'text',
}

export default withSizes(InformationHeader)
