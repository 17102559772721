import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import ViewAllButton from '../ViewAllButton'

class Live extends Component {
  constructor(props) {
    super(props)
    this.timer = 0
    this.startCountdown = this.startCountdown.bind(this)
    this.decrementTime = this.decrementTime.bind(this)
    this.formatTime = this.formatTime.bind(this)
  }

  state = {
    time: {},
    seconds: 0,
  }

  formatTime(secs) {
    let hours = Math.floor(secs / (60 * 60))
    let minutes = Math.floor((secs % (60 * 60)) / 60)
    let seconds = Math.ceil((secs % (60 * 60)) % 60)
    return {
      h: hours,
      m: minutes,
      s: seconds,
    }
  }

  startCountdown() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.decrementTime, 1000)
    }
  }

  decrementTime() {
    let seconds = this.state.seconds - 1
    this.setState({
      time: this.formatTime(seconds),
      seconds: seconds,
    })

    if (seconds === 0) {
      clearInterval(this.timer)
    }
  }

  initializeCountdown() {
    const { startTime } = this.props
    if (startTime) {
      let start = new Date(startTime)
      let seconds =
        Math.round(start.getTime() / 1000) -
        Math.round(new Date().getTime() / 1000)
      if (seconds > 0) {
        this.setState({ seconds }, () => {
          let remaining = this.formatTime(seconds)
          this.setState({ time: remaining })
          this.startCountdown()
        })
      }
    }
  }

  componentDidMount() {
    const { startTime } = this.props
    if (startTime) {
      this.initializeCountdown()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startTime } = this.props
    if (startTime && !prevProps.startTime) {
      this.initializeCountdown()
    }
  }

  render() {
    const { text, startTime } = this.props
    const { time } = this.state

    let countdown = null

    if (startTime && time) {
      const hours = `${time.h < 10 ? `0${time.h}` : time.h}`
      const minutes = `${time.m < 10 ? `0${time.m}` : time.m}`
      const seconds = `${time.s < 10 ? `0${time.s}` : time.s}`
      countdown = `(${hours}:${minutes}:${seconds})`
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          {text}
          {countdown ? <span style={styles.countdown}>{countdown}</span> : null}
        </div>
        <ViewAllButton center />
      </div>
    )
  }
}

const styles = {
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 200,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 65,
    backgroundColor: '#F8F5EE',
    textAlign: 'center',
    '@media (max-width: 1039px)': {
      paddingTop: 120,
      paddingBottom: 30,
    },
  },
  header: {
    fontSize: 45,
    color: '#6A5B5D',
    fontFamily: 'Neutraface Slab Text',
    fontWeight: 300,
    marginBottom: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'Neutraface 2 Text',
    fontSize: 22,
    color: '#6A5B5D',
  },
  link: {
    color: '#65A3BE',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  countdown: {
    width: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
}

const mapStateToProps = (state) => {
  return {
    text: state.sermons.liveText,
    startTime: state.sermons.upcomingVideoStartTime,
  }
}

export default connect(mapStateToProps)(Radium(Live))
