import React, { Component } from 'react'
import NavLink from '../common/NavLink'

class SermonFilterToggle extends Component {
  clickLink = (e) => {
    const {
      useRouter,
      onClickItem,
      item,
      history,
      hideDropDown,
      append,
      nativeNavigation,
    } = this.props

    e.stopPropagation()

    if (window.ReactNativeWebView && nativeNavigation && window.isNativeApp) {
      e.preventDefault()
      window.ReactNativeWebView.postMessage(item.url)
      return
    }

    if (hideDropDown !== undefined) {
      hideDropDown()
    }

    if (useRouter && history) {
      e.preventDefault()
      let url
      if (item.url) {
        url = item.url[0] === '/' || !append ? item.url : `/${item.url}`
        history.push(url)
      }
    }
    if (onClickItem) {
      onClickItem(e, item.url)
    }
  }

  render() {
    const { history, type } = this.props
    const { onToggle } = this

    return (
      <div style={styles.container}>
        <NavLink
          onClick={() => {
            onToggle(0)
          }}
          style={{
            ...styles.series,
            color: type === 0 ? '#F8F5EE' : '#6A5B5D',
            backgroundColor: type === 0 ? '#6A5B5D' : '#F8F5EE',
          }}
          nativeNavigation={false}
          useRouter={true}
          history={history}
          item={{ url: '/sermons/series' }}>
          Series
        </NavLink>
        <NavLink
          onClick={() => {
            onToggle(1)
          }}
          style={{
            ...styles.latest,
            color: type === 1 ? '#F8F5EE' : '#6A5B5D',
            backgroundColor: type === 1 ? '#6A5B5D' : '#F8F5EE',
          }}
          nativeNavigation={false}
          useRouter={true}
          history={history}
          item={{ url: '/sermons/latest' }}>
          Latest
        </NavLink>
      </div>
    )
  }
}

const styles = {
  container: {
    width: 140,
    borderColor: '#6A5B5D',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  series: {
    padding: 5,
    paddingRight: 10,
    paddingLeft: 15,
    fontSize: 19,
    cursor: 'pointer',
    flex: 1,
    textAlign: 'center',
  },
  latest: {
    flex: 1,
    padding: 5,
    fontSize: 19,
    cursor: 'pointer',
    textAlign: 'center',
  },
}

export default SermonFilterToggle
