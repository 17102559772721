import {
  getBroadcast,
  getChannels,
  getTagsFromCraft,
  getVideosFromChannel,
} from '../utils/BoxcastHandler'
import config from '../config'

export const setVideo = (video) => ({
  type: 'SET_VIDEO',
  payload: video,
})

export const setLiveText = (text) => ({
  type: 'SET_LIVE_TEXT',
  payload: text,
})

export const setUpcomingVideoStartTime = (start) => ({
  type: 'SET_UPCOMING_VIDEO_START_TIME',
  payload: start,
})

const setAllSermonSeries = (channels) => ({
  type: 'SET_ALL_SERMON_SERIES',
  payload: channels,
})

const setLatestSermons = (sermons) => ({
  type: 'SET_LATEST_SERMONS',
  payload: sermons,
})

const setSelectedSeries = (selected) => ({
  type: 'SET_SELECTED_SERIES',
  payload: selected,
})

const setFetching = (isFetching) => ({
  type: 'SET_FETCHING',
  payload: isFetching,
})

export const setSelectedYear = (year) => ({
  type: 'SET_SELECTED_YEAR',
  payload: year,
})

export const setSelectedMonth = (month) => ({
  type: 'SET_SELECTED_MONTH',
  payload: month,
})

export const setSelectedDay = (day) => ({
  type: 'SET_SELECTED_DAY',
  payload: day,
})

export const setSelectedBook = (book) => ({
  type: 'SET_SELECTED_BOOK',
  payload: book,
})

export const setSelectedChapter = (chapter) => ({
  type: 'SET_SELECTED_CHAPTER',
  payload: chapter,
})

export const setShowBooks = (showBooks) => ({
  type: 'SET_SHOW_BOOKS',
  payload: showBooks,
})

export const setPassageFilterValue = (filterValue) => ({
  type: 'SET_PASSAGE_FILTER_VALUE',
  payload: filterValue,
})

export const setDayFilterValue = (dayFilter) => ({
  type: 'SET_DAY_FILTER_VALUE',
  payload: dayFilter,
})

export const setSelectedSpeaker = (speaker) => ({
  type: 'SET_SELECTED_SPEAKER',
  payload: speaker,
})

export const setShowDateDropdown = (show) => ({
  type: 'SET_SHOW_DATE_DROPDOWN',
  payload: show,
})

export const setShowSpeakers = (show) => ({
  type: 'SET_SHOW_SPEAKERS',
  payload: show,
})

export const setCanLoadMore = (canLoad, type) => ({
  type: 'SET_CAN_LOAD_MORE',
  payload: {
    loadMoreType: type,
    canLoadMore: canLoad,
  },
})

export const setBroadcastDates = (broadcastDates) => ({
  type: 'SET_BROADCAST_DATES',
  payload: broadcastDates,
})

export const getAllSermonSeries = (itemLimit) => {
  return async (dispatch) => {
    dispatch(setFetching(true))

    let allSeries = await getChannels(itemLimit + 1)

    if (allSeries.data) {
      allSeries.data.sort((a, b) => {
        return new Date(b.date) < new Date(a.date) ? -1 : 1
      })
    }

    if (allSeries.data.length <= itemLimit) {
      dispatch(setCanLoadMore(false, 'channels'))
    } else {
      allSeries.data = allSeries.data.slice(0, itemLimit)
      dispatch(setCanLoadMore(true, 'channels'))
    }

    dispatch(setAllSermonSeries(allSeries))
    dispatch(setFetching(false))
  }
}

export const getSelectedSeries = (id, itemLimit, seriesName = '') => {
  return async (dispatch) => {
    dispatch(setFetching(true))
    dispatch(setSelectedSeries({ name: seriesName }))

    let series = await getVideosFromChannel(id, itemLimit + 1)
    if (series.videos.length <= itemLimit) {
      dispatch(setCanLoadMore(false, 'series'))
    } else {
      series.videos = series.videos.slice(0, itemLimit)
      dispatch(setCanLoadMore(true, 'series'))
    }
    const seriesWithTags = {
      ...series,
      videos: await setTags(series),
    }
    dispatch(setSelectedSeries(seriesWithTags))

    dispatch(setFetching(false))
  }
}

export const getLatestSermons = (
  itemLimit,
  useFilter = false,
  filterBook = null,
  filterChapter = null,
  selectedSpeaker = null,
  startsAt = null,
  stopsAt = null,
) => {
  return async (dispatch) => {
    dispatch(setFetching(true))

    let latest = await getVideosFromChannel(
      config.boxcast.channelId,
      itemLimit + 1,
      'desc',
      false,
      useFilter,
      filterBook,
      filterChapter,
      selectedSpeaker,
      startsAt,
      stopsAt,
    )

    if (latest.videos.length <= itemLimit) {
      dispatch(setCanLoadMore(false, 'latest'))
    } else {
      latest.videos = latest.videos.slice(0, itemLimit)
      dispatch(setCanLoadMore(true, 'latest'))
    }

    const latestWithTags = {
      ...latest,
      videos: await setTags(latest),
    }
    dispatch(setLatestSermons(latestWithTags))

    dispatch(setFetching(false))
  }
}

const setTags = async (sermons) => {
  return await Promise.all(
    sermons.videos.map(async (video) => {
      let tagsFromCraft = await getTagsFromCraft(video.id)
      let info = await getBroadcast(video.id)
      let tags
      tags = {
        passage: info.content_settings.podcast_subtitle,
        speaker: info.content_settings.podcast_summary,
      }

      if (tagsFromCraft.referenceDisplay) {
        tags.passage = tagsFromCraft.referenceDisplay
      }

      if (tagsFromCraft.speaker) {
        tags.speaker = tagsFromCraft.speaker
      }

      return {
        ...info,
        tags,
      }
    }),
  )
}

export const getBroadcastDates = () => {
  return async (dispatch) => {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')

    const response = await fetch(
      `${config.providenceApi.baseUrl}/broadcast_dates`,
      {
        method: 'GET',
        headers: headers,
      },
    )

    const dates = await response.json()
    dispatch(setBroadcastDates(dates))
  }
}
