import React, { Component } from 'react'
import { Button } from '@oos-studio/website-common-components'
import { withRouter } from 'react-router-dom'

class ViewAllButton extends Component {
  state = {
    buttonHover: false,
  }

  onHoverButton = () => {
    this.setState({
      buttonHover: true,
    })
  }

  onLeaveHoverButton = () => {
    this.setState({
      buttonHover: false,
    })
  }
  render() {
    const { history, center } = this.props
    const { onHoverButton, onLeaveHoverButton } = this
    const { buttonHover } = this.state

    return (
      <div
        onMouseEnter={onHoverButton}
        onMouseLeave={onLeaveHoverButton}
        style={{
          ...styles.viewAll,
          marginLeft: center ? 'auto' : 0,
        }}>
        <Button
          useRouter={true}
          history={history}
          item={{ url: '/sermons/series' }}
          styles={{
            ...styles.button,
            navLink: {
              color: buttonHover ? '#FAA860' : '#D07D3C',
              padding: 0,
            },
          }}>
          VIEW ALL SERMONS
          <div
            style={{
              ...styles.arrow,
              borderColor: buttonHover ? '#FAA860' : '#D07D3C',
              right: buttonHover ? 260 : 250,
            }}
          />
        </Button>
      </div>
    )
  }
}

const styles = {
  viewAll: {
    maxWidth: 1200,
    marginRight: 'auto',
  },
  button: {
    marginLeft: 20,
    color: '#D07D3C',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderStyle: 'none',
    letterSpacing: 2.4,
    position: 'relative',
    padding: 0,
    hovered: {
      backgroundColor: 'transparent',
    },
  },
  arrow: {
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    position: 'absolute',
    padding: 5,
    top: 7,
    transform: 'rotate(140deg)',
    marginBottom: 1,
    marginRight: -20,
    transition: 'right 0.5s',
  },
}

ViewAllButton.defaultProps = {
  center: false,
}

export default withRouter(ViewAllButton)
