import React from 'react'
import { Timeline, Tween } from 'react-gsap'
import { withSizes } from '@oos-studio/website-common-components'

const ArrowAnimated = ({ getStyle, styles }) => {
  return (
    <Timeline
      repeat={-1}
      target={<i style={{ ...getStyle(_styles.arrow), ...styles }} />}>
      <Tween
        from={{ y: 0 }}
        to={{ y: 30 }}
        duration={0.75}
        ease="power1.inOut"
      />
      <Tween
        from={{ y: 30 }}
        to={{ y: 0 }}
        duration={0.75}
        ease="power1.inOut"
      />
    </Timeline>
  )
}
const _styles = {
  arrow: {
    borderStyle: 'solid',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    position: 'absolute',
    padding: 25,
    bottom: 115,
    left: 'calc(50% - 37px)',
    zIndex: 2,
    transform: 'rotate(45deg)',
    marginLeft: 15,
    transition: 'right 0.5s',
    xs: {
      padding: 20,
    },
    xxs: {
      padding: 15,
    },
  },
}

export default withSizes(ArrowAnimated)
