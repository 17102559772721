export const SET_IS_NATIVE_APP = 'SET_IS_NATIVE_APP'
export const SET_NAV_COLOR = 'SET_NAV_COLOR'
export const SET_SHOW_NAV_UNDERLINE = 'SET_SHOW_NAV_UNDERLINE'
export const SET_DROPDOWN_ARROW_COLOR = 'SET_DROPDOWN_ARROW_COLOR'

export const setIsNativeApp = () => {
  return {
    type: SET_IS_NATIVE_APP,
  }
}

export const setNavColor = (color) => {
  return {
    type: SET_NAV_COLOR,
    payload: color,
  }
}

export const setShowNavUnderline = (show) => {
  return {
    type: SET_SHOW_NAV_UNDERLINE,
    payload: show,
  }
}

export const setDropdownArrowColor = (color) => {
  return {
    type: SET_DROPDOWN_ARROW_COLOR,
    payload: color,
  }
}
