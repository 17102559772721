import React, { Component } from 'react'
import { NavLink } from '@oos-studio/website-common-components'
import gsap, { Power2 } from 'gsap'
import GoogleMapView from '../common/GoogleMapView'
import { withRouter } from 'react-router-dom'

class ImNewMobile extends Component {
  state = {
    linkHover: false,
    activeLink: null,
  }
  hoverLink = (index) => {
    this.setState({
      linkHover: true,
      activeLink: index,
    })
  }
  leaveHoverLink = (index) => {
    this.setState({
      linkHover: false,
      activeLink: null,
    })
  }
  open = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#imNewContainer', { display: 'flex' }, 0)
    tl.to('#imNewContainer', { height: 'auto', paddingTop: 25 }, 0)
    tl.to('#imNewContainer', { opacity: 1 }, duration)
  }
  close = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#imNewContainer', { opacity: 0 }, 0)
    tl.to('#imNewContainer', { height: 0, paddingTop: 0 }, duration / 2)
    tl.to('#imNewContainer', { display: 'none' }, duration / 2)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  render() {
    const { activeLink, linkHover } = this.state
    const { data, history } = this.props
    const { hoverLink, leaveHoverLink } = this

    return (
      <div id="imNewContainer" style={styles.container}>
        <div style={styles.linkWrapper}>
          {data[0]?.links.map((i, index) => {
            return (
              <div style={styles.link}>
                <NavLink
                  useRouter={i.entry && i.entry[0]?.slug}
                  history={history}
                  onMouseEnter={() => hoverLink(index)}
                  item={{ url: i.entry ? i.entry[0].slug : i.linkUrl }}
                  onMouseLeave={() => leaveHoverLink(index)}
                  style={{
                    ...styles.link,
                    color:
                      activeLink === index && linkHover && styles.link.hover
                        ? styles.link.hover.color
                        : styles.link.color,
                  }}
                  href={i.entry ? i.entry[0].slug : i.linkUrl}>
                  {i.displayName}
                </NavLink>
              </div>
            )
          })}
        </div>
        <div id="timeWrapper" style={styles.timeWrapper}>
          <div style={styles.timeWrapper.header}>{data[1]?.heading}</div>
          {data[1].menuInformationItems.map((info, index) => {
            return (
              <div style={{ marginBottom: 15 }} key={index}>
                <div
                  style={{
                    borderColor: '#A39A93',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    paddingBottom: 10,
                    marginBottom: 10,
                    whiteSpace: 'nowrap',
                    color: '#6A5B5D',
                    fontSize: 18,
                  }}>
                  <span style={{ fontWeight: 'bold' }}>{info.text}: </span>
                  <span>{info.subText}</span>
                  {info.itemSubtitle && (
                    <div
                      style={{
                        fontSize: 18,
                        marginTop: -3,
                        fontStyle: 'italic',
                      }}>
                      {info.itemSubtitle}
                    </div>
                  )}
                </div>
                <div
                  className={'mmInfoButton'}
                  onClick={() => {
                    if (info.itemButtonEntry?.length > 0) {
                      history.push(info.itemButtonEntry[0]?.uri)
                    } else {
                      window.open(info.itemButtonUrl, '__blank')
                    }
                  }}
                  style={{
                    fontSize: 18,
                    color: '#6A5B5D',
                    cursor: 'pointer',
                  }}>
                  {info.itemButtonText}
                </div>
              </div>
            )
          })}
        </div>
        <div style={styles.mapWrapper}>
          <GoogleMapView
            width={'100%'}
            height={200}
            position={'relative'}
            infoWindowText={data[2]?.infoWindowText}
            marker={{
              lat: data[2]?.pinLocation.latitude,
              lng: data[2]?.pinLocation.longitude,
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    paddingLeft: 15,
    paddingRight: 15,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 0,
    opacity: 0,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'none',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingBottom: 25,
    maxHeight: 200,
  },
  link: {
    fontSize: 22,
    color: '#6A5B5D',
    textDecoration: 'none',
    display: 'flex',
    hover: {
      color: '#D07D3C',
    },
  },
  timeWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    paddingBottom: 25,
    header: {
      whiteSpace: 'nowrap',
      color: '#958E8A',
      textTransform: 'uppercase',
      letterSpacing: 1.8,
      marginBottom: 15,
      fontWeight: 500,
      fontSize: 18,
    },
    divider: {
      height: 1,
      backgroundColor: '#A39A93',
      width: '100%',
      marginBottom: 25,
      opacity: 0.5,
    },
    info: {
      marginBottom: 15,
      textAlign: 'center',
      color: '#6A5B5D',
      fontSize: 23,
    },
  },
  mapWrapper: {
    marginLeft: -25,
    marginRight: -25,
  },
}

export default withRouter(ImNewMobile)
