import React from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import withTouchscreenSupport from '../utils/TouchscreenSupport'
import EventFeedEvent from './EventFeedEvent'
import CalendarEventFeedEvent from './CalendarEventFeedEvent'

const EventFeed = ({
  getStyle,
  events,
  title,
  showDateIcon,
  backgroundColor,
}) => {
  const [activeEvent, setActiveEvent] = React.useState(-1)
  const [hoveredEvent, setHoveredEvent] = React.useState(-1)

  const onHover = (index) => {
    setHoveredEvent(index)
  }

  const onLeaveHover = () => {
    setHoveredEvent(-1)
  }

  const expand = (index) => {
    if (index === activeEvent) {
      setActiveEvent(-1)
      setHoveredEvent(-1)
      return
    }
    setActiveEvent(index)
    setHoveredEvent(index)
  }

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: backgroundColor
          ? backgroundColor
          : showDateIcon
          ? '#FFFFFF'
          : '#F8F5EE',
      }}>
      <div style={getStyle(styles.widthRestrict)}>
        {title && <div style={styles.feedTitle}>{title}</div>}
        {events.map((event, index) => {
          return showDateIcon ? (
            <CalendarEventFeedEvent
              key={index}
              event={event}
              index={index}
              onHover={onHover}
              onLeaveHover={onLeaveHover}
              expand={expand}
              activeEvent={activeEvent}
              hoveredEvent={hoveredEvent}
            />
          ) : (
            <EventFeedEvent
              key={index}
              event={event}
              index={index}
              onHover={onHover}
              onLeaveHover={onLeaveHover}
              expand={expand}
              activeEvent={activeEvent}
              hoveredEvent={hoveredEvent}
            />
          )
        })}
      </div>
    </div>
  )
}

const styles = {
  container: {
    width: '100vw',
    backgroundColor: '#F8F5EE',
    paddingBottom: 30,
  },
  widthRestrict: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 70,
    paddingRight: 70,
    maxWidth: 1340,
    lg: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  feedTitle: {
    paddingTop: 45,
    color: '#6A5B5D',
    fontWeight: 300,
    fontSize: 40,
    fontFamily: 'Neutraface Slab Text',
  },
}
export default withTouchscreenSupport(withSizes(EventFeed))
