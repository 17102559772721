import React, { Component } from 'react'

class DateIcon extends Component {
  months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUNE',
    'JULY',
    'AUG',
    'SEPT',
    'OCT',
    'NOV',
    'DEC',
  ]
  formatDate = (date) => {
    const tmpDate = new Date(date)

    if (tmpDate.getHours() < 12) {
      return new Date(tmpDate.getTime() + 43200000)
    }

    return tmpDate
  }
  render() {
    const { date } = this.props
    const { formatDate, months } = this

    const _date = formatDate(date)

    return (
      <div style={styles.container}>
        <div style={styles.month}>{months[_date.getMonth()]}</div>
        <div className={'date-icon-day'} style={styles.day}>
          {_date.getDate()}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#F8F5EE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 75,
    height: 100,
    padding: 10,
    paddingBottom: 0,
    zIndex: 9,
  },
  month: {
    color: '#CE7D43',
    letterSpacing: 3.4,
    fontSize: 20,
  },
  day: {
    fontSize: 60,
    lineHeight: '28px',
    letterSpacing: 4,
    color: '#A39A93',
    paddingTop: 10,
    paddingLeft: 6,
  },
}

export default DateIcon
