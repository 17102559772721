import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withSizes, NavLink } from '@oos-studio/website-common-components'
import deepmerge from 'deepmerge'
import withTouchscreenSupport from '../utils/TouchscreenSupport'
import DateRangeArrow from '../assets/DateRange_Arrow.png'
import { connect } from 'react-redux'

class SermonsSeriesView extends Component {
  state = {
    activeHover: -1,
  }

  handleResize = () => {
    const { mobile } = this.state

    if (window.innerWidth < 1250 && !mobile) {
      this.setState({
        mobile: true,
      })
    }
    if (window.innerWidth >= 1250 && mobile) {
      this.setState({
        mobile: false,
      })
    }
  }
  componentDidMount() {
    const { handleResize } = this

    if (window.innerWidth < 1250) {
      this.setState({
        mobile: true,
      })
    }

    window.addEventListener('resize', handleResize)
  }

  formatDate = (_date) => {
    const date = new Date(_date)
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  }

  onHoverImage = (index) => {
    this.setState({
      activeHover: index,
    })
  }

  onLeaveHoverImage = () => {
    this.setState({
      activeHover: -1,
    })
  }

  render() {
    const {
      channels,
      history,
      getStyle,
      defaultImage,
      touchscreen,
      latestSermons,
    } = this.props
    const { formatDate, onHoverImage, onLeaveHoverImage } = this
    const { activeHover, mobile } = this.state

    const _styles = mobile ? deepmerge(styles, styles.mobile) : styles

    return (
      <div style={getStyle(_styles.container)}>
        {channels.map((channel, index) => {
          const lastSermonDate = formatDate(latestSermons?.videos[0]?.starts_at)
          const startDate = formatDate(channel.startDate)
          const endDate = formatDate(channel.endDate)
          const current = endDate === lastSermonDate
          const url = `sermons/series/${channel.id}`
          return (
            <NavLink
              key={index}
              useTouchEnd={false}
              useRouter={true}
              history={history}
              item={{ url: url }}
              onMouseEnter={touchscreen ? null : () => onHoverImage(index)}
              onMouseLeave={touchscreen ? null : () => onLeaveHoverImage()}
              style={_styles.seriesWrapper}>
              <div
                style={{
                  ..._styles.imgWrapper,
                  transform:
                    activeHover === index ? 'scale(1.025)' : 'scale(1.0)',
                  transition: 'transform 0.5s',
                  overflow: 'hidden',
                }}>
                <img
                  src={channel.content_settings?.preview || defaultImage}
                  alt={'seriesImg'}
                  style={{
                    ...getStyle(_styles.img),
                    transform:
                      activeHover === index ? 'scale(1.0)' : 'scale(1.1)',
                    transition: 'transform 0.5s',
                  }}
                />
              </div>
              <NavLink
                style={{
                  ..._styles.link,
                  color: activeHover === index ? '#CD7D43' : '#A39A93',
                }}
                useRouter={true}
                history={history}
                item={{ url: url }}>
                {channel.name}
              </NavLink>
              <div style={_styles.date}>
                <div>{startDate}</div>
                <img src={DateRangeArrow} style={styles.arrow} alt="arrow" />
                <div style={{ marginLeft: 10 }}>
                  {current ? 'Current' : endDate}
                </div>
              </div>
            </NavLink>
          )
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    maxWidth: 1250,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 40,
    paddingLeft: 25,
    paddingRight: 25,
  },
  seriesWrapper: {
    margin: 0,
    marginTop: 20,
    marginBottom: 15,
    maxWidth: 585,
    width: 'auto',
    fontSize: 20,
    textTransform: 'uppercase',
    padding: 0,
  },
  link: {
    padding: 0,
    paddingTop: 10,
    letterSpacing: 2.2,
  },
  arrow: {
    height: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    height: 330,
  },
  imgWrapper: {},
  date: {
    display: 'flex',
    flexDirection: 'row',
    color: '#6a5b5d',
    fontSize: 18,
    fontWeight: 400,
    textTransform: 'none',
  },
  mobile: {
    container: {
      maxWidth: 800,
      sm: {
        paddingLeft: 35,
        paddingRight: 35,
      },
    },
    seriesWrapper: {
      maxWidth: 'none',
      width: '100%',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    latestSermons: state.sermons?.latestSermons,
  }
}

export default withTouchscreenSupport(
  withRouter(withSizes(connect(mapStateToProps)(SermonsSeriesView))),
)
