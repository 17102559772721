import React, { Component, useEffect, useState } from 'react'
import { Container, Col, Media } from 'reactstrap'
import NavLink from './NavLink'
import mergeStyles from '../utils/StyleMerge'
import deepmerge from 'deepmerge'
import gsap, { TweenLite, Power2, TimelineLite } from 'gsap'

const MegaMenu = ({
  styles,
  useRouter,
  history,
  onClickItem,
  columns,
  animationParams,
  aside,
  renderAside = false,
}) => {
  const [linkHover, setLinkHover] = useState(false)
  const [activeColumn, setActiveColumn] = useState(null)
  const [activeLink, setActiveLink] = useState(null)
  const [opacity, setOpacity] = useState(0)

  const hoverLink = (index, colIndex) => {
    setLinkHover(true)
    setActiveLink(index)
    setActiveColumn(colIndex)
  }

  const leaveHoverLink = (index, colIndex) => {
    setLinkHover(false)
    setActiveLink(null)
    setActiveColumn(null)
  }

  const getColumnStyle = (column) => {
    let columnStyle = styles.defaultColumn
    if ('styles' in column) {
      if ('column' in column.styles) {
        columnStyle = deepmerge(styles.defaultColumn, column.styles.column)
      }
    }
    return columnStyle
  }

  const getLinkStyle = (column) => {
    let linkStyle = styles.defaultLink
    if ('styles' in column) {
      if ('link' in column.styles) {
        linkStyle = deepmerge(styles.defaultLink, column.styles.link)
      }
    }
    return linkStyle
  }

  const getImageStyle = (column) => {
    let imageStyle = styles.defaultImage
    if ('styles' in column) {
      if ('image' in column.styles) {
        imageStyle = deepmerge(styles.defaultImage, column.styles.image)
      }
    }
    return imageStyle
  }

  const getHeaderStyle = (column) => {
    let headerStyle = styles.defaultHeader
    if ('styles' in column) {
      if ('header' in column.styles) {
        headerStyle = deepmerge(styles.defaultHeader, column.styles.header)
      }
    }
    return headerStyle
  }

  useEffect(() => {
    TweenLite.to('.megaMenuContainer', 0.35, animationParams)
    TweenLite.to('.asideHeader', 0.35, animationParams)
    TweenLite.to('.asideBody', 0.35, animationParams)
  }, [])

  const renderHeader = (column, index) => {
    const headerStyle = getHeaderStyle(column)
    return column.heading.length > 0 ? (
      <div style={headerStyle}>{column.heading}</div>
    ) : null
  }

  const renderColumn = (column, index) => {
    const linkStyle = getLinkStyle(column)
    const imageStyle = getImageStyle(column)

    switch (column.type) {
      case 'links':
        return (
          <React.Fragment>
            {renderHeader(column, index)}
            {column.links.map((link, linkIndex) => (
              <NavLink
                key={linkIndex}
                history={history}
                useRouter={
                  link.useRouter !== undefined && link.useRouter !== null
                    ? link.useRouter
                    : useRouter
                }
                onClickItem={onClickItem}
                onMouseEnter={() => hoverLink(linkIndex, index)}
                onMouseLeave={() => leaveHoverLink(linkIndex, index)}
                item={{ url: link.url }}
                style={{
                  ...linkStyle,
                  color:
                    activeLink === linkIndex &&
                    activeColumn === index &&
                    linkHover &&
                    linkStyle.hover
                      ? linkStyle.hover.color
                      : linkStyle.color,
                }}>
                {link.title}
              </NavLink>
            ))}
          </React.Fragment>
        )
      case 'image':
        return (
          <React.Fragment>
            {renderHeader(column, index)}
            {column.overlayText ? (
              <NavLink
                history={history}
                useRouter={
                  column.useRouter !== undefined && column.useRouter !== null
                    ? column.useRouter
                    : useRouter
                }
                onClickItem={onClickItem}
                item={{ url: column.url }}>
                <div style={imageStyle.overlay}>{column.overlayText}</div>
                <Media
                  object
                  src={column.src}
                  alt={column.title}
                  style={imageStyle}
                />
              </NavLink>
            ) : (
              <Media
                object
                src={column.src}
                alt={column.title}
                style={imageStyle}
              />
            )}
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            {renderHeader(column, index)}
            {column.render()}
          </React.Fragment>
        )
    }
  }

  return (
    <div
      className="megaMenuContainer"
      style={{ ...styles.container, maxWidth: renderAside ? 'none' : 777 }}>
      {!!aside && renderAside && (
        <React.Fragment>
          <Media
            style={styles.asideImage}
            object
            src={aside.brand.image.src}
            alt={aside.brand.image.title}
          />
          <div className={'asideContainer'} style={styles.asideWrapper}>
            <div className={'asideHeader'} style={styles.asideHeader}>
              {aside.header}
            </div>
            <div className={'asideBody'} style={styles.asideBody}>
              {aside.text}
            </div>
            <div style={styles.asideDivider} />
          </div>
        </React.Fragment>
      )}
      {columns.map((column, index) => {
        const columnStyle = getColumnStyle(column)
        return (
          <div
            key={index}
            style={{
              ...columnStyle,
            }}>
            {renderColumn(column, index)}
          </div>
        )
      })}
    </div>
  )
}

const defaultStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: 0,
  },
  defaultColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  defaultHeader: {
    fontSize: 25,
  },
  defaultLink: {
    color: 'tan',
  },
  defaultImage: {
    objectFit: 'cover',
    overlay: {
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.5)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  asideWrapper: {
    fontSize: 25,
    width: 210,
    padding: 0,
    zIndex: 5,
    backgroundColor: 'white',
  },
  asideBody: {
    opacity: 0,
    fontSize: 18,
    lineHeight: 2,
    color: '#6A5B5D',
    fontStyle: 'italic',
    textAlign: 'left',
  },
  asideImage: {
    display: 'none',
  },
  asideHeader: {
    display: 'none',
  },
  asideDivider: {
    display: 'none',
  },
  columns: [],
}

MegaMenu.defaultProps = {
  columns: [],
  useRouter: false,
  animationParams: { opacity: 1 },
}

export default mergeStyles(defaultStyles)(MegaMenu)
