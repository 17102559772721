import React, { Component } from 'react'

class VideoAudioSwitch extends Component {
  state = {
    activeToggle: 0,
  }

  toggle = (newType) => {
    const { onSwitch } = this.props
    this.setState({
      activeToggle: newType,
    })
    onSwitch(newType)
  }

  render() {
    const { activeToggle } = this.state
    const { toggle } = this

    return (
      <div style={styles.container}>
        <div
          onClick={() => {
            toggle(0)
          }}
          style={{
            ...styles.video,
            color: activeToggle === 0 ? '#F8F5EE' : '#6A5B5D',
            backgroundColor: activeToggle === 0 ? '#6A5B5D' : '#F8F5EE',
          }}>
          Video
        </div>
        <div
          onClick={() => {
            toggle(1)
          }}
          style={{
            ...styles.audio,
            color: activeToggle === 1 ? '#F8F5EE' : '#6A5B5D',
            backgroundColor: activeToggle === 1 ? '#6A5B5D' : '#F8F5EE',
          }}>
          Audio
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: 140,
    borderColor: '#6A5B5D',
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    padding: 5,
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 19,
    cursor: 'pointer',
    flex: 1,
  },
  audio: {
    flex: 1,
    padding: 5,
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 19,
    cursor: 'pointer',
  },
}

export default VideoAudioSwitch
