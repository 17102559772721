import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPassageFilterValue,
  setSelectedBook,
  setSelectedChapter,
  setShowBooks,
  getLatestSermons, setSelectedSpeaker, setDayFilterValue,
} from './actions'
import { useHover } from '../hooks/useHover'

const PassageFilterBook = (props) => {
  const [hoverRef, isHovered] = useHover()
  const [allHoverRef, isAllHovered] = useHover()
  const [activeChapterHover, setActiveChapterHover] = useState(-1)

  const onHoverChapter = (index) => {
    setActiveChapterHover(index)
  }

  const onLeaveHoverChapter = () => {
    setActiveChapterHover(-1)
  }

  const dispatch = useDispatch()
  const state = useSelector((state) => state.sermons)
  const showChapters = state.selectedBook === props.book.book

  const selectBook = () => {
    if (showChapters) {
      dispatch(setSelectedBook(null))
    } else {
      dispatch(setSelectedBook(props.book.book))
    }
  }

  const selectWholeBook = () => {
    dispatch(setSelectedChapter(null))
    dispatch(setSelectedBook(props.book.book))
    dispatch(setPassageFilterValue(`${state.selectedBook}`))
    dispatch(setShowBooks(false))
    dispatch(setDayFilterValue(null))

    dispatch(
      getLatestSermons(
        10,
        true,
        state.selectedBook,
        null,
        state.selectedSpeaker,
      ),
    )
  }

  const selectChapter = async (chapter) => {
    dispatch(setSelectedChapter(chapter))
    dispatch(setSelectedBook(null))
    dispatch(setPassageFilterValue(`${state.selectedBook} ${chapter}`))
    dispatch(setShowBooks(false))
    dispatch(setDayFilterValue(null))

    dispatch(
      getLatestSermons(
        10,
        true,
        state.selectedBook,
        chapter,
        state.selectedSpeaker,
      ),
    )
  }

  return (
    <div ref={hoverRef} style={styles.container}>
      <div
        onClick={selectBook}
        style={{
          ...styles.name,
          backgroundColor: showChapters
            ? '#FDECBB'
            : isHovered
            ? '#a39a9326'
            : 'transparent',
        }}>
        {props.book.book}
      </div>
      {showChapters && (
        <div style={styles.chapterWrapper}>
          <div
            onClick={selectWholeBook}
            ref={allHoverRef}
            style={{
              ...styles.chapter,
              backgroundColor: isAllHovered ? '#a39a9326' : 'transparent',
            }}>
            All
          </div>
          {props.book.chapters.map((chapter, index) => {
            return (
              <div
                onMouseEnter={() => onHoverChapter(index)}
                onMouseLeave={onLeaveHoverChapter}
                onClick={() => selectChapter(index + 1)}
                style={{
                  ...styles.chapter,
                  backgroundColor:
                    activeChapterHover === index ? '#a39a9326' : 'transparent',
                }}
                key={index}>
                {index + 1}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
  },
  name: {
    fontSize: 18,
    color: '#6A5B5D',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  chapterWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    justifyItems: 'center',
    gridGap: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    cursor: 'default',
  },
  chapter: {
    color: '#6A5B5D',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
  },
}

export default PassageFilterBook
