export const styles = {
  button: {
    backgroundColor: 'transparent',
    color: '#6A5B5D',
    borderColor: '#6A5B5D',
    borderRadius: 0,
    borderWidth: 1,
    letterSpacing: 3.4,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 20,
    paddingRight: 20,
    navLink: {
      color: '#6A5B5D',
      paddingTop: 0,
      paddingBottom: 0,
    },
    hovered: {
      textDecoration: 'none',
      navLink: {
        color: '#FAA860',
      },
      backgroundColor: '#6A5B5D',
      color: '#FAA860',
      borderColor: '#6A5B5D',
      borderRadius: 0,
      borderWidth: 1,
      letterSpacing: 3.4,
    },
  },
}
