import React, { useEffect, useRef, useState } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSizes } from '@oos-studio/website-common-components'
import { useHistory } from 'react-router-dom'
import {
  Collapse,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import NavLink from '../common/NavLink'
import deepmerge from 'deepmerge'
import { mapNavItems } from '../utils/ParseNavigationData'
import { useSelector } from 'react-redux'

const NavBarMinified = ({ gql, getStyle, show }) => {
  const { navItems } = gql

  const [open, setOpen] = useState(false)
  const [megaMenu, setMegaMenu] = useState(null)
  const [aside, setAside] = useState(null)
  const [megaMenuOpen, setMegaMenuOpen] = useState(false)
  const [activeHoveredLink, setActiveHoveredLink] = useState(null)
  const [activeHoveredDropdown, setActiveHoveredDropdown] = useState(null)
  const [mmBackgroundHeight, setMmBackgroundHeight] = useState(135)
  const [data, setData] = useState(null)
  const [navBorderWidth, setNavBorderWidth] = useState([0, 0, 0])
  const [navBorderStyle, setNavBorderStyle] = useState(['', '', ''])

  const history = useHistory()

  let menus = []
  let dropdownCounter = useRef(0)

  useEffect(() => {
    const unlisten = history.listen(() => {
      hideMegaMenu()
    })

    window.addEventListener('resize', onResize)
    window.addEventListener('click', handleOutsideClick)
    window.addEventListener('touchend', handleOutsideClick)

    return () => {
      unlisten()
      window.removeEventListener('resize', onResize)
      window.removeEventListener('click', handleOutsideClick)
      window.removeEventListener('touchend', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    onResize()
  }, [megaMenuOpen])

  useEffect(() => {
    if (gql?.navItems) {
      const items = mapNavItems(navItems, history, getStyle, true)
      const _data = {
        navigation: {
          brand: {
            url: '/',
            image: {
              title: 'Providence Logo',
              src: require('../assets/ProvidenceLogoMini.png'),
            },
          },
          dropdownIcon: require('../assets/DropdownCaret.png'),
          hoveredIcon: require('../assets/DropdownCaret_hover.png'),
          items: items,
        },
      }
      setData(_data)
    }
  }, [gql?.navItems])

  const onResize = () => {
    const mmHeight = document.getElementById('megaMenuMini')?.offsetHeight
    if (mmHeight && mmHeight !== mmBackgroundHeight) {
      setMmBackgroundHeight(mmHeight + 100)
    }
  }

  const handleOutsideClick = (e) => {
    let touchY = null
    let navBottomY = null
    if (e.changedTouches?.length) {
      touchY = e.changedTouches[e.changedTouches.length - 1].clientY
    }
    const mmBackground = document.getElementById('mmBackgroundMini')
    if (mmBackground) {
      navBottomY = mmBackground.offsetTop + mmBackground.offsetHeight
    }
    if (touchY > navBottomY) {
      hideMegaMenu()
    }
  }

  const showMegaMenu = async (key) => {
    if (activeHoveredDropdown === key) {
      return
    }

    await hideMegaMenu()
    const item = menus.filter((obj) => {
      return obj?.item?.text === key
    })

    let borderWidth = [0, 0, 0]
    borderWidth[key] = 2

    let borderStyle = ['', '', '']
    borderStyle[key] = 'solid'

    dropdownCounter.current = 0
    setAside(item[0].item.aside)
    setMegaMenu(item[0].item.render(item[0].item.aside))
    setMegaMenuOpen(true)
    setActiveHoveredDropdown(key)
    setNavBorderWidth(borderWidth)
    setNavBorderStyle(borderStyle)
  }

  useEffect(() => {
    console.log('DROPDOWN: ', activeHoveredDropdown)
  }, [activeHoveredDropdown])

  const hideMegaMenu = (index = null) => {
    setMegaMenu(null)
    setActiveHoveredLink(index)
    setActiveHoveredDropdown(null)
    setAside(null)
    setNavBorderStyle(['', '', ''])
    setNavBorderWidth([0, 0, 0])
    setMegaMenuOpen(false)
  }

  const unHoverLink = () => {
    setActiveHoveredLink(null)
  }

  const toggle = () => {
    setOpen(!open)
  }

  const onClickItem = () => {}

  const onBrandClick = (e) => {
    if (history.location.pathname !== '/') {
      e.preventDefault()
      history.push('/')
    }
  }

  const renderNavigationItems = (item, index) => {
    let navItem = null
    let key = 0

    switch (item.type) {
      case 'link':
        navItem = (
          <NavItem key={index} key={index} style={styles.navItem}>
            <NavLink
              onMouseEnter={() => hideMegaMenu(index)}
              onMouseLeave={() => unHoverLink()}
              history={history}
              useRouter={item.useRouter ? item.useRouter : false}
              onClickItem={onClickItem}
              item={item}
              href={item.url}
              style={{
                ...styles.navLink,
                color:
                  activeHoveredLink === index
                    ? styles.navLink.hovered.color
                    : megaMenuOpen
                    ? styles.mmOpen.navLink.color
                    : styles.navLink.color,
              }}>
              {item.text}
            </NavLink>
          </NavItem>
        )
        break
      case 'dropdown':
        dropdownCounter.current = dropdownCounter.current + 1
        key = item.text
        menus.push({ index: key, item: item })
        navItem = (
          <NavLink
            key={index}
            history={history}
            useRouter={true}
            onClickItem={onClickItem}
            item={item}
            dropdown>
            <DropdownToggle
              style={{
                borderBottomWidth: navBorderWidth[key],
                borderBottomStyle: navBorderStyle[key],
                ...styles.dropdownItem,
                padding:
                  activeHoveredDropdown === key ? '10px 0px 8px' : '10px 0px',
                color:
                  activeHoveredDropdown === key
                    ? styles.dropdownItem.hovered.color
                    : megaMenuOpen
                    ? styles.mmOpen.dropdownItem.color
                    : styles.dropdownItem.color,
              }}
              onMouseEnter={() => showMegaMenu(key)}
              onTouchEnd={() => showMegaMenu(key)}
              onMouseLeave={() => unHoverLink()}
              nav>
              {item.text}
              <i
                style={
                  activeHoveredDropdown === key
                    ? deepmerge(
                        styles.dropdownArrow,
                        styles.dropdownArrow.hovered,
                      )
                    : styles.dropdownArrow
                }
              />
            </DropdownToggle>
          </NavLink>
        )
        break
      default:
        break
    }

    return navItem
  }

  const brand = data?.navigation?.brand

  return data ? (
    <div
      id={'navbarContainerMinified'}
      style={{
        ...styles.container,
        top: show ? 0 : -100,
        transition: 'top 0.5s',
      }}
      onMouseLeave={() => hideMegaMenu()}>
      <div
        id={'mmBackgroundMini'}
        style={
          megaMenuOpen
            ? {
                ...deepmerge(styles.mmBackground, styles.mmOpen.mmBackground),
                height: mmBackgroundHeight,
              }
            : { ...styles.mmBackground, height: mmBackgroundHeight }
        }
      />
      <Navbar
        expand="sm"
        color={
          megaMenuOpen
            ? styles.mmOpen.navbar.backgroundColor
            : styles.navbar.backgroundColor
        }
        style={
          megaMenuOpen
            ? deepmerge(styles.navbar, styles.mmOpen.navbar)
            : styles.navbar
        }>
        <NavbarBrand
          onClick={(e) => onBrandClick(e)}
          href="/"
          style={styles.brand}>
          <Media
            object
            src={brand.image.src}
            alt={brand.image.title}
            style={styles.brandImage}
          />
          <NavbarText style={styles.brandTitle}>{brand.title}</NavbarText>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={styles.toggler} />
        <Collapse isOpen={open} navbar style={styles.collapse}>
          <Nav
            id="nav"
            navbar-expand-lg
            navbar
            style={
              megaMenuOpen
                ? deepmerge(styles.nav, styles.mmOpen.nav)
                : styles.nav
            }>
            <div
              style={
                megaMenuOpen
                  ? deepmerge(styles.itemWrapper, styles.mmOpen.itemWrapper)
                  : styles.itemWrapper
              }>
              {data.navigation.items.map((item, index) =>
                renderNavigationItems(item, index),
              )}
            </div>
            <div
              id={'megaMenuMini'}
              style={
                megaMenuOpen
                  ? deepmerge(styles.megaMenu, styles.mmOpen.megaMenu)
                  : styles.megaMenu
              }>
              {megaMenu}
            </div>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  ) : null
}

const styles = {
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'white',
    paddingLeft: 30,
    zIndex: 10000,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(106,91,93, 0.5)',
  },
  mmOpen: {
    itemWrapper: {},
    megaMenu: {
      opacity: 1,
    },
    navbar: {
      backgroundColor: 'white',
    },
    nav: {
      borderBottomWidth: 0,
      marginBottom: -2,
    },
    navLink: {
      color: '#6A5B5D',
    },
    dropdownItem: {
      color: '#6A5B5D',
      padding: '8px 0px',
    },
    mmBackground: {
      opacity: 1,
      height: 465,
      borderBottomWidth: 1,
    },
  },
  mmBackground: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: -10,
    right: -10,
    height: 135,
    transition: 'height 0.15s ease-out, opacity 0.25s',
    zIndex: 13,
    pointerEvents: 'none',
    backgroundColor: 'white',
    borderBottomWidth: 0,
    borderBottomOpacity: 0.5,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(106,91,93, 0.5)',
  },
  navbar: {
    padding: 0,
    maxWidth: 1230,
    display: 'flex',
    alignItems: 'center',
    height: 75,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 14,
  },
  brand: {
    height: 50,
    width: 50,
    padding: 0,
    margin: 0,
    marginRight: 45,
  },
  itemWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  brandImage: {
    height: '100%',
    width: '100%',
  },
  collapse: {
    fontSize: 25,
    width: 806,
  },
  nav: {},
  navItem: {
    display: 'block',
  },
  navLink: {
    padding: '10px 0px',
    marginRight: 60,
    color: '#6A5B5D',
    textDecoration: 'none',
    hovered: {
      color: '#CD7D43',
    },
  },
  dropdownMenuContainer: {},
  dropdownContainer: {},
  dropdownItem: {
    marginRight: 60,
    padding: '10px 0px',
    color: '#6A5B5D',
    borderBottomColor: '#CD7D43',
    borderBottomWidth: 4,
    hovered: {
      color: '#CD7D43',
    },
  },
  asideWrapper: {
    position: 'absolute',
    top: 30,
    left: 92,
    fontSize: 25,
    width: 200,
    padding: 0,
    zIndex: 5,
    backgroundColor: 'white',
  },
  asideBody: {
    opacity: 0,
    fontSize: 18,
    lineHeight: 2,
    color: '#6A5B5D',
    fontStyle: 'italic',
    textAlign: 'left',
  },
  asideImage: {
    display: 'none',
  },
  asideHeader: {
    display: 'none',
  },
  asideDivider: {
    display: 'none',
  },
  megaMenu: {
    maxWidth: 1108,
    top: 65,
    left: 95,
    right: 0,
    zIndex: 4,
    marginRight: 30,
    position: 'absolute',
    transition: 'opacity 0.25s ease-in',
    opacity: 0,
  },
  dropdownArrow: {
    borderColor: '#6A5B5D',
    borderStyle: 'solid',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(45deg)',
    marginBottom: 3,
    marginLeft: 7,
    hovered: {
      borderColor: '#CD7D43',
    },
  },
}

export default gqlConnect('navigation')(withSizes(NavBarMinified))
