import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import { withRouter } from 'react-router-dom'
import RectButton from '../common/RectButton'

class HomepageMapImage extends Component {
  render() {
    const { map, buttonText, url, isEntry, getStyle } = this.props

    return (
      <div style={getStyle(styles.container)}>
        <img alt={'map'} src={map} style={styles.image} />
        <div style={getStyle(styles.buttonWrapper)}>
          <RectButton
            light
            hoverLight
            external={!isEntry}
            url={url}
            text={buttonText}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100vw',
    height: 550,
    position: 'relative',
    xxs: {
      justifyContent: 'center',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: -1,
  },
  buttonWrapper: {
    maxWidth: 1275,
    width: '100%',
    paddingTop: 60,
    paddingRight: 75,
    paddingLeft: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    sm: {
      paddingRight: 30,
      paddingLeft: 30,
    },
  },
}

export default withRouter(withSizes(HomepageMapImage))
