import React, { Component } from 'react'
import {
  Button as ButtonCommon,
  CardGrid,
  withSizes,
} from '@oos-studio/website-common-components'
import Button from '../common/Button'
import { styles as globalStyles } from '../styles'
import { getLatestPost } from '../utils/InstagramHandler'
import { gqlConnect } from 'react-connect-graphql-beta'
import PlayButton from '../common/PlayButton'
import { getBroadcast, getVideosFromChannel } from '../utils/BoxcastHandler'
import config from '../config'
import RectButton from '../common/RectButton'
import truncateText from '../utils/truncateText'

const styles = {
  container: {
    backgroundColor: '#F8F5EE',
    paddingBottom: 50,
  },
  card: {
    backgroundColor: 'white',
    color: '#6A5B5D',
    paddingBottom: 30,
  },
  image: {
    height: 250,
    objectFit: 'cover',
  },
  separator: {
    borderColor: '#6A5B5D',
  },
  header: {
    color: '#D07D3C',
    letterSpacing: 3.4,
  },
  wrapper: {
    maxWidth: 1350,
  },
  arrow: {
    hovered: {},
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: 17,
  },
  row: {
    marginLeft: 55,
    marginRight: 55,
    xs: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
}

class HomeFeed extends Component {
  state = {
    instagramPost: null,
    instagramMediaRef: null,
    blogPost: null,
    sermon: null,
    playButtonHovered: false,
    activeCards: [],
  }
  async componentDidMount() {
    const token = this.props.gql.data.globalSet.instagramData[0].accessToken

    const blogPost = await this.getBlogPost()
    let instagramPost = null
    try {
      instagramPost = await this.getInstagramPost(token)
    } catch (e) {
      console.log('ERROR GETTING INSTAGRAM POST: ', e)
    }
    let sermon = null
    try {
      sermon = await this.getSermon()
    } catch (e) {
      console.log('ERROR GETTING SERMON: ', e)
    }

    this.setState({
      sermon: sermon,
      blogPost: blogPost,
      instagramPost: instagramPost,
    })

    const instagramVideo = document.getElementById('instagram-video')

    if (instagramVideo) {
      instagramVideo.play()
    }
  }

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  formatDate = (_date) => {
    const { months } = this

    const date = new Date(_date)
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  getInstagramPost = async (token) => {
    const post = await getLatestPost(token)
    const caption = truncateText(post.caption, 230)

    const card = {
      header: 'INSTAGRAM',
      image: {
        title: 'igPic',
        src: post?.image,
        tag: post?.type === 'VIDEO' ? 'video' : 'img',
        id: post?.type === 'VIDEO' ? 'instagram-video' : undefined,
        loop: true,
        controls: true,
      },
      title: post?.username,
      subtitles: [],
      text: `<p style="margin-bottom: 0px;">${caption}</p>`,
      button: {
        text: 'VIEW MORE',
        url: 'https://www.instagram.com/providencechurch/',
        render: () => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <RectButton
                url={'https://www.instagram.com/providencechurch/'}
                external
                newTab
                text={'View More'}
              />
            </div>
          )
        },
      },
    }

    if (post?.image) {
      this.setState({
        activeCards: [...this.state.activeCards, card],
      })
    }
  }

  getBlogPost = () => {
    const { data } = this.props.gql

    const entry = data.entries[0]

    const postDate = this.formatDate(new Date(entry.postDate))

    let firstParagraph
    let firstBodyEntry = null

    firstBodyEntry = entry.body.find((b) => b.__typename.includes('bodyText'))
      ?.text

    if (firstBodyEntry.includes('<p>')) {
      firstParagraph = firstBodyEntry
        .substring(
          firstBodyEntry.indexOf('<p>') + 3,
          firstBodyEntry.indexOf('</p>'),
        )
        .substring(0, 190)
    } else {
      firstParagraph = firstBodyEntry.substring(0, 190)
    }

    if (firstParagraph[firstParagraph.length - 1] !== '.') {
      firstParagraph += '...'
    }

    firstParagraph = firstParagraph.replace(/(<([^>]+)>)/gi, '')

    const preview = truncateText(
      entry.postPreview
        ? entry.postPreview
        : firstParagraph
        ? firstParagraph
        : '',
      230,
    )

    const card = {
      header: 'BLOG',
      image: {
        title: 'blogPic',
        src: entry.heroImage[0].url,
      },
      title: entry.title,
      subtitles: [`${postDate} | ${entry.author.name}`],
      text: preview,
      button: {
        text: 'READ MORE',
        url: 'https://www.oos-studio.com',
        render: () => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}>
              <RectButton
                url={`blog/post/${entry.slug}`}
                external
                text={'Read More'}
              />
            </div>
          )
        },
      },
    }
    if (card.title) {
      this.setState({
        activeCards: [...this.state.activeCards, card],
      })
    }
  }

  getSermon = async () => {
    const { formatDate } = this

    const latest = await getVideosFromChannel(config.boxcast.channelId, 1)
    const latestInfo = await getBroadcast(latest.videos[0].id)
    const description = truncateText(latestInfo?.description, 230)
    const speaker = latestInfo.content_settings?.podcast_summary || ''
    const passage = latestInfo.content_settings?.podcast_subtitle || ''
    const topSubtitle =
      speaker !== ''
        ? `${formatDate(latestInfo.starts_at)} | ${speaker}`
        : formatDate(latestInfo.starts_at)
    const card = {
      header: 'SERMONS',
      image: {
        title: 'latestSermon',
        src: latestInfo.preview,
      },
      title: latestInfo.name,
      subtitles: [topSubtitle, `${passage}`],
      text: description,
      button: {
        text: '',
        url: null,
        render: () => {
          return (
            <div
              style={{
                width: 45,
                height: 45,
                borderRadius: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <PlayButton item={{ url: `sermons/player/${latestInfo.id}` }} />
            </div>
          )
        },
      },
    }
    if (card.title) {
      this.setState({
        activeCards: [...this.state.activeCards, card],
      })
    }
  }

  render() {
    const { getStyle } = this.props
    const { blogPost, instagramPost, sermon, activeCards } = this.state

    return (
      activeCards.length > 0 && (
        <CardGrid
          styles={{
            button: globalStyles.button,
            ...styles,
            row: getStyle(styles.row),
            wrapper: getStyle(styles.wrapper),
          }}
          cards={activeCards}
        />
      )
    )
  }
}

export default gqlConnect('homeFeed')(withSizes(HomeFeed))
