import React, { useEffect, useRef, useState } from 'react'
import XIconLight from '../assets/XIconLight.png'
import XIconDark from '../assets/XIconDark.png'
import DownChevronLight from '../assets/DownChevronLight.png'
import DownChevronDark from '../assets/DownChevronDark.png'
import { useHover } from '../hooks/useHover'
import { withSizes } from '@oos-studio/website-common-components'

const Dropdown = ({
  title,
  options,
  defaultOptionIndex,
  placeholder,
  onSelectOption,
  getStyle,
}) => {
  let ref = useRef(null)

  const [hoveredOption, setHoveredOption] = useState(null)
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    defaultOptionIndex || null,
  )

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (e) => {
    console.log('handleOutsideClick')
    if (ref?.current?.contains(e.target)) {
      console.log('handleOutsideClick - return')
      return
    }

    if (!showOptions) {
      console.log('handleOutsideClick - setShowOptions(false)')
      setShowOptions(false)
    }
  }

  const onClick = () => {
    console.log('onClick')
    setShowOptions(!showOptions)
  }

  const handleSelectOption = (index) => {
    setSelectedOption(index)
    onSelectOption(index)
    setShowOptions(false)
  }

  return !options.length ? null : (
    <div ref={ref} style={getStyle(styles.container)}>
      <div
        onClick={onClick}
        style={{
          ...styles.heading,
          color: showOptions ? '#F8F5EE' : '#6A5B5D',
          backgroundColor: showOptions ? '#6A5B5D' : 'transparent',
          borderColor: showOptions ? '#6A5B5D' : '#6A5B5D',
        }}>
        <div style={styles.headingTitle}>
          {selectedOption ? options[selectedOption] : placeholder || options[0]}
        </div>
        <div style={styles.arrowWrapper}>
          <img
            src={showOptions ? DownChevronLight : DownChevronDark}
            style={{
              ...styles.arrowImage,
              transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </div>
      </div>
      {showOptions && (
        <div style={styles.listWrapper}>
          {options.map((option, index) => {
            return (
              <div
                key={index}
                onMouseEnter={() => setHoveredOption(index)}
                onMouseLeave={() => setHoveredOption(null)}
                onClick={() => handleSelectOption(index)}
                style={{
                  ...styles.optionWrapper,
                  backgroundColor:
                    hoveredOption === index ? '#a39a9333' : 'transparent',
                }}>
                {option}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    maxWidth: 200,
    position: 'relative',
    cursor: 'pointer',
    marginTop: 25,
    marginBottom: 25,
    zIndex: 11,
    flex: 1,
    marginRight: 15,
    xs: {
      maxWidth: 'none',
      width: '100%',
      marginRight: 0,
      marginTop: 15,
      marginBottom: 0,
    },
  },
  heading: {
    fontSize: 18,
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#6A5B5D',
    display: 'inline-flex',
  },
  headingTitle: {
    padding: 10,
    paddingLeft: 15,
    flex: 1,
  },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    maxHeight: 500,
    borderStyle: 'solid',
    borderColor: '#6A5B5D',
    borderWidth: 1,
    borderTopWidth: 0,
    backgroundColor: '#FFFFFF',
  },
  arrowWrapper: {
    padding: 10,
  },
  arrowImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  xImage: {
    height: 12,
    width: 12,
    marginRight: 5,
  },
  clearFilter: {
    padding: 10,
    paddingLeft: 15,
  },
  optionWrapper: {
    width: '100%',
    fontSize: 18,
    color: '#6A5B5D',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
}

export default withSizes(Dropdown)
