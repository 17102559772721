import React, { Component } from 'react'
import { Media } from 'reactstrap'
import { withSizes } from '@oos-studio/website-common-components'

class FooterInfo extends Component {
  state = {
    breakInfoText: false,
  }

  componentDidMount() {
    const { handleResize } = this

    window.addEventListener('resize', handleResize)

    if (window.innerWidth < 1114) {
      this.setState({
        breakInfoText: true,
      })
    }
  }

  handleResize = () => {
    const { breakInfoText } = this.state

    if (window.innerWidth < 1114 && !breakInfoText) {
      this.setState({
        breakInfoText: true,
      })
    }
    if (window.innerWidth >= 1114 && breakInfoText) {
      this.setState({
        breakInfoText: false,
      })
    }
  }

  render() {
    const { getStyle, xs, data, socials } = this.props
    const { breakInfoText } = this.state

    const styles = getStyle(_styles)

    return (
      <div style={styles.container}>
        <div style={styles.topSection}>
          <div style={styles.logoWrapper}>
            <Media object src={data.logo[0]?.url} style={styles.logo} />
          </div>
          <div style={styles.contact}>
            <div>
              <span style={{ whiteSpace: 'nowrap' }}>{data.address}</span>
              <br />
              <a
                style={{
                  color: '#FCEBBE',
                  display: 'inline',
                }}
                href={`tel:${data.phoneNumber.split('.').join('')}`}>
                {data.phoneNumber}
              </a>
              {xs ? <br /> : breakInfoText ? '' : ' | '}
              <a
                style={{
                  color: '#FCEBBE',
                  display: breakInfoText ? 'block' : 'inline',
                }}
                href={`mailto:${data.email}`}>
                {data.email}
              </a>
            </div>
            <div style={styles.login}>
              <a
                /* eslint-disable-next-line react/jsx-no-target-blank */
                target={'_blank'}
                style={{ color: '#FCEBBE' }}
                href={data.providenceConnect[1]?.connectLink}>
                {data.providenceConnect[0]?.text}
              </a>
            </div>
          </div>
        </div>
        <div style={styles.divider} />
        <div style={styles.bottomSection}>
          <div
            style={styles.info}
            className={'affiliation'}
            dangerouslySetInnerHTML={{ __html: data.affiliation }}
          />
          <div
            style={{
              ...styles.social,
              width: socials.length * 50,
            }}>
            {socials?.map((s) => {
              return (
                <React.Fragment>
                  <a /* eslint-disable-next-line react/jsx-no-target-blank */
                    target={'_blank'}
                    href={s.url}>
                    <Media
                      object
                      src={s.icon}
                      alt={s.name}
                      style={{ width: 30, height: 30 }}
                    />
                  </a>
                </React.Fragment>
              )
            })}
          </div>
          <div style={styles.legal}>{data.copyright}</div>
        </div>
      </div>
    )
  }
}

const _styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    fontSize: 18,
    paddingRight: 0,
    color: '#F8F5EE',
    maxWidth: 660,
  },
  topSection: {
    borderBottomWidth: 0,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 60,
  },
  logoWrapper: {
    marginRight: 'auto',
  },
  logo: {
    width: 250,
  },
  contact: {
    paddingLeft: 10,
    alignSelf: 'flex-end',
    paddingBottom: 15,
    //whiteSpace: 'nowrap',
  },
  login: {
    marginTop: 25,
    whiteSpace: 'nowrap',
  },
  divider: {
    backgroundColor: '#F8F5EE',
    width: '100%',
    height: 2,
  },
  bottomSection: {
    marginTop: 60,
    // flex: 1,
    maxHeight: 260,
    fontSize: 18,
  },
  info: {
    marginBottom: 90,
    height: 55,
  },
  social: {
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  legal: {
    fontSize: 17,
    color: '#A39A93',
    letterSpacing: 2,
  },
  md: {
    container: {
      paddingRight: 0,
      paddingTop: 20,
      paddingBottom: 35,
      maxWidth: 'none',
    },
    topSection: {
      flexDirection: 'column',
      paddingRight: 0,
      paddingBottom: 15,
    },
    logoWrapper: {
      marginRight: 0,
    },
    contact: {
      paddingLeft: 0,
      alignSelf: 'center',
      textAlign: 'center',
      marginTop: 50,
    },
    bottomSection: {
      marginTop: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxHeight: 'none',
      flex: 1,
    },
    info: {
      marginBottom: 50,
      textAlign: 'center',
      height: 'auto',
    },
    social: {
      marginBottom: 10,
    },
    legal: {
      textAlign: 'center',
      whiteSpace: 'wrap',
    },
  },
}

export default withSizes(FooterInfo)
