export const _title = 'Providence Church'
export const _description = 'Providence Church in Avon, OH'
export const _image =
  'https://s3.us-east-2.amazonaws.com/providencechurch.us/assets/Footer_Logo.png'
export const _keywords =
  'Church, Providence, Ohio, Avon, Evangelical, Worship, Ministry'
const _imageProperties = {
  width: 1920,
  height: 1080,
}

export const parseKeywords = (keywords) => {
  let keywordString = ''
  keywords.forEach((key, index) => {
    if (index < keywords.length - 1) {
      keywordString += `${key.keywordTitle},`
    } else {
      keywordString += key.keywordTitle
    }
  })
  return keywordString
}

export const getImageProperties = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      const imageProperties = {
        height: image.height,
        width: image.width,
      }
      resolve(imageProperties)
    }
    image.error = reject
    image.src = src
  })
}

export const getMeta = (
  title = _title,
  description = _description,
  image = _image,
  keywords = _keywords,
  imageProperties = _imageProperties,
) => {
  if (!imageProperties) {
    imageProperties = _imageProperties
  }
  return {
    title,
    description,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: keywords,
      },
      itemProp: {
        title,
        description,
        image,
      },
      property: {
        'og:title': title,
        'og:type': 'website',
        'og:image': image,
        'og:image:height': imageProperties.height,
        'og:image:width': imageProperties.width,
        'og:site_name': 'Providence Church',
        'og:description': description,
        'og:url': window.location.href,
        'fb:app_id': '781322739028383',
      },
    },
    auto: {
      ograph: true,
    },
  }
}
