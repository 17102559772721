import React from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import withTouchscreenSupport from '../utils/TouchscreenSupport'

const EventFeedEvent = ({
  event,
  sm,
  getStyle,
  index,
  touchscreen,
  expand,
  onLeaveHover,
  onHover,
  hoveredEvent,
  activeEvent,
}) => {
  return (
    <div
      onMouseEnter={touchscreen ? null : () => onHover(index)}
      onMouseLeave={touchscreen ? null : () => onLeaveHover()}
      onClick={() => (event.eventDescription ? expand(index) : null)}
      className={'expand-event-container'}
      key={index}
      style={{
        ...styles.eventContainer,
        cursor: event.eventDescription ? 'pointer' : 'default',
      }}>
      <div style={styles.eventTitle}>{event.eventTitle}</div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div style={getStyle(styles.divider)} />
        <div className={'expand-event'}>
          <div
            style={{
              ...getStyle(styles.verticalPlus),
              backgroundColor: hoveredEvent === index ? '#FAA860' : '#6A5B5D',
              display: activeEvent === index ? 'none' : 'block',
            }}
          />
          <div
            style={{
              ...getStyle(styles.horizontalPlus),
              backgroundColor: hoveredEvent === index ? '#FAA860' : '#6A5B5D',
            }}
          />
        </div>
      </div>
      <div>
        {event.eventDate && (
          <div style={{ display: sm ? 'block' : 'inline' }}>
            <span style={styles.detailType}>WHEN:</span>
            <span style={styles.detail}>{event.eventDate}</span>
          </div>
        )}
        {event.targetGroup && (
          <div style={{ display: sm ? 'block' : 'inline' }}>
            <span
              style={{
                ...styles.detailType,
                marginLeft: sm ? 0 : 10,
                paddingLeft: sm ? 0 : 10,
                borderLeftStyle: 'solid',
                borderLeftWidth: sm ? 0 : 1,
                borderLeftColor: '#6A5B5D',
              }}>
              WHO:
            </span>
            <span style={styles.detail}>{event.targetGroup}</span>
          </div>
        )}
      </div>
      <div
        style={{
          ...styles.eventDescription,
          height: activeEvent === index ? 100 : 1,
          opacity: activeEvent === index ? 1 : 0,
        }}>
        {activeEvent === index && event.eventDescription}
      </div>
    </div>
  )
}

const styles = {
  eventContainer: {
    fontFamily: 'Neutraface 2 Text',
    marginTop: 45,
    marginBottom: 45,
  },
  eventTitle: {
    color: '#65A3BE',
    fontSize: 22,
    letterSpacing: 2.6,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    height: 1,
    maxWidth: 'none',
    opacity: 0.5,
    backgroundColor: '#A39A93',
    marginBottom: 15,
    marginTop: 15,
    xxs: {
      maxWidth: 200,
    },
  },
  detailType: {
    color: '#A39A93',
    letterSpacing: 1.8,
    fontWeight: 500,
  },
  detail: {
    color: '#6A5B5D',
    marginLeft: 3,
    fontWeight: 400,
    letterSpacing: 0,
  },
  eventDescription: {
    color: '#6A5B5D',
    fontWeight: 400,
    letterSpacing: 0,
    transition: 'all 0.25s',
    marginTop: 25,
  },
  verticalPlus: {
    width: 1,
    height: 16,
    left: 15,
    position: 'absolute',
    top: 8,
    transition: 'background-color 0.25s',
    sm: {
      height: 13,
      left: 10,
      top: 5,
    },
  },
  horizontalPlus: {
    width: 15,
    height: 1,
    left: 8,
    position: 'absolute',
    top: 16,
    transition: 'background-color 0.25s',
    sm: {
      width: 13,
      left: 4,
      top: 11,
    },
  },
}
export default withTouchscreenSupport(withSizes(EventFeedEvent))
