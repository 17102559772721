export const formatStyledText = (text) => {
  if (text.includes('[') && text.includes(']')) {
    let currentString = ''
    let results = []
    text.split('').forEach((c) => {
      if (c === '[') {
        if (currentString.length > 0) {
          results.push({ text: currentString, italic: false })
        }
        currentString = ''
      } else if (c === ']') {
        results.push({ text: currentString, italic: true })
        currentString = ''
      } else {
        currentString += c
      }
    })
    if (currentString.length > 0) {
      results.push({ text: currentString, italic: false })
    }
    return results
  } else {
    return [{ text }]
  }
}
