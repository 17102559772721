import React, { Component } from 'react'
import { NavLink } from '@oos-studio/website-common-components'
import gsap, { TweenLite, Power2, TimelineLite } from 'gsap'
import '../index.css'
import { withRouter } from 'react-router-dom'

class MinistriesMobile extends Component {
  state = {
    linkHover: false,
    activeLink: null,
  }
  hoverLink = (index) => {
    this.setState({
      linkHover: true,
      activeLink: index,
    })
  }
  leaveHoverLink = (index) => {
    this.setState({
      linkHover: false,
      activeLink: null,
    })
  }
  open = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#ministriesContainer', { display: 'flex' }, 0)
    tl.to('#ministriesContainer', { height: 'auto', paddingTop: 25 }, 0)
    tl.to('#ministriesContainer', { opacity: 1 }, duration)
  }
  close = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to('#ministriesContainer', { opacity: 0 }, 0)
    tl.to('#ministriesContainer', { height: 0, paddingTop: 0 }, duration / 2)
    tl.to('#ministriesContainer', { display: 'none' }, duration / 2)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  onClickImage = (link) => {
    const { history } = this.props
    if (
      link.imageEntry.length > 0 ||
      (link.imageUrl && link.imageUrl[0] === '/')
    ) {
      history.push(link.imageEntry[0].uri)
    } else {
      window.open(link.imageUrl, '__blank')
    }
  }

  render() {
    const { activeLink, linkHover } = this.state
    const { data, history } = this.props
    const { hoverLink, leaveHoverLink, onClickImage } = this

    let textLinks = []
    let imageLinks = []
    data.forEach((d) => {
      if (d.__typename === 'menus_links_Entry') {
        d.links.forEach((l) => {
          textLinks.push(l)
        })
      } else if (d.__typename === 'menus_image_Entry') {
        imageLinks.push(d)
      }
    })

    return (
      <div id="ministriesContainer" style={styles.container}>
        <div id="ministriesLinkWrapper" style={styles.linkWrapper}>
          {textLinks.map((link, index) => {
            return (
              <div key={index} id="ministriesLinks" style={styles.link}>
                <NavLink
                  history={history}
                  useRouter={link.entry && link.entry[0]?.slug}
                  item={{ url: link.entry ? link.entry[0].slug : link.linkUrl }}
                  onMouseEnter={() => hoverLink(index)}
                  onMouseLeave={() => leaveHoverLink(index)}
                  style={{
                    ...styles.link,
                    color:
                      activeLink === index && linkHover && styles.link.hover
                        ? styles.link.hover.color
                        : styles.link.color,
                  }}
                  href={link.entry ? link.entry[0].slug : link.linkUrl}>
                  {link.displayName}
                </NavLink>
              </div>
            )
          })}
          {imageLinks.map((link, index) => {
            return (
              <div
                onClick={() => onClickImage(link)}
                style={styles.imageWrapper}>
                <div style={styles.imageText}>{link.imageText}</div>
                <img
                  style={styles.image}
                  src={link.image[0]?.url}
                  alt={link.imageText}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 0,
    opacity: 0,
    // maxHeight: 250,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 15,
    marginBottom: 0,
  },
  linkWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  link: {
    fontSize: 22,
    color: '#6A5B5D',
    textDecoration: 'none',
    display: 'flex',
    hover: {
      color: '#D07D3C',
    },
  },
  image: {
    height: 'auto',
    width: '100%',
    zIndex: 0,
  },
  imageText: {
    fontSize: 32,
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  imageWrapper: {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
    padding: 0,
    maxWidth: 500,
    margin: 'auto',
    marginTop: 25,
    marginBottom: 25,
    cursor: 'pointer',
  },
}

export default withRouter(MinistriesMobile)
