import React, { Component } from 'react'
import NavBarMobile from './NavBarMobile'
import NavBarMinified from './NavBarMinified'
import NavBarDesktop from './NavBarDesktop'
import { connect } from 'react-redux'

class NavBar extends Component {
  state = {
    navbar: NavBarDesktop,
    showMini: false,
  }

  handleResize = () => {
    const { navbar } = this.state

    if (window.innerWidth < 1115 && navbar !== NavBarMobile) {
      this.setState({
        navbar: NavBarMobile,
        showMini: false,
      })
    }
    if (window.innerWidth >= 1115 && navbar !== NavBarDesktop) {
      this.setState({
        navbar: NavBarDesktop,
        showMini: window.scrollY >= 100,
      })
    }
  }

  handleScroll = () => {
    const { navbar, showMini } = this.state

    if (
      window.innerWidth >= 1115 &&
      window.scrollY >= 100 &&
      navbar !== NavBarMinified
    ) {
      this.setState({
        showMini: true,
      })
    }
    if (
      window.innerWidth >= 1115 &&
      window.scrollY < 100 &&
      showMini
    ) {
      this.setState({
        showMini: false,
      })
    }
  }

  componentDidMount() {
    const { handleResize, handleScroll } = this

    if (window.innerWidth < 1115) {
      this.setState({
        navbar: NavBarMobile,
      })
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
  }

  render() {
    const { navbar, showMini } = this.state
    const { isNative } = this.props

    const NavType = navbar

    return isNative ? null : (
      <>
        <NavBarMinified show={showMini} />
        <NavType />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isNative: state.app.isNative,
  }
}

export default connect(mapStateToProps)(NavBar)
