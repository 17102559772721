import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Radium from 'radium'

class Button extends Component {
  onClick = (e) => {
    const { history, url, external, newTab, onClick, playVideo } = this.props

    if(e) {
      e.stopPropagation()
    }

    if (onClick) {
      onClick()
      return
    }
    if (window.isNativeApp) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(url)
      }
    } else {
      if (external) {
        if (newTab) {
          window.open(url, '__blank')
        } else {
          window.location = url
        }
      } else if (url) {
        if (playVideo) {
          history.push({ pathname: `/${url}`, state: { play: true } })
        } else {
          history.push(`/${url}`)
        }
      }
    }
  }

  render() {
    const { onClick } = this
    const { resetStyles } = this.props

    return (
      <div
        onClick={onClick}
        style={resetStyles ? null : styles.container}>
        {this.props.children}
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: 'transparent',
    display: 'inline-block',
    color: '#6A5B5D',
    borderColor: '#6A5B5D',
    borderStyle: 'solid',
    borderRadius: 0,
    borderWidth: 1,
    letterSpacing: 3.4,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'all 0.15s',
    ':hover': {
      backgroundColor: '#6A5B5D',
      textDecoration: 'none',
      color: '#FAA860',
      borderColor: '#6A5B5D',
    },
  },
}

Button.defaultProps = {
  external: false,
  newTab: true,
}

export default withRouter(Radium(Button))
