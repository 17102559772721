import React from 'react'

const DownloadMenu = ({ show, recording, docUrl, top, right }) => {
  const [activeRow, setActiveRow] = React.useState(-1)

  const videoUrl = recording?.download_url
  const audioUrl = recording?.audio_download_url

  return (
    <div
      style={{
        ...styles.container,
        top,
        right,
        opacity: show ? 1 : 0,
        pointerEvents: show ? 'auto' : 'none',
      }}>
      {!!videoUrl && (
        <div
          onClick={() => {
            window.open(videoUrl, '__blank')
          }}
          onMouseLeave={() => setActiveRow(-1)}
          onMouseEnter={() => setActiveRow(0)}
          style={{
            ...styles.row,
            backgroundColor:
              activeRow === 0 ? 'rgba(105,91,93,0.1)' : 'transparent',
          }}>
          <img
            style={styles.icons}
            src={require('../assets/DownloadMenu_Video.png')}
          />
          Download Video
        </div>
      )}
      {!!audioUrl && (
        <div
          onClick={() => {
            window.open(audioUrl, '__blank')
          }}
          onMouseLeave={() => setActiveRow(-1)}
          onMouseEnter={() => setActiveRow(1)}
          style={{
            ...styles.row,
            backgroundColor:
              activeRow === 1 ? 'rgba(105,91,93,0.1)' : 'transparent',
          }}>
          <img
            style={styles.icons}
            src={require('../assets/DownloadMenu_Audio.png')}
          />
          Download Audio
        </div>
      )}
      {!!docUrl && (
        <div
          onClick={() => {
            window.open(docUrl, '__blank')
          }}
          onMouseLeave={() => setActiveRow(-1)}
          onMouseEnter={() => setActiveRow(2)}
          style={{
            ...styles.row,
            backgroundColor:
              activeRow === 2 ? 'rgba(105,91,93,0.1)' : 'transparent',
          }}>
          <img
            style={styles.icons}
            src={require('../assets/DownloadMenu_Outline.png')}
          />
          Download Notes
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    position: 'absolute',
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 0px 20px 0px',
    transition: 'opacity 0.25s',
  },
  row: {
    color: '#695B5D',
    cursor: 'pointer',
    fontSize: 18,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 7,
    paddingBottom: 7,
  },
  icons: {
    width: 20,
    marginBottom: 5,
    marginRight: 15,
  },
}

export default DownloadMenu
