import React, { Component } from 'react'
import {
  Footer as FooterComponent,
  withSizes,
} from '@oos-studio/website-common-components'
import FooterContactForm from './FooterContactForm'
import FooterInfo from './FooterInfo'
import { gqlConnect } from 'react-connect-graphql-beta'

const socials = [
  {
    name: 'facebook',
    icon: require('../assets/Facebook.png'),
    url: 'https://www.facebook.com',
  },
  {
    name: 'instagram',
    icon: require('../assets/Instagram.png'),
    url: 'https://www.instagram.com',
  },
  {
    name: 'vimeo',
    icon: require('../assets/Vimeo.png'),
    url: 'https://www.vimeo.com',
  },
]

const getColumns = (data) => {
  const socialMediaData = data.socialMediaLinks?.map((link) => {
    return {
      name: link.siteName,
      url: link.siteUrl,
      icon: link.icon[0]?.url,
    }
  })
  return [
    {
      heading: '',
      render: () => {
        return <FooterInfo data={data} socials={socialMediaData} />
      },
    },
    {
      heading: '',
      render: () => {
        return <FooterContactForm data={data} />
      },
    },
  ]
}

const styles = {
  container: {
    width: '100%',
    maxWidth: 1200,
    backgroundColor: '#6A5B5D',
  },
  limitWidth: {
    backgroundColor: '#6A5B5D',
    width: '100%',
    paddingTop: 75,
    paddingBottom: 75,
    paddingLeft: 75,
    paddingRight: 75,
    //display: 'flex',
    xs: {
      paddingLeft: 35,
      paddingRight: 35,
    },
    md: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  content: {
    display: 'flex',
  },
  columns: [
    {
      column: {
        flex: 2,
        padding: 0,
      },
    },
    {
      column: {
        flex: 1.4,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
  ],
  md: {
    content: {
      flexDirection: 'column-reverse',
    },
  },
}

class Footer extends Component {
  render() {
    const { getStyle } = this.props
    const { data } = this.props.gql

    return (
      <div style={getStyle(styles.limitWidth)}>
        <FooterComponent columns={getColumns(data)} styles={getStyle(styles)} />
      </div>
    )
  }
}

export default gqlConnect('footer')(withSizes(Footer))
