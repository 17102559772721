export const buildArticle = (text, dropCap) => {
  return {
    dropCap: dropCap,
    newsPostBody: [
      {
        typeHandle: 'text',
        text: text,
      },
    ],
  }
}
