import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ParallaxProvider } from 'react-scroll-parallax'
import 'bootstrap/dist/css/bootstrap.css'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import request from 'request'
import GQLProvider from 'react-connect-graphql-beta'
import gql from './gql.js'
import introspectionQueryResultData from './fragmentTypes.json'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './redux/reducers'
import config from './config'

request(`${window.location.origin}/config.json`, { json: true }, (err, res) => {
  config.instagram = res.body.instagram
  config.boxcast = res.body.boxcast
  config.providenceApi = res.body.providenceApi
  config.craftApi = res.body.craftApi

  const reduxStore = createStore(rootReducer, applyMiddleware(thunkMiddleware))

  const uri = config.craftApi.baseUrl
  const token = config.providenceApi.token

  const httpLink = createHttpLink({ uri })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    }
  })

  const link = authLink.concat(httpLink)

  const store = GQLProvider.createStore({
    link,
    gql,
    introspectionFragmentMatcherOptions: {
      introspectionQueryResultData,
    },
  })

  ReactDOM.render(
    <React.StrictMode>
      <GQLProvider store={store} fallbackOnce={true} fadeInOnce={true}>
        <Provider store={reduxStore}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </Provider>
      </GQLProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
})
