import React, { Component } from 'react'
import {
  Button as ButtonCommon,
  withSizes,
} from '@oos-studio/website-common-components'
import Button from '../../common/Button'
import { styles as globalStyles } from '../../styles'
import RectButton from '../../common/RectButton'

class CallToAction extends Component {
  render() {
    const { text, buttonEntry, buttonText, getStyle } = this.props

    return (
      <div style={styles.container}>
        <div style={getStyle(styles.text)}>{text}</div>
        <RectButton text={buttonText} url={buttonEntry} />
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontSize: 45,
    textAlign: 'center',
    fontWeight: 300,
    fontFamily: 'Neutraface Slab Text',
    color: '#6A5B5D',
    marginBottom: 35,
    sm: {
      fontSize: 25,
    },
  },
}

export default withSizes(CallToAction)
